<template>
	<div>
		<div class="flexB">
			<h3 class="marginB24">推广结算详情</h3>
			<el-dropdown>
				<el-button type="primary" plain color="#112DF2">
				    更多操作<el-icon class="el-icon--right"><arrow-down /></el-icon>
				</el-button>
			    <template #dropdown>
			      <el-dropdown-menu>
			        <el-dropdown-item @click.native="importData">批量导出</el-dropdown-item>
			      </el-dropdown-menu>
			    </template>
			</el-dropdown>
		</div>
		<div>
			<div class="marginB12 flexA">
				<div class="w240">单据名称：{{pushData.name}}</div>
				<div class="w240">结算金额：{{pushData.settledAmount}}</div>
				<div class="w240">结算时间：{{pushData.createdTime}}</div>
			</div>
			<div class="flexA">
				结算凭证：<img class="img50" style="background-color: aquamarine;" :src="pushData.certification" alt="">
			</div>
		</div>
		<el-table :data="tableData" :empty-text="emptyText" style="width: 100%;margin-top: 30px;">
			<el-table-column prop="belongMerchantName" fixed label="会员所属商户" width="180" />
			<el-table-column prop="customerNickName" fixed label="会员名称" width="120" />
			<el-table-column prop="customerPhoneNumber" fixed label="联系方式" width="120" />
			<el-table-column prop="orderId" fixed label="订单编号" width="200" />
			<el-table-column prop="goodsName" fixed label="商品名称" width="150" />
			<el-table-column prop="orderAmount" label="订单金额" width="90" />
			<el-table-column prop="undertakingMerchantName" label="承接商户" width="180" />
			<el-table-column prop="undertakingMerchantServiceRatio" label="承接比例" width="90" />
			<el-table-column prop="undertakingMerchantServiceAmount" label="承接收入" width="90" />
			<el-table-column prop="undertakingMerchantGuestChargeAmount" label="客资费（元）" width="120" />
			<el-table-column prop="belongMerchantGuestChargeRatio" label="客资比例" width="90" />
			<el-table-column prop="belongMerchantGuestChargeAmount" label="客资收入" width="90" />
			<el-table-column prop="platformIncome" label="平台实收" width="120">
				<template v-slot:header="scope">
					<span>平台实收
						<el-tooltip effect="dark" content="（订单实付金额-承接商户承接费）+（承接方客资费-会员所属商户客资费）" placement="top">
							<el-icon><QuestionFilled /></el-icon>
						</el-tooltip>
					</span>
				</template>
			</el-table-column>
			<el-table-column prop="orderTime" label="订单时间" width="180" />
		</el-table>
	</div>
</template>

<script>
	import Validate from '@/utils/rules'
	import {merchantSpreadSettlementGetById,merchantSpreadSettlementExport} from '@/api'
	import {formatDateOrder,dateFormat} from '@/utils/data'
	import { Search } from "@element-plus/icons-vue";
	export default {
		data () {
			return {
				Search,
				pushData:{
					name:'',
					settledAmount:'',
					createdTime:''
				},
				tableData:[],
				emptyText:'暂无数据'
			}
		},
		created() {
			this.search()
		},	
		methods:{
			search(){
				merchantSpreadSettlementGetById({data:{id:this.$route.params.id}}).then(res => {
					if(res&&res.success){
						let data = res.data
						this.pushData = {
							name:data.name,
							createdTime:formatDateOrder(data.createdTime),
							settledAmount:data.settledAmount,
							certification:data.certification.split(',')
						}
						let list = data.particulars
						list.forEach(item=>{
							item.belongMerchantGuestChargeRatio = item.belongMerchantGuestChargeRatio*100+'%'
							item.undertakingMerchantServiceRatio = item.undertakingMerchantServiceRatio*100+'%'
							item.orderTime = formatDateOrder(item.orderTime)
						})
						this.tableData = list
					}
				})
			},
			importData(){//导出
				let posts = {settlementReceiptId:this.$route.params.id}
				this.$http.post(merchantSpreadSettlementExport, posts, {
					headers:{
						'Authorization': this.$store.state.userInfo.token?('Bearer'+' '+ this.$store.state.userInfo.token):'',
						'Content-Type': 'application/x-www-form-urlencoded'
					},
					responseType: "blob"
				}).then(res => {
					const blob = new Blob([res.data], {type: 'application/vnd.ms-excel'});
					const fileName = '推广结算详情表.xlsx';
					if ('download' in document.createElement('a')) {
						const elink = document.createElement('a');
						elink.download = fileName;
						elink.style.display = 'none';
						elink.href = URL.createObjectURL(blob);
						document.body.appendChild(elink);
						elink.click();
						URL.revokeObjectURL(elink.href);
						document.body.removeChild(elink);
					}else{
						navigator.msSaveBlob(blob, fileName);
					}
				});
			}
		}
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.cardCon{
		max-width: 90%;
		margin-bottom: 10px;
	}
	// :deep(.el-select .el-input__wrapper){
	// 	width: 200px;
	// }
	// :deep(.el-icon svg){
	// 	color: #112DF2;
	// 	width: 12px;
	// 	height: 12px;
	// }
	// .el-select-dropdown__item{
	//   padding: 0 20px;
	//   box-sizing: border-box;
	// }
</style>