<template>
	<div class="mainWrap font15">
		<h3 class='marginB24'>商家详情</h3>
		<h4 class="marginB12 font16">商家名称：{{merchantInfoData.name}}</h4>
		<div class="marginB12 w240">负责人：{{merchantInfoData.head}}</div>
		<div class="marginB12 w240">负责联系方式：{{merchantInfoData.headConcat}}</div>
		<!-- <div class="marginB12 w240">商家角色：预留功能</div> -->
		<div class="marginB12 w240">商家入驻时间：{{merchantInfoData.createdTime}}</div>
		<div class="marginB24 flexA">商家类目：<span v-for="(item,index) in merchantInfoData.goodsCategoryNames" :key="index">{{item}}</span></div>
		<div v-if="isShow">
			<h4 class="marginB40 font16">商家门店</h4>
			<el-table :data="merchantInfoData.shopList" :empty-text="emptyText" stripe style="width: 100%;margin-top: 30px;">
				<el-table-column prop="id" label="门店编号"></el-table-column>
				<el-table-column prop="name" label="门店信息">
					<template #default="{row}">
						<div class="flexA">
							<img class="storeImg marginR10" :src="row.resources[0]" alt="">
							<div class="textL">
								<p>{{row.name}}</p>
								<p>{{row.head}}</p>
								<p>{{row.headConcat}}</p>	
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="category" label="门店类目">
					<template #default="{row}">
						类目一个
					</template>
				</el-table-column>
				<el-table-column prop="enabled" label="门店状态">
					<template #default="{row}">
						<div>{{row.enabled==1?'正常状态':'关闭状态'}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="createdTime" label="入驻时间"></el-table-column>
				<el-table-column label="门店操作">
					<template #default="{row}">
						<div class="color112" @click="editItem(row.id)">编辑门店</div>
						<div class="color112" @click="editItem(row.id)">关闭账户</div>
						<div class="color112" @click="viewPro(row.id)">查看门店</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
	import {merchantInfo} from "@/api"
	import {formatDate} from '@/utils/data'
	export default{
		data (){
			return{
				isShow:false,
				merchantInfoData:{},
				tableData:[
					{code:'95280013236',name:'商家名称展示在这边哦',status:1,master:'王麻子',tel:'13776124124',role:'角色一预留',category:[
						'类目一','类目一','类目一'
					],id:'22'},
					{code:'95280013236',name:'商家名称展示在这边哦',status:1,master:'王麻子',tel:'13776124124',role:'角色一预留',category:[
						'类目一','类目一','类目一'
					],id:'41'},
					{code:'95280013236',name:'商家名称展示在这边哦',status:1,master:'王麻子',tel:'13776124124',role:'角色一预留',category:[
						'类目一','类目一','类目一'
					],id:'22'},
					{code:'95280013236',name:'商家名称展示在这边哦',status:1,master:'王麻子',tel:'13776124124',role:'角色一预留',category:[
						'类目一','类目一','类目一'
					],id:'41'}
				],
				emptyText:'暂无数据'
			}
		},
		created() {
			this.getData()
		},	
		methods:{
			editItem(id){
				
			},
			viewPro(id){
				this.$router.push('/merchantManagement/storeManagement/storeView/'+id)
			},
			getData(){
				let postData = {id:this.$route.params.id}
				merchantInfo({data:postData}).then(res => {
					if(res.success){
						res.data.createdTime = formatDate(res.data.createdTime)
						// if(res.data.shopList.length>0){
						// 	res.data.shopList.forEach(item=>{
						// 		item.createdTime = formatDate(item.createdTime)
						// 	})
						// }
						this.merchantInfoData = res.data
					}
				})
			}
		}
	}
</script>

<style rel="stylesheet/scss" lang='scss' scoped>
	span{
		display: inline-block;
		margin-right:20px;
	}
	.storeImg{
		width: 100px;
		height:75px;
		background-color:#f5f5f5;
	}
	::v-deep .el-table th.el-table__cell {
		overflow: hidden;
		-webkit-user-select: none;
		-moz-user-select: none;
		user-select: none;
		background-color: #FFF;
		background: #f5f5f5;
	}
</style>