import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'

// const routes = [
//   {
//     path: "/",
//     name: "Login",
//     component:Login
//   },
//   {
//     path: '/home',
//     name: 'home',
//     component: () => import(/* webpackChunkName: "about" */ '../views/home.vue')
//   }
// ]
import home from '../views/home.vue'
import Login from '../views/login/index.vue'
import merchantManagement from '../views/merchant/merchantManagement/merchantManagement.vue'
import merchantAdd from '../views/merchant/merchantManagement/merchantAdd.vue'
import merchantView from '../views/merchant/merchantManagement/merchantView.vue'
import storeManagement from '../views/merchant/storeManagement/storeManagement.vue'
import storeAdd from '../views/merchant/storeManagement/storeAdd.vue'
import storeView from '../views/merchant/storeManagement/storeView.vue'
import userList from '../views/userManagement/userList.vue'
import retailGoodsList from '../views/goodsManagement/retailGoods/retailGoods.vue'
import retailGoodsAdd from '../views/goodsManagement/retailGoods/retailGoodsAdd.vue'
import itemsList from '../views/goodsManagement/itemsList.vue'
import itemsAdd from '../views/goodsManagement/itemsAdd.vue'
import cardList from '../views/goodsManagement/cardList.vue'
import cardAdd from '../views/goodsManagement/cardAdd.vue'
import orderList from '../views/order/orderList.vue'
import orderView from '../views/order/orderView.vue'
import categoryList from '../views/categoryManagement/categoryList.vue'
import categoryAdd from '../views/categoryManagement/categoryAdd.vue'
import flashSaleList from '../views/marketing/flashSale.vue'
import flashSaleAdd from '../views/marketing/flashSaleAdd.vue'
import groupList from '../views/marketing/groupList.vue'
import groupAdd from '../views/marketing/groupAdd.vue'
import experienceList from '../views/marketing/experienceList.vue'
import experienceAdd from '../views/marketing/experienceAdd.vue'
import diamondRegionList from '../views/marketing/diamondRegionList.vue'
import diamondRegionAdd from '../views/marketing/diamondRegionAdd.vue'
import publicBenefit from '../views/marketing/publicBenefit.vue'
import appointGift from '../views/marketing/appointGift.vue'
import postpartumCare from '../views/marketing/postpartumCare.vue'
import maternityBenefit from '../views/marketing/maternityBenefit.vue'
import generalStatistics from '../views/distributionManagement/commissionMerchant/generalStatistics.vue'
import popularizeDetails from '../views/distributionManagement/commissionMerchant/popularizeDetails.vue'
import accountDetails from '../views/distributionManagement/commissionMerchant/accountDetails.vue'
import orderDetails from '../views/distributionManagement/commissionMerchant/orderDetails.vue'
import platformDetails from '../views/distributionManagement/commissionMerchant/platformDetails.vue'
import extensionCodeList from '../views/distributionManagement/commissionMerchant/extensionCodeList.vue'
import creatCode from '../views/distributionManagement/commissionMerchant/creatCode.vue'
import extensionCodeStatistics from '../views/distributionManagement/commissionMerchant/extensionCodeStatistics.vue'
import promotionSettings from '../views/distributionManagement/commissionMerchant/promotionSettings.vue'
import promotionSettlement from '../views/distributionManagement/commissionMerchant/promotionSettlement.vue'
import settlementDetails from '../views/distributionManagement/commissionMerchant/settlementDetails.vue'
 
const routes = [
	// {
	// 	path: '',
	// 	redirect: "home"
	// }, {
	// 	path: '/',
	// 	redirect: "home"
	// },
	{
		path: '',
		name: 'Login',
		component: Login,
		meta: {
			title: '登录'	
		}
	},
	{
		path: '/home',
		name: 'home',
		component: home,	
        /* 子路由 */
		children: [
			{
				path: '/merchantManagement',
				name: 'merchantManagement',
				component: merchantManagement,
				meta: {
					title: '商户管理',
				}
			},
			{
				path: '/merchantManagement/merchantAdd/:id?',
				name: 'merchantAdd',
				component: merchantAdd,
				meta: {
					title: '新增商户',
				}
			},
			{
				path: '/merchantManagement/merchantView/:id',
				name: 'merchantView',
				component: merchantView,
				meta: {
					title: '查看商户',
				}
			},
			{
				path: '/merchantManagement/storeManagement',
				name: 'storeManagement',
				component: storeManagement,
				meta: {
					title: '门店列表',
				}
			},
			{
				path: '/merchantManagement/storeManagement/storeAdd/:id?',
				name: 'storeAdd',
				component: storeAdd,
				meta: {
					title: '新增/编辑门店',
				}
			},
			{
				path: '/merchantManagement/storeManagement/storeView/:id',
				name: 'storeView',
				component: storeView,
				meta: {
					title: '门店详情',
				}
			},
			{
				path: '/user/userList',
				name: 'userList',
				component: userList,
				meta: {
					title: '用户列表',
					isTab: true
				}
			},
			{
				path: '/goods/retailGoodsList',
				name: 'retailGoodsList',
				component: retailGoodsList,
				meta: {
					title: '零售商品',
					isTab: true
				}
			},
			{
				path: '/goods/retailGoodsList/retailGoodsAdd/:id?',
				name: 'retailGoodsAdd',
				component: retailGoodsAdd,
				meta: {
					title: '新增零售商品',
					isTab: true
				}
			},
			{
				path: '/goods/itemsList',
				name: 'itemsList',
				component: itemsList,
				meta: {
					title: '服务项目',
					isTab: true
				}
			},
			{
				path: '/goods/itemsList/itemsAdd/:id?',
				name: 'itemsAdd',
				component: itemsAdd,
				meta: {
					title: '新增项目',
					isTab: true
				}
			},
			{
				path: '/goods/cardList',
				name: 'cardList',
				component: cardList,
				meta: {
					title: '卡项',
					isTab: true
				}
			},
			{
				path: '/goods/cardList/cardAdd/:id?',
				name: 'cardAdd',
				component: cardAdd,
				meta: {
					title: '新增卡项',
					isTab: true
				}
			},
			{
				path: '/orderList',
				name: 'orderList',
				component: orderList,
				meta: {
					title: '订单列表',
					isTab: true
				}
			},
			{
				path: '/orderList/orderView/:id?',
				name: 'orderView',
				component: orderView,
				meta: {
					title: '订单详情',
					isTab: true
				}
			},
			{
				path: '/categoryList',
				name: 'categoryList',
				component: categoryList,
				meta: {
					title: '类目列表',
					isTab: true
				}
			},
			{
				path: '/categoryList/categoryAdd/:id?',
				name: 'categoryAdd',
				component: categoryAdd,
				meta: {
					title: '新增分类',
					isTab: false
				},
			},
			{
				path: '/active/groupList',
				name: 'groupList',
				component: groupList,
				meta: {
					title: '拼团活动',
					isTab: true
				},
			},
			{
				path: '/active/groupList/groupAdd/:id?',
				name: 'groupAdd',
				component: groupAdd,
				meta: {
					title: '新增拼团活动',
					isTab: true
				},
			},
			{
				path: '/active/flashSaleList',
				name: 'flashSaleList',
				component: flashSaleList,
				meta: {
					title: '秒杀活动',
					isTab: true
				},
			},
			{
				path: '/active/flashSaleList/flashSaleAdd/:id?',
				name: 'flashSaleAdd',
				component: flashSaleAdd,
				meta: {
					title: '新增秒杀活动',
					isTab: true
				},
			},{
				path: '/active/experienceList',
				name: 'experienceList',
				component: experienceList,
				meta: {
					title: '体验价',
					isTab: true
				},
			},{
				path: '/active/experienceList/experienceAdd',
				name: 'experienceAdd',
				component: experienceAdd,
				meta: {
					title: '新增体验',
					isTab: true
				},
			},
			{
				path: '/active/diamondRegionList/:type?',
				name: 'diamondRegionList',
				component: diamondRegionList,
				meta: {
					title: '金刚区列表',
					isTab: true
				},
			},
			{
				path: '/active/diamondRegionList/diamondRegionAdd/:type?/:id?',
				name: 'diamondRegionAdd',
				component: diamondRegionAdd,
				meta: {
					title: '新建金刚区分类',
					isTab: false
				},
			},
			{
				path: '/active/publicBenefit',
				name: 'publicBenefit',
				component: publicBenefit,
				meta: {
					title: '公益活动',
					isTab: true
				},
			},
			{
				path: '/active/appointGift',
				name: 'appointGift',
				component: appointGift,
				meta: {
					title: '预约礼',
					isTab: true
				},
			},
			{
				path: '/active/postpartumCare',
				name: 'postpartumCare',
				component: postpartumCare,
				meta: {
					title: '产后关爱',
					isTab: true
				},
			},
			{
				path: '/active/maternityBenefit',
				name: 'maternityBenefit',
				component: maternityBenefit,
				meta: {
					title: '关爱母婴',
					isTab: true
				},
			},
			{
				path: '/distributionManagement/generalStatistics',
				name: 'generalStatistics',
				component: generalStatistics,
				meta: {
					title: '推广统计',
					isTab: true
				},
			},
			{
				path: '/distributionManagement/popularizeDetails',
				name: 'popularizeDetails',
				component: popularizeDetails,
				meta: {
					title: '推广明细',
					isTab: true
				},
			},
			{
				path: '/distributionManagement/accountDetails',
				name: 'accountDetails',
				component: accountDetails,
				meta: {
					title: '客资明细',
					isTab: true
				},
			},
			{
				path: '/distributionManagement/promotionSettlement',
				name: 'promotionSettlement',
				component: promotionSettlement,
				meta: {
					title: '推广结算',
					isTab: true
				},
			},
			{
				path: '/distributionManagement/settlementDetails/:id',
				name: 'settlementDetails',
				component: settlementDetails,
				meta: {
					title: '推广结算详情',
					isTab: true
				},
			},
			{
				path: '/distributionManagement/orderDetails',
				name: 'orderDetails',
				component: orderDetails,
				meta: {
					title: '订单明细',
					isTab: true
				},
			},
			{
				path: '/distributionManagement/platformDetails',
				name: 'platformDetails',
				component: platformDetails,
				meta: {
					title: '平台明细',
					isTab: true
				},
			},
			{
				path: '/distributionManagement/extensionCodeList',
				name: 'extensionCodeList',
				component: extensionCodeList,
				meta: {
					title: '推广码列表',
					isTab: true
				},
			},
			{
				path: '/distributionManagement/creatCode',
				name: 'creatCode',
				component: creatCode,
				meta: {
					title: '新增推广码',
					isTab: true
				},
			},
			{
				path: '/distributionManagement/extensionCodeStatistics',
				name: 'extensionCodeStatistics',
				component: extensionCodeStatistics,
				meta: {
					title: '推广码统计',
					isTab: true
				},
			},
			{
				path: '/distributionManagement/promotionSettings',
				name: 'promotionSettings',
				component: promotionSettings,
				meta: {
					title: '推广设置',
					isTab: true
				},
			}
		]
	}
];
 
// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
/* router.beforeEach((to, from, next) => {
	if (to.path === '/login') {
		next();
	} else {
		let token = localStorage.getItem('Authorization');
 
		if (token === null || token === '') {
			next('/login');
		} else {
			next();
		}
	}
}); */
const router = createRouter({
	history:createWebHashHistory(),
	routes
})
export default router;
// const router = createRouter({
//   history: createWebHashHistory(process.env.BASE_URL),
//   routes
// })

// export default router
