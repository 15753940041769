<template>
	<div class="wrap">
		<div class="card-header">
			<el-card class="box-card">
			    <template #header>
			      <div class="card-header">
			        <h3>零售产品类目</h3>
			      </div>
			    </template> 
				<div :class="[current=='all' ? 'current' : '','card-header item']" @click="search('all')">
					<div>全部类目</div>
					<span><span class="span">{{totalNum}}</span></span>
				</div>
				<div v-for="item in list" :key="item.id" @click="search(item.id)" :class="[current==item.id ? 'current' : '','card-header item']">
					<div>{{ item.name }}</div>
					<span><span class="span">{{item.bindingGoodsCount}}</span></span>
				</div>
			</el-card>
			<el-card class="mainCon">
				<div class="card-header">
					<h3>零售产品列表</h3>
					<div class="flexA">
						<div class="flexA marginR10">
							<el-select v-model="pushData.enabled" placeholder="是否启用" @change="search(current)" clearable class="marginR10">
								<el-option v-for="item in statusList" :key="item.status"
									:label='item.name' :value="item.status"></el-option>
							</el-select>
							<el-select v-model="pushData.saleable" placeholder="是否可销售" @change="search(current)" clearable class="marginR10">
								<el-option v-for="item in saleStatusList" :key="item.status"
									:label='item.name' :value="item.status"></el-option>
							</el-select>
							<el-input placeholder="搜索" v-model="pushData.fuzzySearch" @keyup.enter="search(current)" class="w200">
								<template #suffix>
									<el-icon class="el-input__icon">
										<component :is="Search" class="enter-sj" @click="search(current)"></component>
									</el-icon>
								</template>
							</el-input>
						</div>
						<el-button type="primary" color='#112DF2' @click="add">新增产品</el-button>
					</div>
				</div>
				<el-table :data="tableData" :empty-text="emptyText" ref='tableBox'
					:row-key="getRowKeys" @row-click='clickRow' @selection-change="selectList"
					style="width: 100%;margin-top: 30px;">
					<el-table-column fixed='left' type="selection" width="50" v-if="isShow" :selectable="selectable"
					  :reserve-selection="true"></el-table-column>
				    <el-table-column fixed='left' prop="name" label="产品信息" width="300">
						<template #default="{row}">
							<div class="flexA">
								<el-image class="img80 bgColor marginR10" :src="row.imgsUrl[0]">
									<template #error>
									  <div class="image-slot ">
										<el-icon style='height:80px;'><PictureFilled /></el-icon>
									  </div>
									</template>
								</el-image>
								<div class="textL">
									<p>{{row.name}}</p>
									<p>{{row.id}}</p>
									<p class="colorff0">{{row.salePrice?('￥'+row.salePrice):''}}</p>	
								</div>
							</div>
						</template>
					</el-table-column>
				    <el-table-column prop="goodsCategoryName" label="产品类目" min-width="180">
						<template #default="{row}">
							<div v-if="row.goodsCategoryName" class="color112 bge5e">{{row.goodsCategoryName}}</div>
						</template>
					</el-table-column>
					<el-table-column prop="statistics.salesVolume" label="销量" min-width='80'></el-table-column>
					<el-table-column prop="virtualSalesVolume" label="虚拟销量" min-width='120'>
						<template #default="{row}">
							<el-button link type="primary" @click="dialogSalesVolume(row.id,row.virtualSalesVolume)">{{row.virtualSalesVolume}}</el-button>
						</template>
					</el-table-column>
					<el-table-column prop="createdTime" label="创建时间" min-width="100" />
					<el-table-column prop="enabled" label="启用">
						<template #default="{row}">
							<el-switch
								@change="changeEnabledById(row.enabled,row.id)"
								v-model="row.enabled"
								style="--el-switch-on-color:#A2A5B8; --el-switch-off-color:#112DF2"
								:active-value="0" :inactive-value="1"/>
						</template>
					</el-table-column>
					<el-table-column prop="saleable" label="销售">
						<template #default="{row}">
							<el-switch
								@change="changeSaleableById(row.saleable,row.id)"
								v-model="row.saleable"
								style="--el-switch-on-color:#A2A5B8; --el-switch-off-color:#112DF2"
								:active-value="0" :inactive-value="1"/>
						</template>
					</el-table-column>
					<el-table-column label="操作" min-width="120">
						<template #default="{row}">
							<el-button link type="primary" plain color="#112DF2" @click="editItem(row.id)">修改</el-button>
							<el-button link type="primary" plain color="#112DF2" @click="delItem(row.id)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="paginations">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next" :total="total"></el-pagination>
				</div>
			</el-card>
		</div>
		<el-dialog v-model="dialogVisible" v-if="dialogVisible" title="虚拟销量编辑" width="600px">
			<el-form :model="form">
			  <el-form-item label="虚拟销量" label-width="80px">
				<el-input v-model="form.virtualSalesVolume" style='width:60%' autocomplete="off" />
			  </el-form-item>
			</el-form>
			<template #footer>
			  <span class="dialog-footer">
				<el-button @click="dialogVisible = false">取消</el-button>
				<el-button type="primary" @click="confirm">确定</el-button>
			  </span>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {goodsCategoryList,goodsPageList,goodsDel,goodsUpdateEnabledById,goodsUpdateSaleableById,goodsUpdateVirtualSalesVolumeById} from '@/api'
	import {formatDate} from '@/utils/data'
	import { Search } from "@element-plus/icons-vue";
	export default {
		props: {
		  isShow: {
		    type: Boolean,
		    default: false
		  },
		  isChange: {
		    type: Boolean,
		    default: false
		  },
		  selection: {
		    type: Array,
		    default: function() {
		      return []
		    }
		  },
		  option: {
		    type: Object,
		    default: function() {
		      return {}
		    }
		  },
		  isRadio: {
		    type: Boolean,
		    default: false
		  }
		},
		data () {
			return {
				Search,
				pushData:{
					enabled:null,
					saleable:null,
					fuzzySearch:'',
					type:1
				},
				form:{
					virtualSalesVolume:''
				},
				dialogVisible:false,
				goodsId:'',
				statusList:[{name:'启用',status:'true'},{name:'未启用',status:'false'}],
				saleStatusList:[{name:'销售',status:'true'},{name:'不可销售',status:'false'}],
				current:'all',
				list:[],
				tableData:[],
				pageSize: 5,	
				pageNum: 1,
				total:0,
				totalNum:0,
				emptyText:'暂无数据'
			}
		},
		watch: {
		  isChange() {
		    this.isChangeFun()
		  }
		},
		created() {
			this.getList()
		},	
		methods:{
			dialogSalesVolume(id,num){
				this.dialogVisible = true
				this.goodsId = id
				this.form.virtualSalesVolume = num
			},
			confirm(){
				let data = {
					id:this.goodsId,
					virtualSalesVolume:this.form.virtualSalesVolume
				}
				goodsUpdateVirtualSalesVolumeById({data}).then(res=>{
					if(res.success){
						this.dialogVisible = false
						this.$message.success(res.msg)
						this.search(this.current)
						return
					}
					this.$message.error(res.msg)
				})
			},
			changeEnabledById(val,id){
				let data = {
					id,
					enabled:val==1?true:false
				}
				goodsUpdateEnabledById({data}).then(res=>{
					if(res.success){
						this.$message.success(res.msg)
						this.search(this.current)
						return
					}
					// this.$message.error(res.msg)
				})
			},
			changeSaleableById(val,id){
				let data = {
					id,
					saleable:val==1?true:false
				}
				goodsUpdateSaleableById({data}).then(res=>{
					if(res.success){
						this.$message.success(res.msg)
						this.search(this.current)
						return
					}
					// this.$message.error(res.msg)
				})
			},
			search(id){
				if(id){
					this.current = id
				}
				let postData = {
					 "paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					}
				}
				postData = Object.assign(postData, this.pushData)
				if(id != 'all'){
					postData.goodsCategoryId = this.current
				}
				if(postData.goodsCategoryId == 'all'){
					delete postData.goodsCategoryId
				}
				postData.enabled = postData.enabled == '' ? null : postData.enabled
				postData.saleable = postData.saleable == '' ? null : postData.saleable
				goodsPageList(postData).then(res => {
					if(res&&res.success){
						let list = res.data.list
						list.forEach(item=>{
							item.createdTime = formatDate(item.createdTime)
							item.enabled = item.enabled ? 1 : 0
							item.saleable = item.saleable ? 1 : 0
							item.imgsUrl = []
							if(item.resources&&item.resources.length>0){
								for(let i of item.resources){
									let reg = /\w.(png|jpg|jpeg)$/i
									if(reg.test(i)){
										item.imgsUrl.push(i)
									}
								}
							}
						})
						this.tableData = list
						this.total = Number(res.data.total)
						if(id == 'all'){
							this.totalNum = Number(res.data.total)
						}
					}
				})
			},
			getList(){
				goodsCategoryList({
					data:{
						parentId:0,level:1,
						statisticsBindingGoodsCount:true,
						statisticsBindingGoodsType:1
					}
				}).then(res => {
					if(res.success){
						this.list = res.data
						this.$nextTick(() => {
							this.search('all')
						})
					}
				})
			},
			delItem(id){
				this.$messageBox.confirm('此操作将永久删除该商品，是否确定删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				}).then(()=>{
					goodsDel({data:{id}}).then(res=>{
						if(res.success){
							this.$message.success('删除成功！')
							this.search(this.current)
							return
						}
						// this.$message.error(res.msg)
					})
				}).catch(()=>{})				
			},
			editItem(id){
				this.$router.push('/goods/retailGoodsList/retailGoodsAdd/'+id)
			},
			add(){
				this.$router.push('/goods/retailGoodsList/retailGoodsAdd/')
			},
			// 选择
			selectList(val) {
			  if (!this.clearSelector) {
			    if (this.isRadio) {
			      // 单选
			      const rows = val[val.length - 1]
			      if (rows) {
			        this.selection.splice(0, 1, rows)
			      } else {
			        this.selection.splice(0, 1)
			      }
			      this.isChangeFun()
			    } else {
			      let s = this.selection
			      s.splice(0, s.length)
			      for (let i = 0; i < val.length; i++) {
			        s.splice(i, 0, val[i])
			      }
			    }
			  }
			},
			selectable(row) {
			  if (!this.isShow) {
			    if (row.state == 1) {
			      if (this.option.smallstate === 1) {
			        if (row.smallstate === 1) {
			          return true
			        } else {
			          return false
			        }
			      } else {
			        return true
			      }
			    } else {
			      return false
			    }
			  } else {
			    return true
			  }
			},
			isChangeFun() {
			  this.$nextTick(() => {
			    let arr = []
			    let backArr = []
			    this.clearSelector = true
			    this.$refs.tableBox.clearSelection()
			    for (let i = 0; i < this.selection.length; i++) {
			      for (let j = 0; j < this.tableData.length; j++) {
			        if (this.selection[i].itemcode == this.tableData[j].itemcode) {
			          arr.push(this.tableData[j])
			          break
			        }
			        if (j == this.tableData.length - 1) {
			          backArr.push(this.selection[i])
			        }
			      }
			    }
			    arr.forEach(row => {
			      this.$refs.tableBox.toggleRowSelection(row)
			    })
			    backArr.forEach(row => {
			      this.$refs.tableBox.toggleRowSelection(row)
			    })
			    this.clearSelector = false
			  })
			},
			clickRow(row) {
			  if (this.selectable(row)) {
			    this.$refs.tableBox.toggleRowSelection(row)
			  }
			},
			getRowKeys(row) {
			  return row.id
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.search(this.current)
			},
			handleCurrentChange(val) {
				this.pageNum = val;
				this.search(this.current)
			},
		}
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.wrap{
		
	}
	.box-card{
		width: 276px;
		height: calc(100vh - 60px);
	}
	.card-header {
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
	}
	.item {
	  margin-bottom: 8px;
	  padding: 12px;
	  box-sizing: border-box;
	  font-size: 16px;
	  .span{
		  color: #00B56B;
	  }
	}
	.current{
		color: #fff;
		background: #112DF2;
		border-radius: 10px;
		.span{
			color: #fff;
		}
	}
	.mainCon{
		width: calc(100% - 316px);
		height: calc(100vh - 60px);
	}
</style>