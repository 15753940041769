<template>
	<div class="el-dialog-body-spacing">
		<el-dialog title="参与门店列表" :model-value="dialogVisible" width="1200px" :before-close="hideDialog"
			:close-on-click-modal='false' append-to-body>
			<div style="position: relative">
				<div class="flexB">
					<span></span>
					<div class="flexA">
						<el-select v-model="pushData.enabled" @change="search()" placeholder="全部状态" class="marginR10">
							<el-option v-for="item in statusList" :key="item.status"
								:label='item.name' :value="item.status"></el-option>
						</el-select>
						<el-input placeholder="搜索" v-model="pushData.fuzzySearch" @keyup.enter="search()" style="width: 300px;">
							<template #suffix>
								<el-icon class="el-input__icon">
									<component :is="Search" class="enter-sj" @click="search()"></component>
								</el-icon>
							</template>
						</el-input>
					</div>
				</div>
				<el-table :data="tableData" :empty-text="emptyText" style="width: 100%;margin-top: 15px;">
				    <el-table-column prop="id" label="门店编号" />
				    <el-table-column prop="name" label="门店名称" />
				    <el-table-column prop="goodsCategoryNames" label="门店类目">
				    	<template #default="{row}">
				    		<div class="color112 bge5e" v-for="(item,index) in row.goodsCategoryNames" :key="index">{{item}}</div>
				    	</template>
				    </el-table-column>
				    <el-table-column prop="head" label="联系人" />
				    <el-table-column prop="headConcat" label="联系方式" />
				    <el-table-column prop="enabled" label="门店状态">
				    	<template #default="{row}">
				    		<div :class="[!row.enabled?'colorff0':'color112']">{{row.enabled?'正常状态':'关闭状态'}}</div>
				    	</template>
				    </el-table-column>
				</el-table>
				<div class="paginations">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next" :total="total"></el-pagination>
				</div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import { Search } from "@element-plus/icons-vue";
	import {shopPageList} from '@/api'
	export default {
		props: {
			visiFlag: {
				type: Function,
				default: function() {}
			},
			activityId:{
				type:String,
				default: ''
			},
			dialogVisible: {
				type: Boolean,
				default: false
			},
		},
		data() {	
			return {
				Search,
				pushData:{
					enabled:'all',
					fuzzySearch:''
				},
				statusList:[
					{name:'全部',status:'all'},
					{name:'正常',status:true},
					{name:'关闭',status:false},
				],
				activityId:'',
				emptyText:'暂无数据',
				tableData:[],
				pageSize: 10,	
				pageNum: 1,
				total:0,
				seleArr: [],
				isChange: false
			}
		},
		methods: {
			search(){
				let postData = {
					 "paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					},
				}
				postData = Object.assign(postData, this.pushData)
				if(postData.enabled == 'all'){
					delete postData.enabled
				}	
				shopPageList(postData).then(res => {
					if(res&&res.success){
						this.tableData = res.data.list
						this.total = Number(res.data.total)
					}
				})
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.search()
			},
			handleCurrentChange(val) {
				this.pageNum = val;
				this.search()
			},
			hideDialog() {
				this.visiFlag(false,1)
			}
		}
	}
</script>
<style rel="stylesheet/scss" lang='scss' scoped>
	.el-dialog__body{
		overflow: hidden;
		overflow-y:auto;
	}	
</style>
