import OriginAxios from 'axios'
import store from '@/store'
import Qs from 'qs'
import router from '@/router'
// import { Message, Loading } from 'element-ui'
import {ElMessage,ElMessageBox,ElLoading} from 'element-plus'
import configB from '@/api/config'
import {userLogin} from '@/api';
const nodeEnv = process.env.NODE_ENV
let baseUrl = '/apis';
if(nodeEnv === 'production'){
	baseUrl = configB.baseUrl.pro;//pro:生产环境 dev:开发环境
}

const service = OriginAxios.create({
	baseURL: baseUrl,
	headers: {
		'Content-Type': 'application/json;charse=UTF-8'
	},
	timeout: 60000 // request timeout
})

let loadingInstance = ''
let headerType = ''
// request interceptor
service.interceptors.request.use(config => {
  // Do something before request is sent、
  config.headers['Authorization'] = store.state.userInfo.token ? ('Bearer'+' '+ store.state.userInfo.token):''
  if (config.method != 'get' || config.method != 'delete') {
    if (headerType != 'json') {
		config.data = Qs.parse(config.data, {arrayFormat: 'repeat'})
		// config.data = Qs.stringify(config.data, {arrayFormat: 'repeat'})
    } else {
      config.headers['Content-Type'] = 'application/json;charset=UTF-8'
    }
  }
  return config
}, error => {
  // Do something with request error
  if (loadingInstance) {
    loadingInstance.close()
  }
  console.log(error) // for debug
  Promise.reject(error)
})

// 响应拦截器
let requestError = null
let stateCodeError = null
let download = null
service.interceptors.response.use(
  // 响应成功
  res => {
    if (loadingInstance) {
      loadingInstance.close()
    }
    if (res.data.success) {
      
    } else if(res.data.code=="A0200"||res.data.code=="A0201"){// 登录过期，重新登录
		let userInfo = store.state.userInfo
		userInfo.token = ''
		userInfo.refreshToken = ''
		store.commit("updateUserInfo", userInfo);
		ElMessage({
		    message: res.data.msg,
		    type: 'warning',
		})
		router.push("/")
	}else if(res.data.code=="A0202"){
		let userInfo = store.state.userInfo
		userInfo.token = ''
		store.commit("updateUserInfo", userInfo);
		let postData = {
			"grantType": "PLATFORM_REFRESH_TOKEN",
			refreshTokenTokenRequest:{
				refreshToken: userInfo.refreshToken
			}
		}
		userLogin(postData).then(res1=>{
			userInfo.token = res1.data.accessToken;
			userInfo.refreshToken = res1.data.refreshToken;
			store.commit("updateUserInfo", userInfo);
		})
	}
	// else if (data.state == 'relogin') { // 登录超时，重新登录
 //      store.dispatch('FedLogOut').then(() => {
 //        VM.$router.push({
 //          path: '/login'
 //        })
 //      })
 //      return data
 //    } else {
      
 //    }
	return res.data
  },
  // 响应失败
  error => {
    // ElLoading.service({ fullscreen: true, background:'rgba(0,0,0,0)'}).close()
    // console.log(error)
    ElMessage.error('网络连接失败，请稍后再试')
    return Promise.resolve(error)
    requestError = null
  }
)

/*
*
* 请求axios配置
* @module axios
* @param {setting} 参数类型 json
* @param {setting} in {data} 参数类型 string  --请求API列表里面的路径
* @param {setting} in {data} 参数类型 json --请求的参数
* @param {setting} in {isLoading} 参数类型 Boolean --是否显示加载中动画
* @param {setting} in {config} 参数类型 json --修改axios配置
* @param {setting} in {requestError} 参数类型 function --重定义axios请求失败处理
* @param {setting} in {stateCodeError} 参数类型 function --重定义后台返回状态码为失败处理
*
*/

function request (setting) {
  // 重定义请求错误处理
  if (setting.requestError) {
    requestError = setting.requestError
  } else {
    requestError = null
  }
  // 重定义后台返回状态码为失败处理
  if (setting.stateCodeError) {
    stateCodeError = setting.stateCodeError
  } else {
    stateCodeError = null
  }

  // 定义下载属性
  if (setting.download) {
    download = setting.download
  } else {
    download = null
  }

  if (setting.headerType == 'json') {
    headerType = setting.headerType
  } else {
    headerType = ''
  }

  // 去除参数的空格
  if (setting.data) {
    for (let key in setting.data) {
      let value = setting.data[key]
      if (typeof value === 'number') {
        value = parseFloat((value + '').trim())
        setting.data[key] = value
      }
      if (typeof value === 'string') {
        setting.data[key] = value.trim()
      }
    }
  }

  const requsetOption = {
    url: setting.url,
    data: Object.assign( setting.data || {}),
    params: Object.assign( setting.data || {}),
    responseType: setting.download ? 'blob' : 'json'
  }

  if (setting.download) {
    // VM.$loading({
    //   lock: true
    // })
  }

  if (setting.isLoading) {
    loadingInstance = ElLoading.service({ fullscreen: true, background:'rgba(0,0,0,0)'})
  }

  // 配置请求方式，默认请求方式 "POST"
  if (setting.method === '' || setting.method === 'delete') {
    requsetOption.method = setting.method || 'get'
    delete requsetOption.data
  } else if (setting.method === 'post') {
    requsetOption.method = 'post'
    delete requsetOption.params
  } else {
    requsetOption.method = setting.method
  }
  return service(requsetOption)
}

export default request