<template>
  <div class="el-dialog-body-spacing">
    <el-dialog :title="addShopDialongTitle" :visible.sync="dialogVisible" width="1100px" :before-close="hideDialog" :close-on-click-modal='false' append-to-body>
		<div style="height: 500px; position: relative">
			<div></div>
			<el-table :data="goodsData" border :empty-text="emptyText" style="width: 100%;margin-top: 30px;">
				<el-table-column align="center" prop="info" label="产品信息">
					<template #default="{row}">
						桂圆红枣枸杞茶
					</template>
				</el-table-column>
				<el-table-column align="center" prop="categoryName" label="产品类目">
					<template #default="{row}">
						桂圆红枣枸杞茶
					</template>
				</el-table-column>
				<el-table-column align="center" prop="creatDate" label="创建时间">
					<template #default="{row}">
						桂圆红枣枸杞茶
					</template>
				</el-table-column>
				<el-table-column align="center" prop="isActive" label="启用">
					<template #default="{row}">
						桂圆红枣枸杞茶
					</template>
				</el-table-column>
				<el-table-column align="center" prop="isSale" label="销售">
					<template #default="{row}">
						桂圆红枣枸杞茶
					</template>
				</el-table-column>
			</el-table>
		</div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    categoryId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      addShopDialongTitle: '选择产品',
      seleArr: [],
      isChange: false,
	  goodsData:[
	  	{id:'1',name:'a',className:'1',people:'群众',range:'一周',goodsNum:200,expNum:1000,hits:50,discuss:2000},
	  	{id:'2',name:'b',className:'1',people:'群众',range:'一周',goodsNum:200,expNum:1000,hits:50,discuss:2000},
	  	{id:'3',name:'c',className:'1',people:'群众',range:'一周',goodsNum:200,expNum:1000,hits:50,discuss:2000}
	  ],
	  pageSize: 10,
	  pageNum: 1,
	  total:0,
	  emptyText:'暂无数据'
    }
  },
  watch: {
    dialogVisible (v) {
      if (v) {
        this.seleArr = this.selList
        this.$nextTick(() => {
          this.isChange = !this.isChange
        })
      }
    }
  },
  methods: {
    confirm () {
      this.hideDialog()
    },
    hideDialog () {
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>
