import {createStore} from 'vuex'
import vuexAlong from 'vuex-along'

export default createStore({
	state: {
		userInfo: '', //用户信息
	},
	getters: {},
	mutations: {
		//更新用户信息
		updateUserInfo(state, newUserInfo) {
		  state.userInfo = newUserInfo;
		},
		loginOut(state) {
		  state.userInfo = ''
		}
	},
	plugins:[vuexAlong({
	  name: "vuexState",
	})]
	// actions: {},
	// modules: {}
})
