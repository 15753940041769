<template>
	<div class="treatmentListAdd-container mainWrap">
		<div class="mainWrap flexB">
			<h3>{{edit?'编辑':'新增'}}卡项</h3>
			<div>
				<el-button @click="() => this.$router.go(-1)">取 消</el-button>
				<el-button type="primary" color='#112DF2' @click="submit" :loading="loading">确认保存</el-button>
			</div>
		</div>
		<el-form :model="pushData" :rules="rules" ref="formBox" label-width="120px" class="formBox">
			<div class="box">
				<el-form-item label="卡类型" prop="type">
					<el-radio-group v-model="pushData.type" :disabled="edit" size="medium">
						<el-radio v-for="item in cardClassify" :key="item.type" :label="item.type">
							{{ item.name}}
						</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="卡名称" prop="name">
					<el-input v-model="pushData.name" placeholder="请输入卡名称"></el-input>
				</el-form-item>
				<el-form-item label="有效期" prop="validity">
					<div class="mb10">
						<el-radio-group v-model="pushData.permanentValidity" @change="changeEffectiveState">
							<el-radio :label="'false'">限期</el-radio>
							<el-radio :label="'true'">无限期</el-radio>
						</el-radio-group>
					</div>
					<el-input v-if="pushData.permanentValidity == 'false'" v-model="pushData.validity" placeholder="请输入有效期">
						<template #append>月</template>
					</el-input>
				</el-form-item>
				<el-form-item label="开卡价" prop="salePrice">
					<el-input v-model="pushData.salePrice" placeholder="请输入开卡价">
						<template #prepend>￥</template>
					</el-input>
				</el-form-item>
				<el-form-item label="虚拟销量" prop="virtualSalesVolume">
					<el-input v-model="pushData.virtualSalesVolume" placeholder="虚拟销量"></el-input>
				</el-form-item>
				<el-form-item label="卡项目" prop="cardIncludeGoods">
					<el-row v-if="pushData.type != '1'">
						<el-button type="primary" link @click="addShopDialong('itemPro')">添加产品服务项目</el-button>
						<el-table v-show="itemData.length > 0" :data="itemData"
							style="width: 800px; margin-top: 15px">
							<el-table-column align="center" prop="name" label="项目" />
							<el-table-column align="center" prop="salePrice" label="单价" />
							<el-table-column align="center" label="总计入绩效次数/次" v-if="pushData.type == '1'">
								<template #default="scope">
									<el-input-number v-if="scope.$index != itemData.length - 1 &&pushData.type != '3'"
									 controls-position="right" v-model="scope.row.countnum" @change="batching.countnum = ''"
										style="width: 80%" :min="1" :step="1"></el-input-number>
									<el-input-number v-else v-model="batching.countnum"
										@change="batchingChange($event, 'countnum')" controls-position="right"
										:step="1" style="width: 80%"></el-input-number>
								</template>
							</el-table-column>
							<el-table-column align="center" label="每月计入绩效次数/次" v-if="pushData.type == '1'">
								<template #default="scope">
									<el-input-number v-if="scope.$index != itemData.length - 1 &&pushData.type != '3'"
									 controls-position="right" v-model="scope.row.monthlyachienum"
										@change="batching.monthlyachienum = ''" style="width: 80%" :min="1"
										:step="1"></el-input-number>
									<el-input-number v-else v-model="batching.monthlyachienum"
										@change="batchingChange($event, 'monthlyachienum')"
										controls-position="right" :step="1" style="width: 80%"></el-input-number>
								</template>
							</el-table-column>
							<el-table-column align="center" label="单套次数" v-if="pushData.type == '2'">
								<template #default="{row}">{{row.serviceableTimes}}</template>
							</el-table-column>
							<el-table-column align="center" label="套数" v-if="pushData.type == '2'">
								<template #default="scope">
									<el-input-number v-if="scope.$index != itemData.length - 1 &&pushData.type != '3'"
									 controls-position="right" v-model="scope.row.quantity"
										@change="quantityChange($event, scope.$index)" style="width: 80%"
										:min="1" :step="1">
									</el-input-number>
									<el-input-number v-else v-model="batching.quantity"
										@change="batchingChangeItem($event, 'quantity')"
										controls-position="right" :step="1" style="width: 80%"></el-input-number>
								</template>
							</el-table-column>
							<el-table-column align="center" label="可消费次数" v-if="pushData.type == '2'">
								<template #default="{row}">{{row.monthlyachienum}}</template>
							</el-table-column>
							<el-table-column align="center" label="操作">
								<template #default="scope">
									<el-tooltip effect="dark" content="删除" placement="bottom">
										<el-button v-if="scope.$index != itemData.length - 1" size="mini"
											@click="_removeItemPro(scope.row.id, scope.$index)">
											<el-icon><DeleteFilled /></el-icon>
										</el-button>
										<el-button v-else size="mini" @click="_removeItemPro('all')">
											<el-icon><DeleteFilled /></el-icon>
										</el-button>
									</el-tooltip>
								</template>
							</el-table-column>
						</el-table>
					</el-row>
					<el-row>
						<el-button type="primary" link @click="addShopDialong('item')">添加手法服务项目</el-button>
						<el-table v-show="pushData.cardIncludeGoods.length > 0" :data="pushData.cardIncludeGoods" border
							style="width: 800px; margin-top: 15px">
							<el-table-column align="center" prop="name" label="项目" />
							<el-table-column align="center" prop="salePrice" label="单价" />
							<el-table-column align="center" label="总计入绩效次数/次" v-if="pushData.type == '1'">
								<template #default="scope">
									<el-input-number v-if="scope.$index != pushData.cardIncludeGoods.length - 1 &&pushData.type != '3'"
									 controls-position="right" v-model="scope.row.countnum" @change="batching.countnum = ''"
										style="width: 80%" :min="1" :step="1"></el-input-number>
									<el-input-number v-else v-model="batching.countnum"
										@change="batchingChange($event, 'countnum')" controls-position="right"
										:step="1" style="width: 80%"></el-input-number>
								</template>
							</el-table-column>
							<el-table-column align="center" label="每月计入绩效次数/次" v-if="pushData.type == '1'">
								<template #default="scope">
									<el-input-number v-if="scope.$index != pushData.cardIncludeGoods.length - 1 &&pushData.type != '3'"
									 controls-position="right" v-model="scope.row.monthlyachienum"
										@change="batching.monthlyachienum = ''" style="width: 80%" :min="1"
										:step="1"></el-input-number>
									<el-input-number v-else v-model="batching.monthlyachienum"
										@change="batchingChange($event, 'monthlyachienum')"
										controls-position="right" :step="1" style="width: 80%"></el-input-number>
								</template>
							</el-table-column>
							<el-table-column align="center" label="可消费次数 (卡可消费的次数)" v-if="pushData.type == '2'">
								<template #default="scope">
									<el-input-number v-if="scope.$index != pushData.cardIncludeGoods.length - 1 && pushData.type != '3'"
									 controls-position="right" v-model="scope.row.monthlyachienum"
										@change="batching.monthlyachienum = ''" style="width: 80%" :min="1"
										:step="1"></el-input-number>
									<el-input-number v-else v-model="batching.monthlyachienum"
										@change="batchingChange($event, 'monthlyachienum')"
										controls-position="right" :step="1" style="width: 80%"></el-input-number>
								</template>
							</el-table-column>
							<el-table-column align="center" label="操作">
								<template #default="scope">
									<el-tooltip effect="dark" content="删除" placement="bottom">
										<el-button v-if="scope.$index != pushData.cardIncludeGoods.length - 1"
											size="mini" @click="_removeItem(scope.row.id, scope.$index)">
											<el-icon><DeleteFilled /></el-icon>
										</el-button>
										<el-button v-else size="mini" @click="_removeItem('all')">
											<el-icon><DeleteFilled /></el-icon>
										</el-button>
									</el-tooltip>
								</template>
							</el-table-column>
						</el-table>
					</el-row>

					<div class="generalMsg mt10" v-show="pushData.type == '3' && (pushData.cardIncludeGoods.length != 0 || itemData.length != 0)">
						<div>
							以上{{ (pushData.cardIncludeGoods.length > 0 ? (pushData.cardIncludeGoods.length - 1) : 0) + (itemData.length > 0 ?
			 (itemData.length - 1):0) }}个服务总次数：共为
							<el-input-number controls-position="right" v-model="pushData.totalServiceableTimes" :min="1" :step="1">
							</el-input-number>
							次
						</div>
						<el-alert title="每次划卡时可以从以上项目中任选一个，并划扣总次数1次" type="info" show-icon :closable="false"
							style="width: 800px; margin-top: 10px">
						</el-alert>
					</div>
					<el-alert title="总计入绩效次数：消费时员工计入绩效的总次数，卡在有效期内可不限次数消费，超过总次数则以免费券的形式计算业绩" type="info" show-icon
						:closable="false" v-show="pushData.type == '1' && pushData.cardIncludeGoods.length != 0" style="width: 800px; margin-top: 10px">
					</el-alert>
				</el-form-item>
				<el-form-item label="关联产品">
					<el-button type="primary" link @click="productShow = true">添加产品</el-button>
					<el-table v-show="pushData.giveproducts.length > 0" :data="pushData.giveproducts" border
						style="width: 800px; margin-top: 15px">
						<el-table-column align="center" prop="name" label="产品名称" />
						<el-table-column align="center" prop="salePrice" label="产品售价">
							<template #default="{row}">
								<span>￥{{ row.salePrice }}</span>
							</template>
						</el-table-column>
						<el-table-column align="center" prop="quantity" label="赠送数量">
							<template #default="{row}">
								<el-input-number style="width: 100%" v-model="row.quantity" :min="1" :step="1"
									:precision="0" controls-position="right"></el-input-number>
							</template>
						</el-table-column>
						<el-table-column align="center" label="操作">
							<template #default="scope">
								<el-tooltip effect="dark" content="删除" placement="bottom">
									<el-button size="mini" @click="removeProduct(scope.$index)">
										<el-icon><DeleteFilled /></el-icon>
									</el-button>
								</el-tooltip>
							</template>
						</el-table-column>
					</el-table>
				</el-form-item>
				<item-addlist :dialog-visible.sync="itemShow" :type="3" :close="mergeList" :sel-list.sync="selItemList"
					:visiFlag="visiFlag" :option="{ state: 1 }"></item-addlist>
				<item-addlist-pro :dialog-visible.sync="itemShowPro" :type="2" :close="mergeListItem" :visiFlag="visiFlag"
					:sel-list="selItemListPro" :option="{ state: 1 }"></item-addlist-pro>
				<product-addlist :dialog-visible.sync="productShow" :close="productMergeList"
					:sel-list.sync="selPoductList" :visiFlag="visiFlag" :option="{ online: 1 }"></product-addlist>
				<el-row :gutter="16">
					<el-col :span="3">
						<el-form-item label="是否可销售" prop="saleable">
							<div class='flexA'>
								<el-switch class="marginR10" v-model="pushData.saleable" :active-value="1"
									:inactive-value="0"></el-switch>
								<el-tooltip effect="dark" content="用于微信小程序显示的商品是否可销售" placement="top">
									<el-icon>
										<QuestionFilled />
									</el-icon>
								</el-tooltip>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="1"></el-col>
					<el-col :span="3">
						<el-form-item label="是否可用" prop="enabled">
							<div class='flexA'>
								<el-switch class="marginR10" v-model="pushData.enabled" :active-value="1"
									:inactive-value="0"></el-switch>
								<el-tooltip effect="dark" content="用于微信小程序显示的商品是否可用" placement="top">
									<el-icon>
										<QuestionFilled />
									</el-icon>
								</el-tooltip>
							</div>
						</el-form-item>
					</el-col>
				</el-row>
				<el-form-item label="购卡赠送">
					<div class="checkBoxInbox" v-if="pushData.type == '1'">
						<el-checkbox v-model="giftState.date" @change="chagneGiftState('date')">赠送有效期</el-checkbox>
						<div v-if="giftState.date">
							<el-input v-model="pushData.giveValidity" placeholder="请输入赠送有效期">
								<template slot="append">月</template>
							</el-input>
						</div>
					</div>
					<div class="checkBoxInbox">
						<el-checkbox v-model="giftState.item" @change="chagneGiftState('item')">赠送手法服务项目
						</el-checkbox>
						<div v-if="giftState.item">
							<el-button type="primary" link @click="addShopDialong('presentation')">添加赠送手法服务项目</el-button>
							<el-table v-show="pushData.giveitems.length > 0" :data="pushData.giveitems" border
								style="width: 800px; margin-top: 15px">
								<el-table-column align="center" prop="name" label="项目" />
								<el-table-column align="center" label="赠送张数/张">
									<template #default="{row}">
										<el-input-number controls-position="right" v-model="row.giftQuantity"
											value="row.giftQuantity" style="width: 80%" :min="1" :step="1">
										</el-input-number>
									</template>
								</el-table-column>
								<el-table-column align="center" label="操作">
									<template #default="scope">
										<el-tooltip effect="dark" content="删除" placement="bottom">
											<el-button size="mini" @click="_removePresentationItem(row.id,scope.$index)">
												<el-icon><DeleteFilled /></el-icon>
											</el-button>
										</el-tooltip>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
					<div class="checkBoxInbox" v-if="pushData.type == '3'">
						<el-checkbox v-model="giftState.itemPro" @change="chagneGiftState('itemPro')">赠送产品服务项目
						</el-checkbox>
						<div v-if="giftState.itemPro">
							<el-button type="primary" link @click="addShopDialong('presentationPro')">添加赠送产品服务项目</el-button>
							<el-table v-show="giveitemPro.length > 0" :data="giveitemPro" border
								style="width: 800px; margin-top: 15px">
								<el-table-column align="center" prop="name" label="项目" />
								<el-table-column align="center" label="赠送张数/张">
									<template #default="{row}">
										<el-input-number controls-position="right" v-model="row.giftQuantity"
											value="row.giftQuantity" style="width: 80%" :min="1" :step="1">
										</el-input-number>
									</template>
								</el-table-column>
								<el-table-column align="center" label="操作">
									<template #default="scope">
										<el-tooltip effect="dark" content="删除" placement="bottom">
											<el-button size="mini" @click="_removePresentationItemPro(scope.row.id,scope.$index)">
												<el-icon><DeleteFilled /></el-icon>
											</el-button>
										</el-tooltip>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
				</el-form-item>
				<item-addlist :dialog-visible.sync="presentationShow" :type="5" :close="mergePresentationList"  :visiFlag="visiFlag"
					:sel-list.sync="selPresentationItemList" :option="{ smallstate: 1 }"></item-addlist>
				<item-addlist-pro :dialog-visible.sync="presentationShowPro" :type="4" :close="mergePresentationListPro"
					:sel-list="selPresentationItemListPro" :visiFlag="visiFlag" :option="{ smallstate: 1 }"></item-addlist-pro>
				<el-form-item label="图片">
					<div class="flexA">
						<el-upload class="uploadLists" name='files' :data="uploadData" :headers="importHeaders" ref="upload" :action="uploadUrl" :file-list="fileListData" multiple accept="image/*,video/*" list-type="picture-card" :limit="6" :on-preview="handlePictureCardPreview" :on-exceed="handleExceed" :beforeUpload="beforeAvatarUpload" :on-success="(response, file, fileList)=>{return uploadSuccess(response, file, fileList,1)}" :on-remove="(file,fileList)=>{return handleRemove(file,fileList,1)}">
							<el-icon><Plus /></el-icon>
						</el-upload>
						<div class="tips">
							<div>图片注意：</div>	
							<div>1、建议尺寸：600px*600px；</div>
							<div>2、建议格式：jpg、png、jpeg、MP4；</div>
							<div>3、建议数量：6张；</div>
						</div>	
						<el-dialog v-model="dialogVisible1">
							<img w-full :src="dialogImageUrl" alt="Preview Image" />
						</el-dialog>
					</div>
				</el-form-item>
				<el-form-item label="图文详情" style="width: 800px">
					<QuillEditor ref='quillRef' v-model:content="pushData.particular" style="width:100%" :options="editorOption" contentType="html" />
				</el-form-item>
			</div>
		</el-form>
	</div>
</template>
<script>
	import ItemAddlist from "@/components/PopTable/ItemAddlist";
	import ItemAddlistPro from "@/components/PopTable/ItemAddlistPro";
	import ProductAddlist from "@/components/PopTable/ProductAddlist";
	import Validate from "@/utils/rules";
	import {uploadUrl,cardCreate,userLogin,cardInfo,cardUpdate} from '@/api'
	// import {
	//   editcard,
	//   editcardsave,
	// } from "@/api/crad";
	// 工具栏配置
	const toolbarOptions = [
	    ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
	    ["blockquote", "code-block"], // 引用
	    [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
	    [{ script: "sub" }, { script: "super" }], // 上标/下标
	    [{ indent: '-1' }, { indent: '+1' }], // 缩进
	    [{ direction: 'rtl' }], // 文本方向
	    [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
	    [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
	    [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
	    [{ font: [] }], // 字体种类
	    [{ align: [] }], // 对齐方式
	    ['clean'], // 清除文本格式
	    ['link', 'image', 'video'] // 链接、图片、视频
	]
	import {QuillEditor, Quill } from '@vueup/vue-quill'
	import { container, ImageExtend, QuillWatch } from 'quill-image-extend-module'
	import quillTool from '@/utils/quillTool'
	Quill.register(quillTool, true)
	Quill.register('modules/ImageExtend', ImageExtend)
	import 'quill/dist/quill.core.css'
	import 'quill/dist/quill.snow.css'
	import 'quill/dist/quill.bubble.css'
	export default {
		name:'cardAdd',
		components: {
			QuillEditor,
			ItemAddlist,
			ItemAddlistPro,
			ProductAddlist
		},
		created() {
			this.importHeaders.Authorization = this.userInfo.token?('Bearer'+' '+ this.userInfo.token):''
			if(this.$route.params.id){
				this.getEditData(this.$route.params.id)
				this.edit = true
			}
		},
		computed: {
		    userInfo() {
				return this.$store.state.userInfo;
		    },
		},
		watch:{
			userInfo: {
				handler(nval, oval) {
					// console.log(nval, oval);//nval改变后的新数据，oval改变前的旧数据
				},
				deep: false, // 深度监听
				immediate: true,//立即执行
			}
		},
		data() {
			const isNullArr = (rule, value, callback) => {
				if (this.itemData.length <= 0 && this.pushData.cardIncludeGoods.length <= 0) {
					callback(new Error('选择卡项目'))
				} else {
					callback()
				}
			}
			return {
				uploadUrl,
				importHeaders:{
					'Authorization':''
				},
				uploadData:{folder:'goods/'},
				fileListData:[],
				imgUrl:[],
				dialogImageUrl:'',
				dialogVisible1:false,
				cardClassify: [
					{name: '时卡',type: '1'},
					{name: '次卡',type: '2'},
					{name: '体验卡',type: '3'}
				],
				edit: false,
				temporaryArrs: [],
				title: "添加卡",
				loading: false,
				selPoductList: [],
				pushData: {
					permanentValidity: 'false',
					resources: [],
					saleable: 1,
					enabled: 1,
					name: "",
					type: "1", //1 时间卡    2 次卡    3 体验卡    stored 折扣卡
					salePrice: "",
					cardIncludeGoods: [],
					validity: "",
					giveproducts: [],
					giveValidity: "",
					giveitems: [],
					virtualSalesVolume:'',
					// commissionmoney: "",
					particular:''
				},
				shopDisabled: false,
				batching: {
					countnum: "",
					monthlyachienum: "",
					quantity: ''
				},
				giftState: {
					date: false,
					item: false,
					itemPro: false,
					coupon: false,
					card: false,
				},
				rules: {
					name: [{required: true,message: "请输入卡名称",trigger: "blur"} ],
					type: [{required: true}],
					salePrice: [{required: true,message: "请输入卡价格",validator: Validate.isRealNum,trigger: "blur"} ],
					virtualSalesVolume: [{required: false,message: '请设置虚拟销量',validator: Validate.posInt, trigger: 'blur'}],
					cardIncludeGoods: [{required: true,message: "选择卡项目",validator: isNullArr,trigger: "blur"}],
					// commissionmoney: [{validator: Validate.isNumber,trigger: "blur"}],
					giveValidity: [{validator: Validate.posInt,trigger: "blur"}],
					validity: [{required: true,validator: Validate.posInt,trigger: "blur"} ],
					enabled:[{required:true}],
					saleable:[{required:true}]
				},
				itemShow: false,
				itemShowPro: false,
				productShow: false,
				presentationShow: false,
				presentationShowPro: false,
				selItemList: [], // 选中的项目
				selItemListPro: [], // 选中的产品项目
				itemData: [],
				giveitemPro: [],
				selPresentationItemListPro: [],
				selPresentationItemList: [],
				editorOption: {
					theme: 'snow',
					placeholder: '请输入内容',
					modules: {
						ImageExtend: {
							name: 'files', // 参数名
							action: uploadUrl, // 服务器地址，如果为空则采用base64插入图片
							headers: xhr => { // 设置请求头参数（选填）
								xhr.setRequestHeader("Authorization", (this.userInfo.token?('Bearer'+' '+ this.userInfo.token):''))
							},
							response: res => {
								if(res.code=="A0200"||res.code=="A0201"){// 登录过期，重新登录
									this.$store.commit("updateUserInfo", '');
									this.$router.push("/")
									return
								}
								if(res.code=="A0202"){
									this.userLogin()
									return
								}
								return res.data[0]
							},
							size: 10, // 图片不能超过10M
							sizeError: () => {
								this.$message.error('粘贴图片大小不能超过10MB!')
							}
						},
						toolbar: {
							container: toolbarOptions,
							handlers: {
								image: function(value) {
									QuillWatch.emit(this.quill.id)
								},
								link: function(value) {
									if (value) {
										var href = prompt('请输入链接地址：')
										this.quill.format('link', href)
									} else {
										this.quill.format('link', false)
									}
								},
								video: function(value) {
									if (value) {
										var href = prompt('请输入视频链接：')
										this.quill.format('video', href)
									} else {
										this.quill.format('video', false)
									}
								}
							}
						}
					}
				}
			};
		},
		methods: {
			// 提交
			submit() {
				const arr = JSON.parse(JSON.stringify(this.pushData.cardIncludeGoods));
				const arr2 = JSON.parse(JSON.stringify(this.pushData.giveitems));
				const itemData = JSON.parse(JSON.stringify(this.itemData))
				const giveitemPro = JSON.parse(JSON.stringify(this.giveitemPro))
				arr.pop();
				if (itemData.length > 0 && (this.pushData.type == "2" || this.pushData.type == "3")) {
					itemData.pop()
					for (const i in itemData) {
						if(this.pushData.type == "3"){
							delete itemData[i].quantity
						}
						delete itemData[i].serviceableTimes
						arr.push(itemData[i])
					}
				}
				if (arr.length == 0) {
					this.$message({
						showClose: true,
						message: "请选择卡项目",
						type: "warning",
					});
					return;
				}
				if (giveitemPro.length > 0 && this.pushData.type == "3") {
					for (const i in giveitemPro) {
						arr2.push(giveitemPro[i])
					}
				}
				for (const i in arr) {
					const value = arr[i].countnum;
					const value2 = arr[i].monthlyachienum;
					const v = Number(value);
					const v2 = Number(value2);
					if (this.pushData.type == "1") {
						if (
							!Number.isInteger(v) ||
							value == "" ||
							value == null ||
							value <= 0
						) {
							this.$message({
								showClose: true,
								message: "请输入总计入绩效次数",
								type: "warning",
							});
							return;
						}
						if (
							!Number.isInteger(v2) ||
							value2 == "" ||
							value2 == null ||
							value2 <= 0
						) {
							this.$message({
								showClose: true,
								message: "请输入每月计入绩效次数",
								type: "warning",
							});
							return;
						}
					} else if (this.pushData.type == "2") {
						if (
							!Number.isInteger(v2) ||
							value2 == "" ||
							value2 == null ||
							value2 <= 0
						) {
							this.$message({
								showClose: true,
								message: "请输入正确的消费次数",
								type: "warning",
							});
							return;
						}
						arr[i].countnum = arr[i].monthlyachienum;
					} else {
						if (this.pushData.totalServiceableTimes == "" || this.pushData.totalServiceableTimes <= 0) {
							this.$message({
								showClose: true,
								message: "请输入正确的服务总次数",
								type: "warning",
							});
							return;
						}
					}
				}
				for (const i in arr2) {
					const value = arr2[i].giftQuantity;
					const v = Number(value);
					if (
						!Number.isInteger(v) ||
						value == "" ||
						value == null ||
						value <= 0
					) {
						this.$message({
							showClose: true,
							message: "请输入赠送张数",
							type: "warning",
						});
						return;
					}
				}
				this.$refs.formBox.validate((valid) => {
					if (valid) {
						this.loading = true;
						let data = Object.assign({}, this.pushData, {
							resources: this.imgUrl,
						});
						data = JSON.parse(JSON.stringify(data));
						data.enabled = data.enabled == 1 ? true : false
						data.saleable = data.saleable == 1 ? true : false
						if(data.permanentValidity == 'true'){
							data.validity = -1
						}
						arr.forEach(item=>{
							item.goodsId = item.id
							if(this.pushData.type == "1"){
								item.totalPerformanceTimes = item.countnum
								item.monthPerformanceTimes = item.monthlyachienum
							}
							if(this.pushData.type == "2"){
								// item.quantity = item.countnum
								item.serviceableTimes = item.monthlyachienum
							}
							delete item.id
							delete item.name
							delete item.salePrice
							delete item.countnum
							delete item.monthlyachienum
						})
						if(data.giveproducts.length>0){
							for (const i in data.giveproducts) {
								data.giveproducts[i].goodsId = data.giveproducts[i].id
								delete data.giveproducts[i].id
								delete data.giveproducts[i].name
								delete data.giveproducts[i].salePrice
								arr.push(data.giveproducts[i])
							}
						}
						data.giveitems = arr2
						if(data.giveitems.length>0){
							for (const i in data.giveitems) {
								data.giveitems[i].goodsId = data.giveitems[i].id
								delete data.giveitems[i].id
								delete data.giveitems[i].name
								arr.push(data.giveitems[i])
							}
						}
						data.cardIncludeGoods = arr
						delete data.giveproducts
						delete data.giveitems
						let postUrl = cardCreate
						if(this.$route.params.id){
							postUrl = cardUpdate
						}
						postUrl(data).then((res) => {
							if(res.success){
								this.$router.replace('/goods/cardList')
							}else{
								this.loading = false
								this.$message.error(res.msg)
							}
						});
					}
				});
			},
			getEditData(id) {
				this.loading = true;
				cardInfo({data:{id}}).then((res) => {
					this.loading = false;
					let data = res.data
					if(data.resources&&data.resources.length>0){
						data.resources.forEach(ies =>{
							this.fileListData.push({url: ies.resource});
							this.imgUrl.push(ies.resource)
						});
					}
					delete data.resources
					this.$nextTick(() => {
						this.$refs.quillRef.setContents(data.particular)
					})
					data.enabled = data.enabled ? 1 : 0
					data.saleable = data.saleable ? 1 : 0
					data.type = data.type.toString()
					data.permanentValidity = data.permanentValidity.toString()
					if(data.permanentValidity == 'true'){
						data.validity = 5000
					}
					data.cardIncludeGoods.forEach(item=>{
						item.id = item.goodsId
						item.name = item.goodsName
						item.salePrice = item.goodsSalePrice
						delete item.goodsId
						delete item.goodsName
						delete item.goodsSalePrice
					})
					let selPoductList = []
					data.giveproducts = data.cardIncludeGoods.filter(item=>item.goodsType==1)//关联产品
					data.giveproducts.forEach(item=>{
						selPoductList.push({
							id:item.id,
							name:item.name,
							salePrice:item.salePrice
						})
					})
					this.selPoductList = selPoductList
					let arr = data.cardIncludeGoods.filter(item=>item.goodsType!=1)
					let arr2 = arr.filter(item=>item.gift)
					let arr1 = arr.filter(item=>!item.gift)
					let giveItemList = [],giveItemListPro = [],giveItemList1 = [],giveItemListPro1 = [],
						itemList = [],itemListPro = [],itemList1 = [],itemListPro1 = []
					data.giveitems = giveItemList1 = arr2.filter(v => {//赠送手法
						if (v.goodsType == 3) {
							return v
						}
					})
					giveItemList1.forEach(item=>{
						giveItemList.push({
							id:item.id,
							name:item.name,
							salePrice:item.salePrice
						})
					})
					this.selPresentationItemList = giveItemList
					this.giveitemPro = giveItemListPro1 = arr2.filter(v => {//赠送产品
						if (v.goodsType == 2) {
							return v
						}
					})
					giveItemListPro1.forEach(item=>{
						giveItemListPro.push({
							id:item.id,
							name:item.name,
							salePrice:item.salePrice
						})
					})
					this.selPresentationItemListPro = giveItemListPro
					this.itemData = itemListPro1 = arr1.filter(v => {//卡项目产品
						if (v.goodsType == 2) {
							v.monthlyachienum = v.serviceableTimes*1
							v.serviceableTimes = v.monthlyachienum/Number(v.quantity)
							return v
						}
					})
					itemListPro1.forEach(item=>{	
						itemListPro.push({ 
							id:item.id,
							name:item.name,
							salePrice:item.salePrice
						})
					})
					this.selItemListPro = itemListPro
					data.cardIncludeGoods = itemList1 = arr1.filter(v => {//卡项目手法
						if (v.goodsType == 3) {
							if(data.type == "1"){
								v.countnum = v.totalPerformanceTimes
								v.monthlyachienum = v.monthPerformanceTimes
							}
							if(data.type == "2"){
								v.countnum = v.quantity
								v.monthlyachienum = v.serviceableTimes
							}
							return v
						}
					})
					itemList1.forEach(item=>{
						itemList.push({
							id:item.id,
							name:item.name,
							salePrice:item.salePrice
						})
					})
					this.selItemList = itemList
					if (data.cardIncludeGoods.length > 0) {
						data.cardIncludeGoods.push({
							name: "批量处理",
							salePrice: "-",
						});
					}
					if (data.type != "1" && this.itemData.length > 0) {
						this.itemData.push({
							name: "批量处理"	,
							salePrice: "-",
							serviceableTimes: "-",
							monthlyachienum: "-"
						});
					}
					if (data.giveitems.length > 0) {
						this.giftState.item = true
					}
					if (this.giveitemPro.length > 0) {
						this.giftState.itemPro = true
					}
					if (data.giveValidity) {
						this.giftState.date = true;
					}
					this.pushData = data
				});
			},
			quantityChange(v, index) {
				this.itemData[index].monthlyachienum = this.itemData[index].serviceableTimes * v
			},
			// 批量修改
			batchingChange(v, type) {
				v = parseFloat(v) || "";
				if (v <= 0 || (v + "").indexOf(".") != "-1") {
					this.batching[type] = "";
				} else {
					this.batching[type] = v;
					for (let i = 0; i < this.pushData.cardIncludeGoods.length; i++) {
						this.pushData.cardIncludeGoods[i][type] = v;
					}
				}
			},
			//产品线项目
			batchingChangeItem(v, type) {
				v = parseFloat(v) || "";
				if (v <= 0 || (v + "").indexOf(".") != "-1") {
					this.batching[type] = "";
				} else {
					this.batching[type] = v;
					for (let i = 0; i < this.itemData.length; i++) {
						this.itemData[i][type] = v;
						this.itemData[i].monthlyachienum = this.itemData[i].serviceableTimes * v
					}
					this.itemData[this.itemData.length - 1].monthlyachienum = '-'
				}
			},
			// 修改是否限期
			changeEffectiveState(v) {
				if (v == 'false') {
					this.pushData.validity = "";
				} else {
					this.pushData.validity = 5000;
				}
			},
			// 修改购卡赠送
			chagneGiftState(type) {
				if (type === "date") {
					this.pushData.giveValidity = "";
				} else if (type == "item") {
					const giveitems = this.pushData.giveitems;
					const selPresentationItemList = this.selPresentationItemList;
					giveitems.splice(0, giveitems.length);
					selPresentationItemList.splice(0, selPresentationItemList.length);
				}
			},
			addShopDialong(type) {
				this.itemShow = type == "item";
				this.itemShowPro = type == "itemPro";
				this.productShow = type == "service";
				this.presentationShow = type == "presentation";
				this.presentationShowPro = type == "presentationPro";
			},
			visiFlag(val,type){
				if(type == 1){
					this.productShow = val
					return
				}
				if(type == 2){
					this.itemShowPro = val
					return
				}
				if(type == 3){
					this.itemShow = val
					return
				}
				if(type == 4){
					this.presentationShowPro = val
					return
				}
				if(type == 5){
					this.presentationShow = val
					return
				}
			},
			mergeList(data) {
				let cardIncludeGoods = JSON.parse(JSON.stringify(this.pushData.cardIncludeGoods))
				let arr = [];
				for (let i = 0; i < data.length; i++) {
					let pushState = false;
					for (let j = 0; j < cardIncludeGoods.length; j++) {
						if (data[i].id === cardIncludeGoods[j].id) {
							if (data[i].name != '批量处理') {
								arr.push({
									id: cardIncludeGoods[j].id,
									name: cardIncludeGoods[j].name,
									gift: false,
									countnum: cardIncludeGoods[j].countnum ? cardIncludeGoods[j].countnum : '',
									monthlyachienum: cardIncludeGoods[j].monthlyachienum ? cardIncludeGoods[j]
										.monthlyachienum : '',
									salePrice: cardIncludeGoods[j].salePrice
								});
							}
							break;
						}
						if (j == cardIncludeGoods.length - 1) {
							pushState = true;
						}
					}
					if (pushState || cardIncludeGoods.length == 0) {
						arr.push({
							id: data[i].id,
							name: data[i].name,
							gift: false,
							countnum: "",
							monthlyachienum: "",
							salePrice: data[i].salePrice
						});
					}
				}
				if (arr.length != 0) {
					arr.push({
						name: "批量处理",
						salePrice: "-",
					});
				}
				this.pushData.cardIncludeGoods = arr;
			},
			mergeListItem(data) {
				let cardIncludeGoods = JSON.parse(JSON.stringify(this.itemData))
				let arr = [];
				for (let i = 0; i < data.length; i++) {
					let pushState = false;
					for (let j = 0; j < cardIncludeGoods.length; j++) {
						if (data[i].id === cardIncludeGoods[j].id) {
							if (data[i].name != '批量处理') {
								cardIncludeGoods[j].serviceableTimes = cardIncludeGoods[j].serviceableTimes
								cardIncludeGoods[j].monthlyachienum = cardIncludeGoods[j].monthlyachienum ? cardIncludeGoods[j]
									.monthlyachienum : cardIncludeGoods[j].serviceableTimes * 1
								cardIncludeGoods[j].quantity = cardIncludeGoods[j].monthlyachienum / cardIncludeGoods[j]
									.serviceableTimes
								arr.push({
									id: cardIncludeGoods[j].id,
									name: cardIncludeGoods[j].name,
									gift: false,
									countnum: cardIncludeGoods[j].countnum ? cardIncludeGoods[j].countnum : '',
									monthlyachienum: cardIncludeGoods[j].monthlyachienum ? cardIncludeGoods[j]
										.monthlyachienum : '',
									salePrice: cardIncludeGoods[j].salePrice,
									serviceableTimes: cardIncludeGoods[j].serviceableTimes,
									quantity: cardIncludeGoods[j].quantity
								});
							}
							break;
						}
						if (j == cardIncludeGoods.length - 1) {
							pushState = true;
						}
					}
					if (pushState || cardIncludeGoods.length == 0) {
						arr.push({
							id: data[i].id,
							name: data[i].name,
							serviceableTimes: data[i].serviceableTimes,
							gift: false,
							countnum: "",
							quantity: 1,
							monthlyachienum: data[i].serviceableTimes * 1,
							salePrice: data[i].salePrice,
						});
					}
				}
				if (arr.length != 0) {
					arr.push({
						name: "批量处理",
						salePrice: "-",
						serviceableTimes: "-",
						monthlyachienum: "-"
					});
				}
				this.itemData = arr
			},
			productMergeList(data) {
				let giveproducts = JSON.parse(JSON.stringify(this.pushData.giveproducts))
				let arr = [];
				for (let i = 0; i < data.length; i++) {
					let pushState = false;
					for (let j = 0; j < giveproducts.length; j++) {
						if (data[i].id === giveproducts[j].id) {
							arr.push(giveproducts[j]);
							break;
						}
						if (j == giveproducts.length - 1) {
							pushState = true;
						}
					}
					if (pushState || giveproducts.length == 0) {
						arr.push({
							id: data[i].id,
							name: data[i].name,
							quantity: 1,
							gift: false,
							salePrice: data[i].salePrice,
						});
					}
				}
				this.pushData.giveproducts = arr;
			},
			mergePresentationList(data) {
				let giveitems = JSON.parse(JSON.stringify(this.pushData.giveitems))
				let arr = [];
				for (let i = 0; i < data.length; i++) {
					let pushState = false;
					for (let j = 0; j < giveitems.length; j++) {
						if (data[i].id === giveitems[j].id) {
							giveitems[j].giftQuantity = giveitems[j].giftQuantity?giveitems[j].giftQuantity:1
							giveitems[j].gift = true
							arr.push(giveitems[j]);
							break;
						}
						if (j == giveitems.length - 1) {
							pushState = true;
						}
					}
					if (pushState || giveitems.length == 0) {
						arr.push({
							id: data[i].id,
							name: data[i].name,
							gift: true,
							giftQuantity: 1
						});
					}
				}
				this.pushData.giveitems = arr;
			},
			mergePresentationListPro(data) {
				let giveitems = JSON.parse(JSON.stringify(this.giveitemPro))
				let arr = [];
				for (let i = 0; i < data.length; i++) {
					let pushState = false;
					for (let j = 0; j < giveitems.length; j++) {
						if (data[i].id === giveitems[j].id) {
							giveitems[j].giftQuantity = giveitems[j].giftQuantity?giveitems[j].giftQuantity:1
							giveitems[j].gift = true
							arr.push(giveitems[j]);
							break;
						}
						if (j == giveitems.length - 1) {
							pushState = true;
						}
					}
					if (pushState || giveitems.length == 0) {
						arr.push({
							id: data[i].id,
							name: data[i].name,
							gift: true,
							giftQuantity: 1
						});
					}
				}
				this.giveitemPro = arr;
			},
			// 删除
			_removeItem(id, index) {
				let {
					selItemList,
					pushData: {
						cardIncludeGoods
					},
				} = this;
				if (id === "all") {
					cardIncludeGoods.splice(0, cardIncludeGoods.length);
					selItemList.splice(0, selItemList.length);
					return;
				}
				cardIncludeGoods.splice(index, 1);
				for (let b = 0; b < selItemList.length; b++) {
					const bi = selItemList[b];
					if (id == bi.id) {
						selItemList.splice(b, 1);
					}
				}
				if (cardIncludeGoods.length == 1) {
					cardIncludeGoods.splice(0, 1);
				}
			},
			// 删除产品项目
			_removeItemPro(id, index) {
				let {
					selItemListPro,
					itemData
				} = this;
				if (id === "all") {
					itemData.splice(0, itemData.length);
					selItemListPro.splice(0, selItemListPro.length);
					return;
				}
				itemData.splice(index, 1);
				for (let b = 0; b < selItemListPro.length; b++) {
					const bi = selItemListPro[b];
					if (id == bi.id) {
						selItemListPro.splice(b, 1);
					}
				}
				if (itemData.length == 1) {
					itemData.splice(0, 1);
				}
			},
			_removePresentationItem(id, index) {
				let {
					selPresentationItemListPro,
					pushData: {
						giveitems
					},
				} = this;
				giveitems.splice(index, 1);
				for (let b = 0; b < selPresentationItemListPro.length; b++) {
					const bi = selPresentationItemListPro[b];
					if (id == bi.id) {
						selPresentationItemListPro.splice(b, 1);
					}
				}
			},
			_removePresentationItemPro(id, index) {
				let {
					selPresentationItemList,
					giveitemPro
				} = this;
				giveitemPro.splice(index, 1);
				for (let b = 0; b < selPresentationItemList.length; b++) {
					const bi = selPresentationItemList[b];
					if (id == bi.id) {
						selPresentationItemList.splice(b, 1);
					}
				}
			},
			removeProduct(index) {
				this.pushData.giveproducts.splice(index, 1);
				this.selPoductList.splice(index, 1);
			},
			handlePictureCardPreview(file, fileList,types){
				this.imgUrl.forEach((ies,index) =>{
					if(file.response){
						if(ies==file.response.data[0]){
							this.dialogImageUrl = ies
							this.dialogVisible1 = true
						}
						return
					}
					if(ies==file.url){
						this.dialogImageUrl = ies
						this.dialogVisible1 = true
					}
				});
			},
			handleRemove(file, fileList,types) {
				this.fileListData.forEach((ies,index) =>{
					if(ies.url){
						if(ies.url==file.url || ies.url==file.response.data[0]){
							this.fileListData.splice(index,1)
						}
					}else{
						if(ies==file.url || ies==file.response.data[0]){
							this.fileListData.splice(index,1)
						}
					}
				});
				this.imgUrl.forEach((ies,index) =>{
					if(ies==file.url || ies==file.response.data[0]){
						this.imgUrl.splice(index,1)
					}
				});
			},
			uploadSuccess(response, file, fileList,types) {
				let uid = file.uid;
				let idx = fileList.findIndex(item => item.uid === uid)
				if(response.code.indexOf("A02")!=-1){
					fileList.splice(idx, 1)
				}
				if(response.code=="A0200"||response.code=="A0201"){// 登录过期，重新登录
					this.$store.commit("updateUserInfo", '');
					this.$router.push("/")
					return
				}
				if(response.code=="A0202"){
					this.userLogin()
					return
				}
				this.fileListData.push(response.data[0])
				this.imgUrl.push(response.data[0])
			},
			userLogin(){
				let userInfo = this.userInfo
				let postData = {
					"grantType": "PLATFORM_REFRESH_TOKEN",
					refreshTokenTokenRequest:{
						refreshToken: this.userInfo.refreshToken
					}
				}
				userLogin(postData).then(res1=>{
					userInfo.token = res1.data.accessToken;
					userInfo.refreshToken = res1.data.refreshToken;
					this.$store.commit("updateUserInfo", userInfo);
					this.importHeaders.Authorization = userInfo.token?('Bearer'+' '+ userInfo.token):''
				})
			},
			handleExceed(files, fileList) {
				this.$message.warning("当前限制选择 6 个文件");
			},
			beforeAvatarUpload(file) {
				const extension = file.name.endsWith(".jpg")||file.name.endsWith(".JPG");
				const extension1 = file.name.endsWith(".png")||file.name.endsWith(".PNG");
				const extension2 = file.name.endsWith(".jpeg")||file.name.endsWith(".JPEG");
				const extension3 = file.name.endsWith(".mp4")||file.name.endsWith(".MP4");
				const extension4 = file.name.endsWith(".avi")||file.name.endsWith(".AVI");
				const extension5 = file.name.endsWith(".rmvb")
				const extension6 = file.name.endsWith(".3gp")||file.name.endsWith(".3GP");
				const extension7 = file.name.endsWith(".mov")||file.name.endsWith(".MOV");
				// const isLt2M = file.size / 1024 / 1024 < 2;
				if (!extension && !extension1 && !extension2 && !extension3 && !extension4 && !extension5 && !extension6 && !extension7) {
					this.$message({
						message: "上传模板只能是 .jpg,.png,.jpeg,.mp4,.avi,.rmvb,.3gp,.mov 格式!",
						type: "error"
					});
				}
				// if (!isLt2M) {
				// 	this.$message.error("上传图片大小不能超过 2M!");
				// }
				// return (extension || extension1 || extension2 || extension3) && isLt2M;
				return extension || extension1 || extension2 || extension3 || extension4 || extension5 || extension6 || extension7;
			}
		}
	};
</script>

<style rel="stylesheet/scss" lang='scss' scoped>
	.box {
		margin-bottom: 20px;
		padding-bottom:50px;
		.checkBoxInbox {
			/* padding-bottom: 10px;
			margin-bottom: 10px; */

			&:last-child {
				border-bottom: 0px none;
			}

			.el-checkbox:last-of-type {
				margin-right: 20px;
			}
		}
	}

	.treatmentListAdd-container {
		.formBox {
			.el-radio {
				margin-right: 20px;
			}

			.el-input,
			.el-select {
				width: 280px;
				// margin-right: 10px;
			}
			::v-deep .el-form-item__content{
				display: block;
			}
			.mb10 {
				margin-bottom: 10px;
			}

			.ml10 {
				margin-left: 10px;
			}

			.t-center {
				text-align: center;
			}

			.el-table__row {
				height: 60px;
			}

			.el-alert {
				height: 36px;
				width: auto;
			}
		}
	}
	::v-deep .el-row{
		display: block;
	}
</style>
