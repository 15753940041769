import axios from 'axios'
// import ResizeObserver from 'resize-observer-polyfill'
import { createApp } from 'vue'
import VueQrcode from 'vue-qrcode'
// import vueQr from 'vue-qr'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { ElTable, ElTableColumn } from 'element-plus'
import 'dayjs/locale/zh-cn';//中文
import locale from 'element-plus/lib/locale/lang/zh-cn';//中文
 
// 获取组件的props
const TableProps = ElTable.props
const TableColumnProps = ElTableColumn.props
 
// 修改默认props
// 全局el-table设置
TableProps.border = { type: Boolean, default: true } // 边框线
// 全局el-table-column设置
TableColumnProps.align = { type: String, default: 'center' } // 居中
// 解决 ElTable 自动宽度高度导致的「ResizeObserver loop limit exceeded」问题
TableColumnProps.minWidth = { type: String, default: '80px' } // 设置宽度
TableColumnProps.showOverflowTooltip = { type: Boolean, default: true } // 文本溢出

const app = createApp(App)

// //导入Svg图片插件，可以在页面上显示Svg图片
// import 'virtual:svg-icons-register'
import SvgIcon from "./components/SvgIcon.vue";
app.component('SvgIcon',SvgIcon)
app.config.globalProperties.$http = axios
 
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
   app.component(key, component)
}

app.component('vue-qrcode', VueQrcode)
app.use(ElementPlus,{ locale }).use(store).use(router).mount('#app')

// Vue.config.productionTip = false
// let VM = new Vue({
//   el: '#app',
//   router,
//   store,
//   render: h => h(App)
// })

// export default VM