<template>
	<div class="mainWrap">
		<div class="mainWrap flexB marginB24">
			<h3>{{edit?'编辑':'新增'}}服务项目</h3>
			<div class="footerBtn">
				<el-button @click="() => this.$router.go(-1)">取 消</el-button>
				<el-button type="primary" color='#112DF2' @click="submit" :loading="loading">{{edit?'保 存':'添 加'}}</el-button>
			</div>
		</div>
		<el-form :model="pushData" ref="formBox" :rules="rules" label-width="130px" class="formBox">
			<el-row :gutter="16">
				<el-col :span="8">
					<el-form-item label="项目类型" prop="type">
						<el-radio-group v-model="pushData.type" :disabled='edit'>
							<el-radio label="3">手法服务</el-radio>
							<el-radio label="2">产品服务</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="项目名称" prop="name">
						<el-input v-model="pushData.name" maxlength="20" placeholder="输入项目名称"></el-input>
					</el-form-item>
					<el-form-item label="销售价" prop="salePrice">
						<el-input v-model="pushData.salePrice" maxlength="11" placeholder="销售价"></el-input>
					</el-form-item>
					<el-form-item label="项目服务次数" v-if="pushData.type=='2'" prop="serviceableTimes">
					  <el-input  placeholder="可用次数" v-model="pushData.serviceableTimes"></el-input>
					</el-form-item>
					<el-form-item label="服务项目类目" prop="goodsCategoryId">
						<el-cascader ref="cascaderAddr" :options="options" v-model='pushData.goodsCategoryId' @change="handleChange" />
					</el-form-item>	
					<el-form-item label="虚拟销量" prop="virtualSalesVolume">
						<el-input v-model="pushData.virtualSalesVolume" placeholder="虚拟销量"></el-input>
					</el-form-item>
					<el-form-item label="是否可用" prop="enabled">
						<el-switch style="--el-switch-on-color:#A2A5B8; --el-switch-off-color:#112DF2"
						 v-model="pushData.enabled" :active-value="0" :inactive-value="1"></el-switch>
					</el-form-item>
					<el-form-item label="是否可销售" prop="saleable">
						<el-switch style="--el-switch-on-color:#A2A5B8; --el-switch-off-color:#112DF2"
						 v-model="pushData.saleable" :active-value="0" :inactive-value="1"></el-switch>
					</el-form-item>
				</el-col>
			</el-row>
			<el-form-item label="项目图片" style="width:90%;">
				<el-upload class="uploadLists" name='files' :data="uploadData" :headers="importHeaders" ref="upload" :action="uploadUrl" :file-list="fileListData" multiple accept="image/*,video/*" list-type="picture-card" :limit="6" :on-preview="handlePictureCardPreview" :on-exceed="handleExceed" :beforeUpload="beforeAvatarUpload" :on-success="(response, file, fileList)=>{return uploadSuccess(response, file, fileList,1)}" :on-remove="(file,fileList)=>{return handleRemove(file,fileList,1)}">
					<el-icon><Plus /></el-icon>
				</el-upload>
				<div class="tips">
					<div>图片注意：</div>	
					<div>1、建议尺寸：600px*600px；</div>
					<div>2、建议格式：jpg、png、jpeg、MP4；</div>
					<div>3、建议数量：6张；</div>
				</div>	
				<el-dialog v-model="dialogVisible">
					<img w-full :src="dialogImageUrl" alt="Preview Image" />
				</el-dialog>
			</el-form-item>
			<el-form-item label="项目简介" style="width:60%;">
				<QuillEditor ref='quillRef' v-model:content="pushData.particular" style="width:100%" :options="editorOption" contentType="html" />
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	import Validate from '@/utils/rules'
	import {uploadUrl,goodsCreate,goodsCategoryTreeList,userLogin,goodsInfo,goodsUpdate} from '@/api'
	// 工具栏配置
	const toolbarOptions = [
	    ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
	    ["blockquote", "code-block"], // 引用
	    [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
	    [{ script: "sub" }, { script: "super" }], // 上标/下标
	    [{ indent: '-1' }, { indent: '+1' }], // 缩进
	    [{ direction: 'rtl' }], // 文本方向
	    [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
	    [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
	    [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
	    [{ font: [] }], // 字体种类
	    [{ align: [] }], // 对齐方式
	    ['clean'], // 清除文本格式
	    ['link', 'image', 'video'] // 链接、图片、视频
	]
	import {QuillEditor, Quill } from '@vueup/vue-quill'
	import { container, ImageExtend, QuillWatch } from 'quill-image-extend-module'
	import quillTool from '@/utils/quillTool'
	Quill.register(quillTool, true)
	Quill.register('modules/ImageExtend', ImageExtend)
	import 'quill/dist/quill.core.css'
	import 'quill/dist/quill.snow.css'
	import 'quill/dist/quill.bubble.css'
	export default{
		components: {QuillEditor},
		data (){
			return{
				uploadUrl,
				importHeaders:{
					'Authorization':''
				},
				uploadData:{folder:'goods/'},
				fileListData:[],
				imgUrl:[],
				dialogImageUrl:'',
				dialogVisible:false,
				pushData:{
					type:'2',
					serviceableTimes:1,
					name:'',
					salePrice:'',
					particular:'',
					resources:[],
					goodsCategoryId:'',
					virtualSalesVolume:'',
					enabled:1,
					saleable:1
				},
				options:[],
				rules: {
					name: [{required: true, message: '请输入产品名称', trigger: 'blur'}],
					salePrice: [{required: true,message: '请输入销售价',validator: Validate.isNumber, trigger: 'blur'}],
					virtualSalesVolume: [{required: false,message: '请设置虚拟销量',validator: Validate.posInt, trigger: 'blur'}],
					goodsCategoryId: [{required: true, message: '请选择产品类目', trigger: 'blur'}],
					fileListData:[{}],
					serviceableTimes:[{required:true, message: '请输入服务次数', trigger: 'blur'}],
					type:[{required:true}],
					enabled:[{required:true}],
					saleable:[{required:true}]
				},
				loading: false,
				edit: false,
				editorOption: {
					theme: 'snow',
					placeholder: '请输入内容',
					modules: {
						ImageExtend: {
							name: 'files', // 参数名
							action: uploadUrl, // 服务器地址，如果为空则采用base64插入图片
							headers: xhr => { // 设置请求头参数（选填）
								xhr.setRequestHeader("Authorization", (this.userInfo.token?('Bearer'+' '+ this.userInfo.token):''))
							},
							response: res => {
								if(res.code=="A0200"||res.code=="A0201"){// 登录过期，重新登录
									this.$store.commit("updateUserInfo", '');
									this.$router.push("/")
									return
								}
								if(res.code=="A0202"){
									this.userLogin()
									return
								}
								return res.data[0]
							},
							size: 10, // 图片不能超过10M
							sizeError: () => {
								this.$message.error('粘贴图片大小不能超过10MB!')
							}
						},
						toolbar: {
							container: toolbarOptions,
							handlers: {
								image: function(value) {
									QuillWatch.emit(this.quill.id)
								},
								link: function(value) {
									if (value) {
										var href = prompt('请输入链接地址：')
										this.quill.format('link', href)
									} else {
										this.quill.format('link', false)
									}
								},
								video: function(value) {
									if (value) {
										var href = prompt('请输入视频链接：')
										this.quill.format('video', href)
									} else {
										this.quill.format('video', false)
									}
								}
							}
						}
					}
				}
			}
		},
		computed: {
		    userInfo() {
				return this.$store.state.userInfo;
		    },
		},
		watch:{
			userInfo: {
				handler(nval, oval) {
					// console.log(nval, oval);//nval改变后的新数据，oval改变前的旧数据
				},
				deep: false, // 深度监听
				immediate: true,//立即执行
			}
		},
		created() {
			this.getDate()
			this.importHeaders.Authorization = this.userInfo.token?('Bearer'+' '+ this.userInfo.token):''
			if(this.$route.params.id){
				this.getEditData(this.$route.params.id)
				this.edit = true
			}
		},	
		methods:{
			getEditData(id){
				goodsInfo({data:{id}}).then(res=>{
					let data = res.data
					if(data.resources&&data.resources.length>0){
						data.resources.forEach(ies =>{
							this.fileListData.push({url: ies.resource});
							this.imgUrl.push(ies.resource)
						});
					}
					delete data.resources
					this.$nextTick(() => {
						this.$refs.quillRef.setContents(data.particular)
					})
					data.enabled = data.enabled ? 1 : 0
					data.saleable = data.saleable ? 1 : 0
					data.type = data.type.toString()
					this.pushData = data
				})
			},
			handleChange(e) {
				this.pushData.goodsCategoryId = this.$refs['cascaderAddr'].getCheckedNodes()[0].value
			},
			// 确认提交
			submit() {
				this.$refs.formBox.validate((valid) => {
					if (valid) {
						this.loading = true
						this.pushData.resources = this.imgUrl
						this.pushData.enabled = this.pushData.enabled == 1 ? true : false
						this.pushData.saleable = this.pushData.saleable == 1 ? true : false
						let postUrl = goodsCreate
						if(this.$route.params.id){
							postUrl = goodsUpdate
						}
						postUrl(this.pushData).then(res=>{
							if(res.success){
								this.$router.replace('/goods/itemsList')
							}else{
								this.loading = false
								this.$message.error(res.msg)
							}
						})
					}
				})
			},
			getDate(){
				goodsCategoryTreeList({}).then(res=>{
					if(res.success){
						let data = res.data
						let arr = []
						if(data.length>0){
							data.forEach(item=>{
								item.label = item.name
								item.value = item.id
								if(item.children&&item.children.length>0){
									for (let i of item.children) {
										i.label = i.name
										i.value = i.id
									}
								}else{
									item.disabled = true
								}
								arr.push(item)
							})
						}
						this.options = arr
					}
				})
			},
			handlePictureCardPreview(file, fileList,types){
				this.imgUrl.forEach((ies,index) =>{
					if(file.response){
						if(ies==file.response.data[0]){
							this.dialogImageUrl = ies
							this.dialogVisible = true
						}
						return
					}
					if(ies==file.url){
						this.dialogImageUrl = ies
						this.dialogVisible = true
					}
				});
			},
			handleRemove(file, fileList,types) {
				this.fileListData.forEach((ies,index) =>{
					if(ies.url){
						if(ies.url==file.url || ies.url==file.response.data[0]){
							this.fileListData.splice(index,1)
						}
					}else{
						if(ies==file.url || ies==file.response.data[0]){
							this.fileListData.splice(index,1)
						}
					}
				});
				this.imgUrl.forEach((ies,index) =>{
					if(ies==file.url || ies==file.response.data[0]){
						this.imgUrl.splice(index,1)
					}
				});
			},
			uploadSuccess(response, file, fileList,types) {
				let uid = file.uid;
				let idx = fileList.findIndex(item => item.uid === uid)
				if(response.code.indexOf("A02")!=-1){
					fileList.splice(idx, 1)
				}
				if(response.code=="A0200"||response.code=="A0201"){// 登录过期，重新登录
					this.$store.commit("updateUserInfo", '');
					this.$router.push("/")
					return
				}
				if(response.code=="A0202"){
					this.userLogin()
					return
				}
				this.fileListData.push(response.data[0])
				this.imgUrl.push(response.data[0])
			},
			userLogin(){
				let userInfo = this.userInfo
				let postData = {
					"grantType": "PLATFORM_REFRESH_TOKEN",
					refreshTokenTokenRequest:{
						refreshToken: this.userInfo.refreshToken
					}
				}
				userLogin(postData).then(res1=>{
					userInfo.token = res1.data.accessToken;
					userInfo.refreshToken = res1.data.refreshToken;
					this.$store.commit("updateUserInfo", userInfo);
					this.importHeaders.Authorization = userInfo.token?('Bearer'+' '+ userInfo.token):''
				})
			},
			handleExceed(files, fileList) {
				this.$message.warning("当前限制选择 6 个文件");
			},
			beforeAvatarUpload(file) {
				const extension = file.name.endsWith(".jpg")||file.name.endsWith(".JPG");
				const extension1 = file.name.endsWith(".png")||file.name.endsWith(".PNG");
				const extension2 = file.name.endsWith(".jpeg")||file.name.endsWith(".JPEG");
				const extension3 = file.name.endsWith(".mp4")||file.name.endsWith(".MP4");
				const extension4 = file.name.endsWith(".avi")||file.name.endsWith(".AVI");
				const extension5 = file.name.endsWith(".rmvb")
				const extension6 = file.name.endsWith(".3gp")||file.name.endsWith(".3GP");
				const extension7 = file.name.endsWith(".mov")||file.name.endsWith(".MOV");
				// const isLt2M = file.size / 1024 / 1024 < 2;
				if (!extension && !extension1 && !extension2 && !extension3 && !extension4 && !extension5 && !extension6 && !extension7) {
					this.$message({
						message: "上传模板只能是 .jpg,.png,.jpeg,.mp4,.avi,.rmvb,.3gp,.mov 格式!",
						type: "error"
					});
				}
				// if (!isLt2M) {
				// 	this.$message.error("上传图片大小不能超过 2M!");
				// }
				// return (extension || extension1 || extension2 || extension3) && isLt2M;
				return extension || extension1 || extension2 || extension3 || extension4 || extension5 || extension6 || extension7;
			}
		}
	}
</script>

<style rel="stylesheet/scss" lang='scss' scoped>
	
</style>