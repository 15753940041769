<template>
	<div class="mainWrap font15">
		<div class="flexB marginB24">
			<h3>{{pushData.typeStr}}</h3>
			<h3>{{pushData.stateStr}}</h3>
		</div>
		<div class="flexB marginB24">
			<h4>订单编号：{{pushData.id}}</h4>
			<span><span class="fontB">下单时间：</span>{{pushData.createdTime}}</span>
		</div>
		<div class="marginB12"><span class="fontB">会员信息：</span>{{pushData.customerNickName}}（{{pushData.customerPhoneNumber}}）</div>
		<div class="marginB12"><span class="fontB">所属门店：</span>{{pushData.shopName?pushData.shopName:'--'}}</div>
		<div class="marginB12" v-if="(pushData.state != 'UN_PAY') && (pushData.state != 'GROUPING_FAILURE') && (pushData.state != 'CANCELED')"><span class="fontB">支付时间：</span>{{pushData.paymentTime?pushData.paymentTime:'--'}}</div>
		<div class="marginB12" v-if="pushData.state == 'COMPLETION'"><span class="fontB">分配时间：</span>{{pushData.distributionTime?pushData.distributionTime:'--'}}</div>
		<div class="marginB12" v-if="pushData.state == 'COMPLETION'"><span class="fontB">订单完成时间：</span>{{pushData.completionTime?pushData.completionTime:'--'}}</div>
		<div class="marginB12" v-if="pushData.state == 'CANCELED'"><span class="fontB">订单取消时间：</span>{{pushData.cancellationTime?pushData.cancellationTime:'--'}}</div>
		<div class="marginB12"><span class="fontB">备注：</span>{{pushData.remark?pushData.remark:'--'}}</div>
		<div class="marginB24">
			<div class="marginB12"><span class="fontB">商品信息：</span></div>
			<el-table :data="tableData" :empty-text="emptyText">
				<el-table-column v-if="!isCard" prop="goodsId" label="商品ID" />
				<el-table-column v-if="isCard" prop="goodsId" label="卡ID" />
				<el-table-column v-if="isCard" prop="goodsName" label="卡名称" />
				<el-table-column v-if="!isCard" prop="goodsName" label="商品名称" />
				<el-table-column v-if="isCard" prop="goodsOriginalPrice" label="卡原价" />
				<el-table-column v-if="!isCard" prop="goodsOriginalPrice" label="商品原价" />
				<el-table-column prop="purchaseQuantity" label="购买数量" />
				<el-table-column prop="purchasePrice" label="购买价" />
				<el-table-column prop="orderItemsGoods.serviceableTimes" v-if="!isCard" label="可服务次数" />
				<el-table-column prop="subtotal" label="小计" />
				<el-table-column label="操作" v-if="isCard">
					<template #default="scope">
						<el-button link type="primary" plain color="#112DF2" @click="dialogLayer(scope.row,scope.$index)">查看卡详情</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="flexB">
			<h3>合计</h3>
			<div>
				<div class="marginB6">订单总金额：<span style="color: red;">￥{{pushData.orderAmount}}</span></div>
				<div class="marginB6">优惠抵扣总金额：<span style="color: red;">￥{{pushData.discountAmount}}</span></div>
				<div>实付总金额：<span style="color: red;">{{pushData.state != 'CANCELED'?('￥'+pushData.actualPaymentAmount):'--'}}</span></div>
			</div>
		</div>
		<el-dialog title="卡详情" :model-value="dialogVisible" width="70vw" :before-close="hideDialog"
			:close-on-click-modal='false' append-to-body>
			<div style="position: relative">
				<el-table :data="cardDate" style="width: 100%;max-height: 60vh;height:auto;overflow-y: scroll;">
					<el-table-column prop="goodsId" label="商品ID"></el-table-column>
					<el-table-column prop="goodsName" label="商品名称"></el-table-column>
					<el-table-column prop="goodsType" label="商品类型">
						<template #default="{row}">
							<div>{{row.goodsType==1?'零售产品':row.goodsType==2?'产品服务':row.goodsType==3?'手法服务':''}}</div>
						</template>
					</el-table-column>
					<el-table-column prop="serviceableTimes" label="可服务次数">
						<template #default="{row}">
							{{row.serviceableTimes?row.serviceableTimes:1}}
						</template>
					</el-table-column>
					<el-table-column prop="gift" label="是否赠品">
						<template #default="{row}">
							{{row.gift?'是':'否'}}
						</template>
					</el-table-column>	
				</el-table>	
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {ordersInfo} from "@/api"
	import {formatDateOrder,orderStateStr,orderTypeStr} from '@/utils/data'
	export default{
		data (){
			return{
				pushData:{},
				emptyText:'暂无数据',
				tableData:[],
				cardDate:[],
				isCard:false,
				dialogVisible:false
			}
		},
		created() {
			this.viewPro()
		},	
		methods:{
			editItem(id){},
			hideDialog(){
				this.dialogVisible = false
			},
			dialogLayer(item,index){
				this.cardDate = JSON.parse(JSON.stringify(item.orderItemsCard.cardIncludeGoods))
				this.$nextTick(() => {
					this.dialogVisible = true
				})
			},
			viewPro(id){
				let postData = {id:this.$route.params.id}
				ordersInfo({data:postData}).then(res => {
					console.log(res.data)
					if(res.success){
						let data = res.data
						data.createdTime = formatDateOrder(data.createdTime)
						if(data.state != 'UN_PAY' && data.state != 'GROUPING_FAILURE' && data.state != 'CANCELED'){
							data.paymentTime = formatDateOrder(data.paymentTime)
						}
						if(data.state == 'CANCELED'){
							data.cancellationTime = formatDateOrder(data.cancellationTime)
						}
						if(data.state == 'COMPLETION'){
							data.distributionTime = data.distributionTime?formatDateOrder(data.distributionTime):''
							data.completionTime = formatDateOrder(data.completionTime)
						}
						data.stateStr = orderStateStr(data.state)
						data.typeStr = orderTypeStr(data.type)
						this.tableData = data.items
						this.isCard = data.items.length>0?data.items[0].isCard:false
						this.pushData = data
					}
				})
			}
		}
	}
</script>

<style rel="stylesheet/scss" lang='scss' scoped>
	.font13{font-size: 13px;}
	.flexS{
		display: flex;
		align-items: flex-start;
	}
	.lineation{
		text-decoration: line-through;
		color: #999;
		display: inline-block;
		margin-left: 6px;
	}
	.marginB6{
		margin-bottom: 6px;
	}
</style>