<template>
	<div>
		<h3 class="marginB24">推广结算</h3>
		<div class="flexB">
			<div class="flexA marginR10">
				<el-date-picker class="marginR10" v-model="timeInterval" @change="search()" type="daterange" value-format="YYYY-MM-DD" start-placeholder="开始日期"
					end-placeholder="结束日期" />
				<el-input placeholder="搜索单据名称" v-model="pushData.fuzzySearch" @keyup.enter="search()" style="width: 200px;">
					<template #suffix>
						<el-icon class="el-input__icon">
							<component :is="Search" class="enter-sj" @click="search()"></component>
						</el-icon>
					</template>
				</el-input>
			</div>
		</div>
		<el-table :data="tableData" :empty-text="emptyText" style="width: 100%;margin-top: 30px;">
			<el-table-column prop="name" label="单据名称" />
			<el-table-column prop="settledAmount" label="结算金额" />
			<el-table-column prop="createdTime" label="结算时间" />
			<el-table-column prop="id" label="操作">
				<template #default="{row}">
					<el-button link type="primary" plain color="#112DF2" @click="viewPro(row.id)">详情</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="relativeB">
			<div class='absoluteB'>
			  <el-dropdown placement='top'>
			    <el-button type="warning" size='mini'>
			      统计数据<el-icon class="el-icon--right"><arrow-down /></el-icon>
			    </el-button>
				<template #dropdown>
				  <el-dropdown-menu>
				    <el-dropdown-item v-for="(item, index) in countData" :key="index">{{item.label}}: {{item.value || 0}}</el-dropdown-item>
				  </el-dropdown-menu>
				</template>
			  </el-dropdown>
			</div>
			<div class="paginations">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next" :total="total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {merchantSpreadSettlementPageList} from '@/api'
	import {formatDateOrder,dateFormat} from '@/utils/data'
	import { Search } from "@element-plus/icons-vue";
	export default {
		data () {
			return {
				Search,
				pushData:{
					fuzzySearch:'',
					beginTime:null,
					endTime:null
				},
				countData:[{
					label:'结算金额',
					value:0
				}],
				timeInterval:[],
				tableData:[],
				pageSize: 10,	
				pageNum: 1,
				total:0,
				emptyText:'暂无数据'
			}
		},
		computed:{
			// 计算出本月第一天和最后一天
			countDate () {
			  let end = new Date()
			  let start = new Date()
			  start = new Date(start.getFullYear(), start.getMonth(), 1)
			  return [
			    dateFormat(start, 'YYYY-MM-DD'),
			    dateFormat(end, 'YYYY-MM-DD')
			  ]
			}
		},
		created() {
			this.timeInterval = this.countDate
			this.search()
		},	
		methods:{
			search(){
				if(this.timeInterval&&this.timeInterval.length>0){
					this.pushData.beginTime = new Date(this.timeInterval[0]+' '+'00:00:00').getTime()/1000
					this.pushData.endTime = new Date(this.timeInterval[1]+' '+'23:59:59').getTime()/1000
				}else{
					this.pushData.beginTime = null
					this.pushData.endTime = null
				}
				let postData = {
					 "paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					}
				}
				postData = Object.assign(postData, this.pushData)
				merchantSpreadSettlementPageList(postData).then(res => {
					if(res&&res.success){
						let list = res.data.pagination.list
						list.forEach(item=>{
							item.createdTime = formatDateOrder(item.createdTime)
						})
						this.tableData = list
						this.total = Number(res.data.pagination.total)
						this.countData[0].value = res.data.settledAmount
					}
				})
			},
			viewPro(id){
				this.$router.push('/distributionManagement/settlementDetails/'+id)
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.search()
			},
			handleCurrentChange(val) {
				this.pageNum = val;
				this.search()
			},
		}
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.cardCon{
		max-width: 90%;
		margin-bottom: 10px;
	}
	// :deep(.el-select .el-input__wrapper){
	// 	width: 200px;
	// }
	// :deep(.el-icon svg){
	// 	color: #112DF2;
	// 	width: 12px;
	// 	height: 12px;
	// }
	// .el-select-dropdown__item{
	//   padding: 0 20px;
	//   box-sizing: border-box;
	// }
</style>