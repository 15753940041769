<template>
	<div>
		<el-button class="marginB24" type="primary" color='#112DF2' @click="add">+ 新增商户推广码</el-button>
		<div class="flexB">
			<div class="flexA marginR10">
				<el-select v-model="pushData.merchantId" style="width: 260px;" @change="getMerchantId" placeholder="商户" clearable class="marginR10">
					<el-option style="max-width: 260px;" v-for="item in merchantList" :key="item.id"
						:label='item.name' :value="item.id"></el-option>
				</el-select>
				<el-select v-model="pushData.shopId" style="width: 260px;" @change="search()" placeholder="全部门店" clearable class="marginR10">
					<el-option style="max-width: 260px;" v-for="item in shopList" :key="item.id"
						:label='item.name' :value="item.id"></el-option>
				</el-select>
			</div>
		</div>
		<el-table :data="tableData" :empty-text="emptyText" style="width: 100%;margin-top: 30px;">
			<el-table-column prop="merchantName" label="商户名称" />
			<el-table-column prop="shopName" label="门店名称" />
		    <el-table-column prop="enabled" label="商家状态">
				<template #default="{row}">
					<div>{{row.enabled?'正常':'关闭'}}</div>
				</template>
			</el-table-column>
			<el-table-column prop="spreadUnitPrice" label="推广单价">
				<template #default="scope">
					<div v-if="!scope.row.isEdit" class="flexAR">{{scope.row.spreadUnitPrice?scope.row.spreadUnitPrice:0}}
						<el-icon @click="editItem(scope.row.id,scope.row.spreadUnitPrice,1,scope.$index)" :size="18" style="margin-left: 6px;"><Edit /></el-icon>
					</div>
					<div v-if="scope.row.isEdit">
						<el-input style="width: 60%;" v-model="scope.row.spreadUnitPriceUp" @keyup="scope.row.spreadUnitPriceUp=scope.row.spreadUnitPriceUp.replace(/[^\d\.]/g,'')"
						 @input="groupPrice(scope.$index,scope.row.spreadUnitPriceUp)" @blur="groupPriceBlur(scope.$index,scope.row.spreadUnitPriceUp)"></el-input>
						<el-button link type="primary" plain color="#112DF2" @click="editItem(scope.row.id,scope.row.spreadUnitPrice,3,scope.$index)">取消</el-button>
						<el-button link type="primary" plain color="#112DF2" @click="editItem(scope.row.id,scope.row.spreadUnitPriceUp,2,scope.$index)">保存</el-button>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="url" label="推广码">
				<template #default="{row}">
					<img class="qrcode" style="width: 60px;margin: 0 auto;" :src="row.url" alt="">
					<!-- <vue-qrcode class="qrcode" :value="row.url" style="width: 60px;margin: 0 auto;" fg-color="#000" bg-color="#fff"></vue-qrcode> -->
				</template>
			</el-table-column>
			<el-table-column prop="createdTime" label="操作">
				<template #default="scope">
					<el-button link type="primary" plain color="#112DF2" @click="downQrCode(scope.row.id,scope.row.url,scope.$index,scope.row.shopName)">下载</el-button>
					<el-button v-if="scope.row.enabled" link type="primary" plain color="#112DF2" @click="inOrOff(scope.row.id,0)">关闭</el-button>
					<el-button v-if="!scope.row.enabled" link type="primary" plain color="#112DF2" @click="inOrOff(scope.row.id,1)">正常</el-button>
					<el-button link type="primary" plain color="#112DF2" @click="delPro(scope.row.id)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="paginations">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {wechatMaQrcodePageList,wechatMaQrcodeEnabledById,wechatMaQrcodeDeleteById,wechatMaQrcodeUpdateSpreadUnitPriceById,shopList,merchantList} from '@/api'
	import {formatDateOrder,dateFormat} from '@/utils/data'
	import { Search } from "@element-plus/icons-vue";
	export default {
		data () {
			return {
				Search,
				pushData:{
					shopId:'',
					merchantId:''
				},
				merchantId:'',
				tableData:[],
				merchantList:[],
				shopList:[],
				pageSize: 10,	
				pageNum: 1,
				total:0,
				emptyText:'暂无数据'
			}
		},
		created() {
			this.getMerchantList()
			this.search()
		},	
		methods:{
			search(){
				let postData = {
					 "paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					}
				}
				postData = Object.assign(postData, this.pushData)
				wechatMaQrcodePageList(postData).then(res => {
					if(res&&res.success){
						let list = res.data.list
						list.forEach(item=>{
							item.createdTime = formatDateOrder(item.createdTime)
							item.isEdit = false
							item.spreadUnitPriceUp = ''
						})
						this.tableData = list
						this.total = Number(res.data.total)
					}
				})
			},
			getMerchantId(){
				let data = this.pushData
				if(data.merchantId == this.merchantId){
					return
				}
				this.pushData.shopId = ''
				this.merchantId = data.merchantId
				this.search()
				if(data.merchantId){
					this.getShopList()
				}else{
					this.shopList = []
				}
			},
			getMerchantList(){
				let postData = {enabled:true}
				merchantList(postData).then(res => {
					if(res&&res.success){
						this.merchantList = res.data
					}
				})
			},
			getShopList(){
				let postData = {
					enabled:true,
					merchantId:this.merchantId
				}
				shopList(postData).then(res => {
					if(res&&res.success){
						this.shopList = res.data
					}
				})
			},
			inOrOff(id,enabled){
				wechatMaQrcodeEnabledById({data:{id,enabled}}).then(res=>{
					if(res.success){
						this.search()
					}else{
						this.$message.error(res.msg)
					}
				})
			},
			editItem(id,spreadUnitPrice,type,index){
				if(type == 3){
					this.search()
					return
				}
				this.tableData.forEach(item=>{
					if(item.id == id){
						item.isEdit = !item.isEdit
						if(type == 1){
							item.spreadUnitPriceUp = spreadUnitPrice
						}
					}
				})
				if(type==2){
					wechatMaQrcodeUpdateSpreadUnitPriceById({data:{id,spreadUnitPrice}}).then(res=>{
						if(res&&res.success){
							this.tableData.forEach(item=>{
								if(item.id == id){
									item.spreadUnitPrice = spreadUnitPrice
								}
							})
						}else{
							this.$message.error(res.msg)
						}
					})
				}
			},
			add(){
				this.$router.push('/distributionManagement/creatCode/')
			},
			delPro(id){
				this.$messageBox.confirm('是否确定删除此条数据？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				}).then(()=>{
					wechatMaQrcodeDeleteById({data:{id}}).then(res=>{
						if(res.success){
							this.$message.success('删除成功！')
							this.search()
						}else{
							this.$message.error(res.msg)
						}
					})
				}).catch(()=>{})
			},
			downQrCode(id,href,index,name){
				// let canvasData = document.getElementsByClassName('qrcode')
				// const imgFile = this.base64ToFile(canvasData[index].src,name);
				let imgDown= document.createElement('a');
				imgDown.download = name;
				// imgDown.href = URL.createObjectURL(imgFile);
				imgDown.href = href
				imgDown.click();
			},
			base64ToFile(base64, fileName) {
				let arr = base64.split(",");
				let mime = arr[0].match(/:(.*?);/)[1];
				let bytes = atob(arr[1]);
				let n = bytes.length;
				let ia = new Uint8Array(n);
				while (n--) {
					ia[n] = bytes.charCodeAt(n);
				}
				return new File([ia], fileName, { type: mime });  // 将值抛出去
			},
			groupPrice(index,val){
				if(val>99999.99)val=99999.99;
				if(val<0)val=0;
				this.tableData[index].spreadUnitPrice = val
			},
			groupPriceBlur(index,val){
				val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')
				this.tableData[index].spreadUnitPrice = val
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.search()
			},
			handleCurrentChange(val) {
				this.pageNum = val;
				this.search()
			},
		}
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.cardCon{
		max-width: 90%;
		margin-bottom: 10px;
	}
	// :deep(.el-select .el-input__wrapper){
	// 	width: 200px;
	// }
	// :deep(.el-icon svg){
	// 	color: #112DF2;
	// 	width: 12px;
	// 	height: 12px;
	// }
	// .el-select-dropdown__item{
	//   padding: 0 20px;
	//   box-sizing: border-box;
	// }
</style>