<template>
	<div class="mainWrap">
		<div class="flexB">
			<h3>商家列表</h3>
			<div class="flexA">
				<div class='marginR10 flexA'>
					<el-select v-model="pushData.enabled" placeholder="全部状态" class="marginR10">
						<el-option v-for="item in statusList" :key="item.status"
							:label='item.name' :value="item.status"></el-option>
					</el-select>
					<el-select v-model="pushData.goodsCategoryId" placeholder="全部类目" clearable class="marginR10">
						<el-option v-for="item in list" :key="item.id"
							:label='item.name' :value="item.id"></el-option>
					</el-select>
					<el-input placeholder="搜索" v-model="pushData.fuzzySearch" @keyup.enter="search" class="w200">
						<template #suffix>
							<!--   vue3图标使用方式  -->
							<el-icon class="el-input__icon">
								<component :is="Search" class="enter-sj" @click="search"></component>
							</el-icon>
						</template>
					</el-input>
				</div>
				<el-button type="primary" color='#112DF2' @click="add">新增商家</el-button>
			</div>
		</div>
		<el-table :data="tableData" :empty-text="emptyText" style="width: 100%;margin-top: 30px;">
			<el-table-column prop="id" label="商编"></el-table-column>
			<el-table-column prop="name" label="商家名称"></el-table-column>
			<el-table-column prop="enabled" label="商家状态">
				<template #default="{row}">
					<div :class="[!row.enabled?'colorff0':'color112']">{{row.enabled?'正常':'关闭'}}</div>
				</template>
			</el-table-column>
			<el-table-column prop="goodsCategoryNames" label="商家类目">
				<template #default="scope">
					<div :class="[index%2 === 0?'bge5e color112':'bge4f color00b']" style="margin: 0 2px;" v-for="(item,index) in scope.row.goodsCategoryNames" :key="index">{{item}}</div>
				</template>
			</el-table-column>
			<el-table-column prop="head" label="负责人"></el-table-column>
			<el-table-column prop="headConcat" label="联系方式"></el-table-column>
			<!-- <el-table-column prop="role" label="商家角色">
				<template #default="{row}">
					<div class="color00b">角色</div>
				</template>
			</el-table-column> -->
			<el-table-column label="商家操作">
				<template #default="{row}">
					<el-button link type="primary" plain color="#112DF2" @click="editItem(row.id)">编辑</el-button>	
					<el-button v-if="row.enabled" link type="primary" plain color="#112DF2" @click="inOrOff(row.id,0)">关闭</el-button>
					<el-button v-if="!row.enabled" link type="primary" plain color="#112DF2" @click="inOrOff(row.id,1)">开启</el-button>
					<el-button link type="primary" plain color="#112DF2" @click="viewPro(row.id)">查看</el-button>	
				</template>
			</el-table-column>
		</el-table>
		<div class="paginations">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next" :total="total"></el-pagination>
		</div>
	</div>
</template>

<script>
	import { Search } from "@element-plus/icons-vue";
	import {merchantPageList,goodsCategoryList,merchantUpdateEnabledById} from "@/api"
	export default{
		data (){
			return{
				pushData:{
					enabled:'all',
					goodsCategoryId:'',
					fuzzySearch:''
				},
				statusList:[
					{name:'全部',status:'all'},
					{name:'正常',status:true},
					{name:'关闭',status:false},
				],
				list:[],
				Search,
				pageSize: 10,
				pageNum: 1,
				total:0,
				tableData:[],
				emptyText:'暂无数据'
			}
		},
		created() {
			this.getDate()
			this.search()
		},	
		methods:{
			search(){
				let postData = {
					 "paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					},
				}
				postData = Object.assign(postData, this.pushData)
				if(postData.enabled == 'all'){
					delete postData.enabled
				}	
				merchantPageList(postData).then(res => {
					if(res&&res.success){
						this.tableData = res.data.list
						this.total = Number(res.data.total)
					}
				})
			},
			getDate(){
				let postData = {parentId:0,level:1}
				goodsCategoryList({data:postData}).then(res => {
					if(res.success){	
						this.list = res.data
						this.search()
					}
				})
			},
			inOrOff(id,enabled){
				let postData = {id,enabled}
				merchantUpdateEnabledById(postData).then(res=>{
					console.log(res)
					if(res.success){
						this.search()
					}
				})
			},
			add(){
				this.$router.push('/merchantManagement/merchantAdd')
			},
			editItem(id){
				this.$router.push('/merchantManagement/merchantAdd/'+id)
			},
			viewPro(id){
				this.$router.push('/merchantManagement/merchantView/'+id)
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.search()
			},
			handleCurrentChange(val) {
				this.pageNum = val;
				this.search()
			}
		}
	}
</script>

<style rel="stylesheet/scss" lang='scss' scoped>
	
</style>