<template>
	<div class="el-dialog-body-spacing">
		<el-dialog title="参与商品列表" :model-value="dialogVisible" width="1200px" :before-close="hideDialog"
			:close-on-click-modal='false' append-to-body>
			<div style="position: relative">
				<div class="flexB">
					<span></span>
					<el-input placeholder="搜索" v-model="pushData.fuzzySearch" @keyup.enter="search(this.activityId)" style="width: 300px;">
						<template #suffix>
							<el-icon class="el-input__icon">
								<component :is="Search" class="enter-sj" @click="search(this.activityId)"></component>
							</el-icon>
						</template>
					</el-input>
				</div>
				<el-table :data="tableData" :empty-text="emptyText" style="width: 100%;margin-top: 15px;">
				    <el-table-column prop="goodsName" label="商品信息" min-width="180">
						<template #default="{row}">
							<div class="flexA">
								<el-image class="img80 bgColor marginR10" :src="row.imgsUrl">
									<template #error>
									  <div class="image-slot ">
										<el-icon style='height:80px;'><PictureFilled /></el-icon>
									  </div>
									</template>
								</el-image>
								<div class="textL" style="width: calc(100% - 85px)">
									<p>{{row.goodsName}}</p>
									<p>{{row.goodsId}}</p>
									<p class="colorff0">{{row.goodsSalePrice?('￥'+row.goodsSalePrice):''}}</p>	
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column v-if="activityType==1" prop="activePrice" label="秒杀价" min-width="90"></el-table-column>
					<!-- <el-table-column v-if="activityType==3" prop="activePrice" label="拼团价"></el-table-column> -->
					<el-table-column prop="statistics.pageView" label="浏览量" min-width="90" />
					<el-table-column prop="statistics.visitorNumber" label="浏览人数" min-width="100" />
					<el-table-column prop="statistics.salesVolume" label="实际下单量" min-width="110" />
					<el-table-column prop="virtualSalesVolume" label="虚拟销量" min-width="150">
						<template #default="{row}">
							<div v-if="!row.isEdit">{{row.virtualSalesVolume}}
								<el-icon @click="editItem(row.id,row.virtualSalesVolume,1,row.virtualSalesVolumeOld)" style='height:10px;margin-left: 6px;'><EditPen /></el-icon>
							</div>
							<div v-if="row.isEdit">
								<el-input style="width: 60%;" @keyup="row.virtualSalesVolumeUp=row.virtualSalesVolumeUp.replace(/\D/g,'')" v-model="row.virtualSalesVolumeUp"></el-input>
								<el-button link type="primary" plain color="#112DF2" @click="editItem(row.id,row.virtualSalesVolume,3,row.virtualSalesVolumeOld)">取消</el-button>
								<el-button link type="primary" plain color="#112DF2" @click="editItem(row.id,row.virtualSalesVolumeUp,2,row.virtualSalesVolumeOld)">保存</el-button>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<div class="paginations">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next" :total="total"></el-pagination>
				</div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import { Search } from "@element-plus/icons-vue";
	import {pageListByActivityId,activityGoodsUpdateVirtualSalesVolumeById} from '@/api'
	export default {
		props: {
			visiFlag: {
				type: Function,
				default: function() {}
			},
			activityType:{
				type:Number,
				default: 1
			},
			activityId:{
				type:String,
				default: ''
			},
			dialogVisible: {
				type: Boolean,
				default: false
			},
		},
		data() {	
			return {
				Search,
				pushData:{
					fuzzySearch:''
				},
				activityId:'',
				emptyText:'暂无数据',
				tableData:[],
				pageSize: 5,	
				pageNum: 1,
				total:0
			}
		},
		methods: {
			editItem(id,virtualSalesVolume,type,virtualSalesVolumeOld){
				this.tableData.forEach(item=>{
					if(item.id == id){
						item.isEdit = !item.isEdit
						if(type==1){
							item.virtualSalesVolumeUp = virtualSalesVolume
						}
					}
				})
				if(type==2){
					if(Number(virtualSalesVolume)<=Number(virtualSalesVolumeOld)){
						this.$message({
							showClose: true,
							message: "新设置的活动商品虚拟销量不能小于上次设置的值",
							type: "warning",
						});
						return
					}
					activityGoodsUpdateVirtualSalesVolumeById({data:{id,virtualSalesVolume}}).then(res=>{
						if(res&&res.success){
							this.search(this.activityId)
						}
					})
				}
			},
			search(id){
				this.activityId = id
				let postData = {
					 "paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					},
					activityId:id
				}
				postData = Object.assign(postData, this.pushData)
				pageListByActivityId(postData).then(res => {
					if(res&&res.success){
						let list = res.data.list
						list.forEach(item=>{
							item.imgsUrl = ''
							let reg = /\w.(png|jpg|jpeg)$/i
							if(reg.test(item.goodsAvatarUrl)){
								item.imgsUrl = item.goodsAvatarUrl
							}
							item.virtualSalesVolumeOld = item.virtualSalesVolume
						})
						this.tableData = list
						this.total = Number(res.data.total)
					}
				})
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.search(this.activityId)
			},
			handleCurrentChange(val) {
				this.pageNum = val;
				this.search(this.activityId)
			},
			hideDialog() {
				this.visiFlag(false,2)
			}
		}
	}
</script>
<style rel="stylesheet/scss" lang='scss' scoped>
	.el-dialog__body{
		overflow: hidden;
		overflow-y:auto;
	}	
</style>
