<template>
	<div>
		<div class="mainWrap flexB marginB80">
			<h3>推广设置</h3>
			<el-button type="primary" style="margin-right: 300px;" color='#112DF2' @click="save(1)" :loading="loading">保存设置</el-button>
		</div>
		<div style="margin-left: 100px;">
			<el-form :model="pushData" ref="formBox" :rules="rules" label-width="180px" class="formBox">
				<!-- <el-form-item style="margin-bottom: 40px;" label="实际金额比例设置&nbsp;&nbsp;&nbsp;&nbsp;" prop="amountRate">
					<el-input
					    v-model="pushData.amountRate"
					    style="max-width: 400px"
					    placeholder="请输入实际金额比例"
					    class="input-with-select"
					>
					    <template #prepend>实际金额</template>
					    <template #append>元=1元</template>
					</el-input>
				</el-form-item> -->
				<el-form-item style="margin-bottom: 40px;" class="formItems" label="推广比例参数设置&nbsp;&nbsp;&nbsp;&nbsp;" prop="undertakingMerchantServiceRatio">
					<el-row>
						<el-col :span="3">承接费比例设置</el-col>
						<el-col :span="6">
							<el-input
							    v-model="pushData.undertakingMerchantServiceRatio"
							    style="max-width: 220px"
							    placeholder="请输入承接费比例"
							    class="input-with-select"
							>
							    <template #append>%</template>
							</el-input>
						</el-col>
					</el-row>
					
				</el-form-item>
				<el-form-item style="margin-bottom: 40px;" class="formItems" prop="belongMerchantGuestChargeRatio">
					<el-row>
						<el-col :span="3">客资费比例设置</el-col>
						<el-col :span="6">
							<el-input
							    v-model="pushData.belongMerchantGuestChargeRatio"
							    style="max-width: 220px"
							    placeholder="请输入客资费比例"
							    class="input-with-select"
							>
							    <template #append>%</template>
							</el-input>
						</el-col>
					</el-row>
				</el-form-item>
				<el-form-item>
					<el-button link type="primary" plain color="#112DF2" @click="settingShop">客资费比例设置</el-button>
				</el-form-item>
			</el-form>
		</div>
		<el-drawer v-model="centerDialogVisible" :direction="direction">
		    <template #header>
		      <h4>客资费比例设置</h4>
		    </template>
		    <template #default>
				<div class="flexB marginB24">
					<span></span>
					<el-button type="warning" @click="add">+ 新增商户</el-button>
				</div>
				<div>
					<el-row :gutter='10' v-for="(item,index) in settingList" class="marginB12">
						<el-col :span="2">商户</el-col>
						<el-col :span="8">
							<el-select v-model="item.merchantId" placeholder="商户" :disabled='!item.isEditM' clearable class="marginR10">
								<el-option v-for="item in merchantList" :key="item.id" :disabled="item.disabled"
									:label='item.name' :value="item.id"></el-option>
							</el-select>
						</el-col>
						<el-col :span="8">
							<el-input :disabled='!item.isEdit' v-model="item.belongMerchantGuestChargeRatio" @input="belongMerchantGuestChargeRatio(index,item.belongMerchantGuestChargeRatio)" @keyup="item.belongMerchantGuestChargeRatio=item.belongMerchantGuestChargeRatio.replace(/\D/g,'')">
								<template #append>%</template>
							</el-input>
						</el-col>
						<el-col :span="3">
							<el-button type="warning" @click="delPro(index)">删除</el-button>
						</el-col>
						<el-col :span="3" v-if="!item.isEdit">
							<el-button type="primary" color="#112DF2" @click="editPro(index)">编辑</el-button>
						</el-col>
						<el-col :span="3" v-if="item.isEdit">
							<el-button type="primary" color="#112DF2" @click="savePro(index)">保存</el-button>
						</el-col>
					</el-row>
				</div>
		    </template>
		</el-drawer>
	</div>
</template>

<script>
	import Validate from '@/utils/rules'
	import {merchantList,merchantSpreadConfigList,merchantSpreadConfigShopIdList,merchantSpreadConfigCreate,merchantSpreadConfigUpdate,merchantSpreadConfigDel} from '@/api'
	import {formatDateOrder,dateFormat} from '@/utils/data'
	import { Search } from "@element-plus/icons-vue";
	export default {
		data () {
			return {
				merchantList:[],
				centerDialogVisible:false,
				settingList:[],
				pushData:{},
				rules: {
					// amountRate: [{required: true,message: '请输入实际金额比例',validator: Validate.posIntRange, trigger: 'blur'}],
					undertakingMerchantServiceRatio: [{required: true,message: '请输入承接费比例(整数1-100)',validator: Validate.posIntRange, trigger: 'blur'}],
					belongMerchantGuestChargeRatio: [{required: true,message: '请输入客资费比例(整数1-100)',validator: Validate.posIntRange, trigger: 'blur'}]
				},
			}
		},
		created() {
			this.getShopList()
			this.getList()
		},	
		methods:{
			getList(){
				merchantSpreadConfigList().then(res => {
					if(res&&res.success){
						let list = res.data
						let arr = []
						if(list.length>0){
							list.forEach(item=>{
								item.undertakingMerchantServiceRatio = item.undertakingMerchantServiceRatio*100
								item.belongMerchantGuestChargeRatio = item.belongMerchantGuestChargeRatio*100
								if(!item.isDefault){
									item.isEdit = false
									item.isEditM = false
									arr.push(item)
								}
							})
							if(list[0].isDefault){
								this.pushData = list[0]
							}
							this.settingList = arr
							this.merchantSpreadConfigShopIdList()
						}
					}
				})
			},
			merchantSpreadConfigShopIdList(){
				merchantSpreadConfigShopIdList().then(res=>{
					if(res&&res.success){
						let data = res.data
						let list = JSON.parse(JSON.stringify(this.merchantList));
						list.forEach(item=>{
							let arr = res.data.filter(v=>v==item.id)
							if(arr.length>0){
								item.disabled = true
							}else{
								item.disabled = false
							}
						})
						this.merchantList = list
					}
				})
			},
			belongMerchantGuestChargeRatio(index,val){
				if(val == "" || val == null){
					this.settingList[index].belongMerchantGuestChargeRatio = 1
					return
				}
				if(val>100){
					this.settingList[index].belongMerchantGuestChargeRatio = 100
					return
				}
				this.settingList[index].belongMerchantGuestChargeRatio = val
			},
			delPro(index){
				let data = this.settingList[index]
				this.$messageBox.confirm('是否确定删除此条设置？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				}).then(()=>{
					let id = data.id
					if(!id){
						this.settingList.splice(index, 1);
						return
					}
					merchantSpreadConfigDel({data:{id}}).then(res=>{
						if(res.success){
							this.$message.success('删除成功！')
							this.getList()
							return
						}
					})
				}).catch(()=>{})
			},
			editPro(index){
				this.settingList[index].isEdit = true
			},
			savePro(index){
				let data = this.settingList[index]
				if(!data.merchantId){
					this.$message({
						showClose: true,
						message: "请选择商户",
						type: "error",
					});
					return
				}
				if(!data.belongMerchantGuestChargeRatio){
					this.$message({
						showClose: true,
						message: "请输入客资费比例",
						type: "error",
					});
					return
				}
				if(data.id){
					this.update(data)
					return
				}
				this.save(2,data.merchantId,data.belongMerchantGuestChargeRatio)
			},
			update(data){
				let body = {
					id:data.id,
					belongMerchantGuestChargeRatio:data.belongMerchantGuestChargeRatio/100
				}
				if(data.merchantId){
					body.isDefault = false
				}else{
					body.isDefault = true
					// body.amountRate = data.amountRate
					body.undertakingMerchantServiceRatio = data.undertakingMerchantServiceRatio/100
				}
				merchantSpreadConfigUpdate(body).then(res=>{
					if(res&&res.success){
						this.getList()
						this.$message({
							showClose: true,
							message: "更新推广设置成功",
							type: "success",
						});
					}else{
						this.$message.error(res.msg)
					}
				})
			},
			add(){
				this.settingList.push({
					belongMerchantGuestChargeRatio:'',
					merchantId:'',
					isEdit:true,
					isEditM:true
				})
			},
			settingShop(){
				this.centerDialogVisible = true
			},
			save(type,merchantId,belongMerchantGuestChargeRatio){
				let pushData = this.pushData
				if(pushData.id&&(type==1)){
					this.update(pushData)
					return
				}
				this.$refs.formBox.validate((valid) => {
					if (valid) {
						let body = {}
						if(merchantId){
							body.merchantId = merchantId	
							body.belongMerchantGuestChargeRatio = belongMerchantGuestChargeRatio/100
							body.isDefault = false
						}else{
							body.isDefault = true
							// body.amountRate = pushData.amountRate
							body.undertakingMerchantServiceRatio = pushData.undertakingMerchantServiceRatio/100
							body.belongMerchantGuestChargeRatio = pushData.belongMerchantGuestChargeRatio/100
						}
						merchantSpreadConfigCreate(body).then(res => {
							if(res&&res.success){
								this.getList()
								this.$message({
									showClose: true,
									message: "保存推广设置成功",
									type: "success",
								});
							}else{
								this.$message.error(res.msg)
							}
						})
					}
				})
			},
			getShopList(){
				let postData = {enabled:true}
				merchantList(postData).then(res => {
					if(res&&res.success){
						res.data.forEach(item=>{
							item.disabled = false
						})
						this.merchantList = res.data
					}
				})
			}
		}
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.cardCon{
		max-width: 90%;
		margin-bottom: 10px;
	}
	:deep(.el-drawer__header){
		margin-bottom: 0;
	}
	:deep(.formItems .el-form-item__content){
		display: initial !important;
		color: #666;
	}
	// :deep(.el-select .el-input__wrapper){
	// 	width: 200px;
	// }
	// :deep(.el-icon svg){
	// 	color: #112DF2;
	// 	width: 12px;
	// 	height: 12px;
	// }
	// .el-select-dropdown__item{
	//   padding: 0 20px;
	//   box-sizing: border-box;
	// }
</style>