<template>
	<div class="mainWrap font15">
		<h3 class='marginB24'>门店详情</h3>
		<h4 class="marginB12 font16">门店名称：{{pushData.name}}</h4>
		<div class="marginB12 flexA">
			<div class="w240">负责人：{{pushData.head}}</div>
			<div class="w240">负责联系方式：{{pushData.headConcat}}</div>
		</div>
		<div class="marginB12 flexA">
			<div class="w240">门店类目：<span v-for="(item,index) in pushData.goodsCategoryNames" :key="index">{{item}}</span></div>
			<div class="w240">门店入驻时间：{{pushData.createdTime}}</div>
		</div>
		<div class="marginB12">门店地址：{{pushData.address}}</div>
		<div class="marginB24">
			<h4 class="marginB12 font16">所属商家</h4>
			<div>商家名称：{{pushData.merchantName}}</div>
			<!-- <el-table :data="tableData" :empty-text="emptyText" stripe style="width: 100%;margin-top: 30px;">
				<el-table-column prop="merchantId" label="商家编号">
					<template #default="{row}">{{pushData.merchantId}}</template>
				</el-table-column>
				<el-table-column prop="name" label="商家信息">
					<template #default="{row}">
						<div class="flexA">
							<div class="textL">
								<p class="fontB">{{pushData.name}}</p>
								<p>{{pushData.head}}</p>
								<p>{{pushData.headConcat}}</p>	
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="category" label="商家类目">
					<template #default="{row}">
						类目一个
					</template>
				</el-table-column>
				<el-table-column prop="status" label="商家状态">
					<template #default="{row}">
						<div>{{row.status==1?'正常状态':''}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="master" label="入驻时间">
					<template #default="{row}">{{pushData.createdTime}}</template>
				</el-table-column>
			</el-table> -->
		</div>
		<div class="marginB24">
			<h4 class="marginB9 font16">结算账户</h4>
			<el-row :gutter="16">
				<el-col :span="12">
					<h4 class="marginB9 font14"> 账户名称：{{pushData.settlementAccount.name}}</h4>
					<h4 class="font14"> 所属银行：{{pushData.settlementAccount.bank}}</h4>
				</el-col>
				<el-col :span="12">
					<h4 class="marginB9 font14"> 账户号码 ：{{pushData.settlementAccount.number}}</h4>
					<h4 class="font14"> 开户银行 ：{{pushData.settlementAccount.accountBank}}</h4>
				</el-col>
			</el-row>
		</div>
		<div class="marginB24">
			<h4 class="marginB9 font16">门店头图</h4>
			<div class="flexA">
				<img v-for="item in pushData.resources" :key="item" class="img marginR20" :src="item">
			</div>
		</div>
		<div>	
			<h4 class="marginB9 font16">门店简介</h4>
			<div v-html="pushData.introduction"></div>
			<!-- <div class="phoneBg">
				<div class="previewWrap">
					<img :src="pushData.resources[0]" style="width: 100%;margin-bottom: 10px;" alt="">
					<div class="previewCon" v-if="pushData.name||pushData.forPeople||pushData.effectivePeriod">
						<div style="font-size: 16px;font-weight: bold;margin-bottom: 9px;">{{pushData.name}}</div>
						<div class="flexA">
							<div>
								<span v-if="pushData.forPeople">适用人群：{{pushData.forPeople}}</span>
							</div>
							<span v-if="pushData.effectivePeriod">见效周期：{{pushData.effectivePeriod}}</span>
						</div>
					</div>
					<div class="introduction" v-if="pushData.introduction">
						<div style="font-size: 14px;font-weight: bold;margin-left: 10px;">暖心科普</div>
						<div v-html="pushData.introduction"></div>
					</div>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
	import {shopInfo} from "@/api"
	import {formatDate} from '@/utils/data'
	export default{
		data (){
			return{
				pushData:{},
				imgUrl:[],
				tableData:[
					{code:'95280013236',name:'商家名称展示在这边哦',status:1,master:'王麻子',tel:'13776124124',role:'角色一预留',category:[
						'类目一','类目一','类目一'
					],id:'22'},
					// {code:'95280013236',name:'商家名称展示在这边哦',status:1,master:'王麻子',tel:'13776124124',role:'角色一预留',category:[
					// 	'类目一','类目一','类目一'
					// ],id:'41'},
					// {code:'95280013236',name:'商家名称展示在这边哦',status:1,master:'王麻子',tel:'13776124124',role:'角色一预留',category:[
					// 	'类目一','类目一','类目一'
					// ],id:'22'},
					// {code:'95280013236',name:'商家名称展示在这边哦',status:1,master:'王麻子',tel:'13776124124',role:'角色一预留',category:[
					// 	'类目一','类目一','类目一'
					// ],id:'41'}
				],
				emptyText:'暂无数据'
			}
		},
		created() {
			this.viewPro()
		},	
		methods:{
			editItem(id){
				
			},
			viewPro(id){
				let postData = {id:this.$route.params.id}
				shopInfo({data:postData}).then(res => {
					console.log(res.data)
					if(res.success){
						res.data.createdTime = formatDate(res.data.createdTime)
						// if(res.data.shopList.length>0){
						// 	res.data.shopList.forEach(item=>{
						// 		item.createdTime = formatDate(item.createdTime)
						// 	})
						// }
						this.pushData = res.data
					}
				})
			}
		}
	}
</script>

<style rel="stylesheet/scss" lang='scss' scoped>
	span{
		display: inline-block;
		margin-right:20px;
	}
	::v-deep .el-table th.el-table__cell {
		overflow: hidden;
		-webkit-user-select: none;
		-moz-user-select: none;
		user-select: none;
		background-color: #FFF;
		background: #f5f5f5;
	}
	.img{
		width: 100px;
		height: 100px;
		border-radius: 8px;
		background-color: #f2f2f2;
	}
	.phoneBg{
		width:350px;
		height: 700px;
		background: #f2f2f2;
		border-radius: 20px;
		padding:60px 10px;
		box-sizing: border-box;
		margin-top: 15px;
		.previewWrap{
			width: 100%;	
			height: 100%;
			background:#F5F5F8;
			border-radius: 20px;
			/* overflow-y: scroll;
			overflow-x: hidden; */
		}
		.previewCon{
			padding: 10px;
			box-sizing: border-box;
			font-size: 12px;
			background: #fff;
			border-radius: 5px;
			margin-bottom: 10px;
		}
		.introduction{
			font-size: 12px;
			background: #fff;
			border-radius: 5px;
			padding-top: 10px;
		}
		/* background: url('../../assets/images/phoneBg.png') no-repeat;
		background-size: 100% 100%; */
	}
</style>