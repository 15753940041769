<template>
	<div id="app"><router-view/></div>
</template>

<style lang="scss">
	@import url("./assets/css/base.css");
	#app{
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		margin: 0;
		padding: 0;
	}
</style>
