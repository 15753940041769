<template>
	<div>
		<h3 class="marginB12">平台明细</h3>
		<div class="flexB">
			<div class="flexA marginR10">
				<el-date-picker class="marginR10" v-model="timeInterval" @change="search()" type="daterange" value-format="YYYY-MM-DD" start-placeholder="开始日期"
					end-placeholder="结束日期" />
				<el-input placeholder="搜索会员名称手机号" v-model="pushData.fuzzySearch" @keyup.enter="search()" style="width: 200px;">
					<template #suffix>
						<el-icon class="el-input__icon">
							<component :is="Search" class="enter-sj" @click="search()"></component>
						</el-icon>
					</template>
				</el-input>
			</div>
			<div>
			<!-- <div style="padding: 6px 10px;border: 1px solid gainsboro;border-radius: 6px;"> -->
				<el-dropdown>
					<el-button type="primary" plain color="#112DF2">
					    更多操作<el-icon class="el-icon--right"><arrow-down /></el-icon>
					</el-button>
				    <!-- <span class="el-dropdown-link">
				      更多操作<el-icon class="el-icon--right"><arrow-down /></el-icon>
				    </span> -->
				    <template #dropdown>
				      <el-dropdown-menu>
				        <el-dropdown-item @click.native="importData">批量导出</el-dropdown-item>
				      </el-dropdown-menu>
				    </template>
				  </el-dropdown>
			</div>
		</div>
		<el-table :data="tableData" :empty-text="emptyText" style="width: 100%;margin-top: 30px;">
		    <el-table-column prop="customerNickName" label="会员名称" />
			<el-table-column prop="shopName" label="联系方式" />
			<el-table-column prop="customerPhoneNumber" label="订单时间" />
			<el-table-column prop="customerPhoneNumber" label="商品名称" />
			<el-table-column prop="createdTime" label="订单金额" />
			<el-table-column prop="shopName" label="推广比例" />
			<el-table-column prop="customerPhoneNumber" label="订单收入" />
			<el-table-column prop="createdTime" label="客资费" />
			<el-table-column prop="shopName" label="推广比例" />
			<el-table-column prop="customerPhoneNumber" label="客资收入" />
			<el-table-column prop="customerPhoneNumber" label="总收入" />
		</el-table>
		<div class="relativeB">
			<div class='absoluteB'>
			  <el-dropdown placement='top'>
			    <el-button type="warning" size='mini'>
			      统计数据<el-icon class="el-icon--right"><arrow-down /></el-icon>
			    </el-button>
				<template #dropdown>
				  <el-dropdown-menu>
				    <el-dropdown-item v-for="(item, index) in tCountData" :key="index">{{item.label}}: {{item.value || 0}}</el-dropdown-item>
				  </el-dropdown-menu>
				</template>
			  </el-dropdown>
			</div>
			<div class="paginations">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next" :total="total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {appointGiftActivity,shopList,} from '@/api'
	import {formatDateOrder,dateFormat} from '@/utils/data'
	import { Search } from "@element-plus/icons-vue";
	export default {
		data () {
			return {
				Search,
				pushData:{
					fuzzySearch:'',
					beginTime:null,
					endTime:null,
					shopId:''
				},
				timeInterval:[],
				tableData:[],
				shopList:[],
				pageSize: 10,	
				pageNum: 1,
				total:0,
				emptyText:'暂无数据'
			}
		},
		computed:{
			tCountData () {
			  // const data = this.countData
			  return [
			    {label: '总收入', value: '100300'},
			    {label: '订单金额', value: '100'},
			    {label: '客资费用', value: '200'}
			  ]
			},
			// 计算出本月第一天和最后一天
			countDate () {
			  let end = new Date()
			  let start = new Date()
			  start = new Date(start.getFullYear(), start.getMonth(), 1)
			  return [
			    dateFormat(start, 'YYYY-MM-DD'),
			    dateFormat(end, 'YYYY-MM-DD')
			  ]
			}
		},
		created() {
			this.timeInterval = this.countDate
			this.search()
			this.getShopList()
		},	
		methods:{
			search(){
				return
				if(this.timeInterval&&this.timeInterval.length>0){
					this.pushData.beginTime = new Date(this.timeInterval[0]+' '+'00:00:00').getTime()/1000
					this.pushData.endTime = new Date(this.timeInterval[1]+' '+'23:59:59').getTime()/1000
				}else{
					this.pushData.beginTime = null
					this.pushData.endTime = null
				}
				let postData = {
					 "paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					}
				}
				postData = Object.assign(postData, this.pushData)
				appointGiftActivity(postData).then(res => {
					if(res&&res.success){
						let list = res.data.list
						list.forEach(item=>{
							item.createdTime = formatDateOrder(item.createdTime)
							item.isEdit = false
							item.customerNickNameUp = ''
						})
						this.tableData = list
						this.total = Number(res.data.total)
					}
				})
			},
			getShopList(){
				let postData = {
					// "paging": {
					// 	"pageNum": 1,
					// 	"pageSize": 100
					// },
					enabled:true
				}
				shopList(postData).then(res => {
					if(res&&res.success){
						this.shopList = res.data
					}
				})
			},
			editItem(id,customerNickName,type){
				this.tableData.forEach(item=>{
					if(item.id == id){
						item.isEdit = !item.isEdit
						if(type==1){
							item.customerNickNameUp = customerNickName
						}
					}
				})
				if(type==2){
					updateCustomerNickNameById({data:{id,customerNickName}}).then(res=>{
						if(res&&res.success){
							this.tableData.forEach(item=>{
								if(item.id == id){
									item.customerNickName = customerNickName
								}
							})
						}
					})
				}
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.search()
			},
			handleCurrentChange(val) {
				this.pageNum = val;
				this.search()
			},
		}
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.cardCon{
		max-width: 90%;
		margin-bottom: 10px;
	}
	// :deep(.el-select .el-input__wrapper){
	// 	width: 200px;
	// }
	// :deep(.el-icon svg){
	// 	color: #112DF2;
	// 	width: 12px;
	// 	height: 12px;
	// }
	// .el-select-dropdown__item{
	//   padding: 0 20px;
	//   box-sizing: border-box;
	// }
</style>