<template>
	<div>
		<div class="mainWrap flexB">
			<h3>产后关爱</h3>
			<div>
				<el-input placeholder="搜索" v-model="pushData.fuzzySearch" @keyup.enter="search()" class="w200">
					<template #suffix>
						<el-icon class="el-input__icon">
							<component :is="Search" class="enter-sj" @click="search()"></component>
						</el-icon>
					</template>
				</el-input>
			</div>
		</div>
		<el-table :data="tableData" :empty-text="emptyText" style="width: 100%;margin-top: 30px;">
		    <el-table-column prop="customerNickName" label="会员名称" />
			<el-table-column prop="customerPhoneNumber" label="会员手机" />
			<el-table-column prop="stage" label="阶段">
				<template #default="{row}">
					<div>{{row.stage==1?'生产后':''}}</div>
				</template>
			</el-table-column>
			<el-table-column prop="dueDate" label="日期">
				<template #default="{row}">
					<div>{{row.stage==1?'生产后:'+row.childbirthDate:''}}</div>
				</template>
			</el-table-column>
			<el-table-column prop="createdTime" label="提交时间" />
		</el-table>
		<div class="paginations">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next" :total="total"></el-pagination>
		</div>
	</div>
</template>

<script>
	import {postpartumCareActivity} from '@/api'
	import {formatDateOrder} from '@/utils/data'
	import { Search } from "@element-plus/icons-vue";
	export default {
		data () {
			return {
				Search,
				pushData:{
					fuzzySearch:''
				},
				tableData:[],
				pageSize: 10,	
				pageNum: 1,
				total:0,
				emptyText:'暂无数据'
			}
		},
		created() {
			this.search()
		},	
		methods:{
			search(){
				let postData = {
					 "paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					}
				}
				postData = Object.assign(postData, this.pushData)
				postpartumCareActivity(postData).then(res => {
					if(res&&res.success){
						let list = res.data.list
						list.forEach(item=>{
							item.createdTime = formatDateOrder(item.createdTime)
						})
						this.tableData = list
						this.total = Number(res.data.total)
					}
				})
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.search()
			},
			handleCurrentChange(val) {
				this.pageNum = val;
				this.search()
			},
		}
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	
</style>