<template>
	<div class="wrap">
		<div class="card-header">
			<el-card class="box-card">
			   <!-- <template #header>
			      <div class="card-header">
			        <h3>一级项目类目</h3>
			        <el-button class="button" text>Operation button</el-button>
			      </div>
			    </template> -->
				<!-- <div :class="[current=='all' ? 'current' : '','card-header item']" @click="getCategory('all')">
					<div>全部类目</div>
					<span>{{totalNum}}</span>
				</div> -->
				<el-tree style="font-size: 15px;" :data="options" :props="defaultProps" ref="cascaderAddr" @node-click="handleNodeClick" />
				<!-- <div v-for="item in list" :key="item.id" @click="getCategory(item.id)" :class="[current==item.id ? 'current' : '','card-header item']">
					<div>{{ item.name }}</div>
					<span>{{item.subCategoryCount}}</span>
				</div> -->
			</el-card>
			<el-card class="mainCon">
				<div class="card-header">
					<!-- <h3>二级项目类目</h3> -->
					<!-- <el-button type="primary" plain color='#112DF2' @click="loginOut">退出</el-button> -->
					<el-button type="primary" color='#112DF2' @click="add">新增分类</el-button>
				</div>
				<el-table :data="tableData" :empty-text="emptyText" style="width: 100%;margin-top: 30px;">
				    <el-table-column prop="name" label="分类名称" min-width="100" />
				    <el-table-column prop="parentName" label="上级分类" min-width="100" />
				    <el-table-column prop="forPeople" label="适用人群" min-width="100" />
					<el-table-column prop="effectivePeriod" label="见效周期" min-width="100" />
					<el-table-column prop="enabled" label="商家状态" min-width="100">
						<template #default="{row}">
							<div :class="[!row.enabled?'colorff0':'color112']">{{row.enabled?'启用':'禁用'}}</div>
						</template>
					</el-table-column>
					<el-table-column prop="goodsNum" label="商品量" min-width="70">
						<template #default="{row}">
							0
						    <!-- <el-button link type="primary" @click="dialogCategory(row.id)">{{row.goodsNum}}</el-button> -->
						</template>
					</el-table-column>
					<el-table-column prop="expNum" label="体验量" min-width="70">
						<template #default="{row}">
							0
						    <!-- <el-button link type="primary" @click="dialogCategory(row.id)">{{row.expNum}}</el-button> -->
						</template>
					</el-table-column>
					<el-table-column prop="hits" label="点击量" min-width="70">
						<template #default="{row}">
							0
						    <!-- <el-button link type="primary" @click="dialogHits(row.id)">{{row.expNum}}</el-button> -->
						</template>
					</el-table-column>
					<el-table-column prop="discuss" label="讨论量" min-width="70">
						<template #default="{row}">
							0
						    <!-- <el-button link type="primary" >{{row.discuss}}</el-button> -->
						</template>
					</el-table-column>
					<el-table-column label="操作" min-width="160">
						<template #default="{ row }">
							<!-- <el-button @click="editItem(row.id)">编辑</el-button> -->
							<el-button link type="primary" plain color="#112DF2" @click="editItem(row.id)">编辑</el-button>
							<el-button v-if="row.enabled" link type="primary" plain color="#112DF2" @click="inOrOff(row.id,false)">禁用</el-button>
							<el-button v-if="!row.enabled" link type="primary" plain color="#112DF2" @click="inOrOff(row.id,true)">启用</el-button>
							<el-button link type="primary" plain color="#112DF2" @click="delItem(row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="paginations">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next" :total="total"></el-pagination>
				</div>
			</el-card>
		</div>
		<el-dialog v-model="dialogVisible" v-if="dialogVisible">
			<div style="display: flex;justify-content:space-between;align-items: center;margin-bottom: 20px;">
				<div style="display: flex;align-items: center;">
					<el-button type="primary" @click="getProductType(1)" :plain='productType!=1' color="#112DF2">零售产品</el-button>
					<el-button type="primary" @click="getProductType(2)" :plain='productType!=2' color="#112DF2">服务项目</el-button>
					<el-button type="primary" @click="getProductType(3)" :plain='productType!=3' color="#112DF2">零售产品</el-button>
				</div>
				<div>
					<el-input placeholder="搜索" v-model="searchCon" @keyup.enter="search()">
						<template #suffix>
							<!--   vue3图标使用方式  -->
							<el-icon class="el-input__icon">
								<component :is="Search" class="enter-sj" @click="search()"></component>
							</el-icon>
						</template>
					</el-input>
				</div>
			</div>
		    <el-table :data="goodsData" :empty-text="emptyText" style="width: 100%;font-size: 12px;">
				<el-table-column prop="info" label="产品信息">
					<template #default="{row}">
						<div style='display: flex;justify-content:center;align-items: center;'>
							<img style="width: 80px;height: 80px;margin-right: 10px;" src="" alt="">
							<div style="display: flex;align-items:flex-start;flex-direction: column;">
								<div>{{row.name}}</div>
								<div>{{row.price}}</div>
								<div style='color: #FF0000;'>￥128.00</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="categoryName" label="产品类目" width="150px">
					<template #default="{row}">
						<div class="waysStyle">{{row.categoryName}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="creatDate" label="创建时间" width="180px">
					<template #default="{row}">
						
					</template>
				</el-table-column>
				<el-table-column prop="isActive" label="启用" width="120px">
					<template #default="{row}">
						<el-switch
						  v-model="row.isActive"
						  style="--el-switch-on-color:#A2A5B8; --el-switch-off-color:#112DF2"
						  :active-value="1"
						  :inactive-value="2"
						/>
					</template>
				</el-table-column>
				<el-table-column prop="isSale" label="销售" width="120px">
					<template #default="{row}">
						<el-switch
						  v-model="row.isSale"
						  style="--el-switch-on-color:#A2A5B8; --el-switch-off-color:#112DF2"
						  :active-value="1"
						  :inactive-value="2"
						/>
					</template>
				</el-table-column>
			</el-table>
			<div class="paginations">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next" :total="total"></el-pagination>
			</div>
		</el-dialog>
		<el-dialog v-model="dialogHitsVisible" v-if="dialogHitsVisible">
		    <el-table :data="goodsData" :empty-text="emptyText" style="width: 100%;font-size: 12px;">
				<el-table-column prop="info" label="用户信息">
					<template #default="{row}">
						<div>
							{{row.name}} {{row.creatDate}}
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="categoryName" label="产品类目">
					<template #default="{row}">
						<div style="display: inline-block;color: #112DF2;padding: 2px 20px;box-sizing: border-box;border-radius: 32px;background-color: #E5E8FD;">{{row.categoryName}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="creatDate" label="点击时间">
					<template #default="{row}">
						
					</template>
				</el-table-column>
				<el-table-column prop="timeSpent" label="浏览时长" />
			</el-table>
			<div class="paginations">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next" :total="total"></el-pagination>
			</div>
		</el-dialog>
		<!-- <categoryIndex :dialog-visible.sync="dialogVisible" /> -->
	</div>
</template>

<script>
	import categoryIndex from "@/components/popUp/categoryIndex";
	import { Search } from "@element-plus/icons-vue";
	import {goodsCategoryList,goodsCategoryTreeList,goodsCategoryPageList,goodsCategoryDeletedById,goodsCategoryUpdateEnabledById,logout} from '@/api'
	
	export default {
		name: 'categoryList',
		components: {categoryIndex},
		data () {
			return {
				defaultProps:{
				  children: 'children',
				  label: 'label',
				},
				options:[],
				goodsCategoryId:'',
				Search,
				searchCon:'',
				current:'all',
				originalArray:[],
				list:[],
				tableData:[],
				pageSize: 10,
				pageNum: 1,
				total:0,
				totalNum:0,
				emptyText:'暂无数据',
				dialogVisible:false,
				dialogHitsVisible:false,
				categoryId:'',
				productType:1,
				goodsData:[
					// {creatDate:'2021-12-23 12:25:35',isActive:1,isSale:2,categoryName:'气血茶',name:'后i恶化的佛请问',price:'20',timeSpent:'120秒'},
					// {creatDate:'2021-12-23 12:25:35',isActive:1,isSale:2,categoryName:'气血茶',name:'后i恶化的佛请问',price:'20',timeSpent:'120秒'},
					// {creatDate:'2021-12-23 12:25:35',isActive:2,isSale:1,categoryName:'气血茶',name:'后i恶化的佛请问',price:'20',timeSpent:'120秒'}
				],
				parentId:0
			}
		},
		created() {
			// this.getList()
			this.getCategoryTreeList()
		},	
		methods:{
			handleNodeClick(e){
				// var  thsAreaCode = this.$refs['cascaderAddr'].getCheckedNodes()
				if(e.value){
					this.getCategory(e.value)
					this.originalArray = [e]
					// for (let i = 0; i < this.tableData.length; i++) {
					// 	this.tableData[0].parentName = '--'
					// 	if (this.tableData[i].children) {
					// 		for (let o = 0; o < this.tableData[i].children.length; o++) {
					// 			this.tableData[i].children[o].parentName = this.tableData[0].name
					// 			this.tableData.push(this.tableData[i].children[o])
					// 		}
					// 	}
					// }
					// console.log(this.tableData)
				}
			},
			loginOut(){
				logout({}).then(res=>{
					console.log()
					this.$router.replace('/')
				})
			},
			getCategoryTreeList(){
				goodsCategoryTreeList({}).then(res=>{
					if(res.success){
						let data = res.data
						let arr = []
						if(data.length>0){
							data.forEach(item=>{
								item.label = item.name
								item.value = item.id
								if(item.children&&item.children.length>0){
									for (let i of item.children) {
										i.label = i.name
										i.value = i.id
									}
								}
								arr.push(item)
							})
						}
						this.options = arr
						this.$nextTick(() => {
							this.getCategory(this.current)
						})
					}
				})
			},
			getList(){
				goodsCategoryList({
					data:{
						parentId:0,level:1,
						statisticsSubCategoryCount:true,
						// statisticsBindingShopCount:true,
						// statisticsBindingGoodsCount:true
					}
				}).then(res => {
					if(res.success){
						this.list = res.data
						this.$nextTick(() => {
							this.getCategory('all')
						})
					}
				})
			},
			getCategory(id){
				this.current = id
				let postData = {
					 "paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					},
					// level:2
				}
				if(id != 'all'){
					postData.parentId = id
					this.parentId = id
				}
				goodsCategoryPageList(postData).then(res => {
					if(res&&res.success){
						let list = res.data.list
						list.forEach(item=>{
							if(item.level==1){
								item.parentName = '--'
							}
						})
						let originalArray = JSON.parse(JSON.stringify(this.originalArray))
						if(id != 'all'&&(this.pageNum = 1)){
							originalArray[0].parentName = '--'
							delete originalArray[0].children
						}else{
							originalArray = []
						}
						this.total = Number(res.data.total)
						if(id == 'all'){
							this.totalNum = Number(res.data.total)
						}
						this.tableData = originalArray.concat(list)
					}
				})
			},
			dialogHits(id){
				this.dialogHitsVisible = true
			},
			search(){
				console.log(this.searchCon)
			},
			getProductType(num){
				this.productType = num
			},
			dialogCategory(id){
				this.dialogVisible = true
				this.categoryId = id
			},
			inOrOff(id,enabled){
				let postData = {id,enabled}
				goodsCategoryUpdateEnabledById({data:postData}).then(res=>{
					if(res.success){
						this.getCategory(this.current)
						return
					}
					this.$message.error(res.msg)
				})
			},
			delItem(item){
				this.$messageBox.confirm('此操作将永久删除该类目，是否确定删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				}).then(()=>{
					goodsCategoryDeletedById({data:{id:item.id}}).then(res=>{
						if(res.success){
							this.$message.success('删除成功！')
							if(item.level == 1){
								this.current = 'all'
							}
							this.getCategoryTreeList()
							return
						}
						this.$message.error(res.msg)
					})
				}).catch(()=>{})	
			},
			editItem(id){
				this.$router.push('/categoryList/categoryAdd/'+id)
			},
			add(){
				this.$router.push('/categoryList/categoryAdd/')
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.getCategory(this.current)
			},
			handleCurrentChange(val) {
				this.pageNum = val;
				this.getCategory(this.current)
			},
		}
	}
</script>

<style lang="scss" scoped>
	.wrap{
		
	}
	.box-card{
		width: 276px;
		height: calc(100vh - 60px);
	}
	.card-header {
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
	}
	.item {
	  margin-bottom: 8px;
	  padding: 12px;
	  box-sizing: border-box;
	  font-size: 16px;
	  span{
		  color: #00B56B;
	  }
	}
	.current{
		color: #fff;
		background: #112DF2;
		border-radius: 10px;
		span{
			color: #fff;
		}
	}
	.mainCon{
		width: calc(100% - 316px);
		height: calc(100vh - 60px);
	}
	.paginations{
		margin-top: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.waysStyle{
		display: inline-block;
		color: #112DF2;
		padding: 2px 20px;
		box-sizing: border-box;
		border-radius: 32px;
		background-color: #E5E8FD;
	}
	:deep(.el-tree-node){
		margin:3px 0;
	}
	:deep(.el-tree-node.is-expanded>.el-tree-node__children){
		margin:3px 0;
	}
</style>