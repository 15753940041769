<template>
	<div style="width: 70%;">
		<div class="flexB marginB80">
			<h3>新增推广码</h3>
			<div style="margin-right: 200px;">
				<el-button class="marginR10" type="primary" color="#112DF2" @click="save">保存新增</el-button>
				<el-button type="primary" color="#787878" @click="() => this.$router.go(-1)">暂不保存</el-button>
			</div>
		</div>
		<div style="margin-left: 100px;">
			<el-form :model="pushData" ref="formBox" :rules="rules" label-width="180px" class="formBox">
				<el-form-item style="margin-bottom: 40px;" label="商户名称" prop="merchantId">
					<el-select v-model="pushData.merchantId" placeholder="请选择商户名称" @change="getMerchantId" clearable style="width: 300px;">
						<el-option v-for="item in merchantList" :key="item.id" style="max-width: 300px;"
							:label='item.name' :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item style="margin-bottom: 40px;" label="门店名称" prop="shopId">
					<el-select v-model="pushData.shopId" placeholder="请选择门店名称" clearable style="width: 300px;">
						<el-option v-for="item in shopList" :key="item.id" :disabled="item.disabled" style="max-width: 300px;"
							:label='item.name' :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item style="margin-bottom: 40px;" class="formItems" label="推广单价设置" prop="spreadUnitPrice">
					<el-input
					    v-model="pushData.spreadUnitPrice"
					    style="max-width: 300px"
					    placeholder="请输入推广单价"
					    class="input-with-select"
					>
					    <template #append>元</template>
					</el-input>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import Validate from '@/utils/rules'
	import {wechatMaQrcodeCreat,wechatMaCreatedListShopId,shopList,merchantList} from '@/api'
	import {formatDateOrder,dateFormat} from '@/utils/data'
	export default {
		data () {
			return {
				pushData:{
					shopId:'',
					merchantId:'',
					spreadUnitPrice:''
				},
				merchantId:'',
				merchantList:[],
				shopList:[],
				rules: {
					shopId: [{required: true,message: '请选择门店名称', trigger: 'blur'}],
					merchantId: [{required: true,message: '请选择商户名称',trigger: 'blur'}],
					spreadUnitPrice: [{required: true,message: '请正确输入推广单价',validator: Validate.isNumber, trigger: 'blur'}]
				},
			}
		},
		created() {
			this.getMerchantList()
			this.getList()
		},	
		methods:{
			getList(){
				wechatMaCreatedListShopId().then(res => {
					if(res&&res.success){
						this.createdListShopId = res.data
					}
				})
			},
			getMerchantId(){
				let data = this.pushData
				if(data.merchantId == this.merchantId){
					return
				}
				this.pushData.shopId = ''
				this.merchantId = data.merchantId
				if(data.merchantId){
					this.getShopList()
				}else{
					this.shopList = []
				}
			},
			getMerchantList(){
				let postData = {enabled:true}
				merchantList(postData).then(res => {
					if(res&&res.success){
						this.merchantList = res.data
					}
				})
			},
			getShopList(){
				let postData = {
					enabled:true,
					merchantId:this.merchantId
				}
				shopList(postData).then(res => {
					if(res&&res.success){
						let data = res.data
						let list = JSON.parse(JSON.stringify(this.createdListShopId));
						data.forEach(item=>{
							let arr = list.filter(v=>v==item.id)
							if(arr.length>0){
								item.disabled = true
							}else{
								item.disabled = false
							}
						})
						this.shopList = data
					}
				})
			},
			save(){
				this.$refs.formBox.validate((valid) => {
					if (valid) {
						let pushData = this.pushData
						let body = {
							shopId:pushData.shopId,
							spreadUnitPrice:pushData.spreadUnitPrice
						}
						wechatMaQrcodeCreat({data:body}).then(res=>{
							if(res&&res.success){
								this.$message({
									showClose: true,
									message: "新增推广码成功",
									type: "success",
								});
								setTimeout(()=>{
									this.$router.replace('/distributionManagement/extensionCodeList')
								},1000)
							}else{
								this.$message.error(res.msg)
							}
						})
					}
				})
			}
		}
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.cardCon{
		max-width: 90%;
		margin-bottom: 10px;
	}
	// :deep(.el-select .el-input__wrapper){
	// 	width: 200px;
	// }
	// :deep(.el-icon svg){
	// 	color: #112DF2;
	// 	width: 12px;
	// 	height: 12px;
	// }
	// .el-select-dropdown__item{
	//   padding: 0 20px;
	//   box-sizing: border-box;
	// }
</style>