<template>
	<div class="wrap">
		<div class="card-header">
			<el-card class="box-card">
			    <template #header>
			      <div class="card-header">
			        <h3>门店类目</h3>
			      </div>
			    </template> 
				<div :class="[current=='all' ? 'current' : '','card-header item']" @click="search('all')">
					<div>全部类目</div>
					<span><span class="span">{{totalNum}}</span>门店</span>
				</div>
				<div v-for="item in list" :key="item.id" @click="search(item.id)" :class="[current==item.id ? 'current' : '','card-header item']">
					<div>{{ item.name }}</div>
					<span><span class="span">{{item.bindingShopCount}}</span>门店</span>
				</div>
			</el-card>
			<el-card class="mainCon">
				<div class="card-header">
					<h3>门店列表</h3>
					<div class="flexA">
						<div class="marginR10 flexA">
							<el-select v-model="pushData.enabled" placeholder="全部状态" class="marginR10">
								<el-option v-for="item in statusList" :key="item.status"
									:label='item.name' :value="item.status"></el-option>
							</el-select>
							<el-input placeholder="搜索" v-model="pushData.fuzzySearch" @keyup.enter="search(current)" class="w200">
								<template #suffix>
									<el-icon class="el-input__icon">
										<component :is="Search" class="enter-sj" @click="search(current)"></component>
									</el-icon>
								</template>
							</el-input>
						</div>
						<el-button type="primary" color='#112DF2' @click="add">新增门店</el-button>
					</div>
				</div>
				<el-table :data="tableData" :empty-text="emptyText" style="width: 100%;margin-top: 30px;">
				    <el-table-column prop="id" label="门店编号" min-width="150" />
				    <el-table-column prop="name" label="门店名称" min-width="200" />
				    <el-table-column prop="goodsCategoryNames" label="门店类目" min-width="150">
						<template #default="{row}">
							<div class="color112 bge5e" v-for="(item,index) in row.goodsCategoryNames" :key="index">{{item}}</div>
						</template>
					</el-table-column>
					<el-table-column prop="head" label="联系人" min-width="80" />
					<el-table-column prop="headConcat" label="联系方式" min-width="120" />
					<el-table-column prop="enabled" label="门店状态" min-width="90">
						<template #default="{row}">
							<div :class="[!row.enabled?'colorff0':'color112']">{{row.enabled?'正常状态':'关闭状态'}}</div>
						</template>
					</el-table-column>
					<el-table-column prop="display" label="门店展示" min-width="90">
						<template #default="{row}">
							<div :class="[!row.display?'color999':'color112']">{{row.display?'展示':'隐藏'}}</div>
						</template>
					</el-table-column>
					<el-table-column label="操作" min-width="200">
						<template #default="{ row }">
							<el-button link type="primary" plain color="#112DF2" @click="viewPro(row.id)">查看</el-button>
							<el-button link type="primary" plain color="#112DF2" @click="editItem(row.id)">编辑</el-button>
							<el-button v-if="row.enabled" link type="primary" plain color="#112DF2" @click="inOrOff(row.id,false)">关闭</el-button>
							<el-button v-if="!row.enabled" link type="primary" plain color="#112DF2" @click="inOrOff(row.id,true)">开启</el-button>
							<el-button v-if="row.display" link type="primary" plain color="#112DF2" @click="showOrHidden(row.id,false)">隐藏</el-button>
							<el-button v-if="!row.display" link type="primary" plain color="#112DF2" @click="showOrHidden(row.id,true)">展示</el-button>
							<!-- <el-button link type="primary" plain color="#112DF2" @click="viewPro(row.id)">结算账户</el-button> -->
						</template>
					</el-table-column>
				</el-table>
				<div class="paginations">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next" :total="total"></el-pagination>
				</div>
			</el-card>
		</div>
	</div>
</template>

<script>
	import {shopPageList,goodsCategoryList,shopUpdateEnabledById,shopUpdateDisplayById} from "@/api"
	import { Search } from "@element-plus/icons-vue";
	export default {
		data () {
			return {
				Search,
				pushData:{
					enabled:'all',
					fuzzySearch:''
				},
				statusList:[
					{name:'全部',status:'all'},
					{name:'正常',status:true},
					{name:'关闭',status:false},
				],
				current:'all',
				list:[],
				tableData:[
					// {name:'das',code:'20155472254',master:'hakd',tel:'13776124137',status:'1',category:'产后修复'},
					// {name:'das',code:'20155472254',master:'hakd',tel:'13776124137',status:'2',category:'产后修复'},
					// {name:'das',code:'20155472254',master:'hakd',tel:'13776124137',status:'2',category:'产后修复'},
					// {name:'das',code:'20155472254',master:'hakd',tel:'13776124137',status:'1',category:'产后修复'},
					// {name:'das',code:'20155472254',master:'hakd',tel:'13776124137',status:'2',category:'产后修复'},
					// {name:'das',code:'20155472254',master:'hakd',tel:'13776124137',status:'1',category:'产后修复'},
				],
				pageSize: 10,
				pageNum: 1,
				total:0,
				totalNum:0,
				emptyText:'暂无数据',
				parentId:0
			}
		},
		created() {
			this.getList()
		},	
		methods:{
			search(id){
				this.current = id
				let postData = {
					 "paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					},
				}
				postData = Object.assign(postData, this.pushData)
				if(postData.enabled == 'all'){
					delete postData.enabled
				}	
				if(id != 'all'){
					postData.goodsCategoryId = id
					this.parentId = id
				}
				shopPageList(postData).then(res => {
					if(res&&res.success){
						this.tableData = res.data.list
						this.total = Number(res.data.total)
						if(id == 'all'){
							this.totalNum = Number(res.data.total)
						}
					}
				})
			},
			getList(){
				goodsCategoryList({
					data:{
						parentId:0,level:1,
						// statisticsSubCategoryCount:true,
						statisticsBindingShopCount:true,
						// statisticsBindingGoodsCount:true
					}
				}).then(res => {
					if(res.success){
						this.list = res.data
						this.$nextTick(() => {
							this.search('all')
						})
					}
				})
			},
			inOrOff(id,enabled){
				let postData = {id,enabled}
				shopUpdateEnabledById(postData).then(res=>{
					if(res.success){
						this.search(this.current)
					}
				})
			},
			showOrHidden(id,display){
				let postData = {id,display}
				shopUpdateDisplayById(postData).then(res=>{
					if(res.success){
						this.search(this.current)
					}
				})
			},
			editItem(id){
				this.$router.push('/merchantManagement/storeManagement/storeAdd/'+id)
			},
			add(){
				this.$router.push('/merchantManagement/storeManagement/storeAdd/')
			},
			viewPro(id){
				this.$router.push('/merchantManagement/storeManagement/storeView/'+id)
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.search(this.current)
			},
			handleCurrentChange(val) {
				this.pageNum = val;
				this.search(this.current)
			},
		}
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.wrap{
		
	}
	.box-card{
		width: 276px;
		height: calc(100vh - 60px);
	}
	.card-header {
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
	}
	.item {
	  margin-bottom: 8px;
	  padding: 12px;
	  box-sizing: border-box;
	  font-size: 16px;
	  .span{
		  color: #00B56B;
	  }
	}
	.current{
		color: #fff;
		background: #112DF2;
		border-radius: 10px;
		span{
			color: #fff;
		}
	}
	.mainCon{
		width: calc(100% - 316px);
		height: calc(100vh - 60px);
	}
</style>