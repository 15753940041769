import axios from 'axios'
import qs from 'qs'
// import { Message, MessageBox, Loading  } from 'element-ui';
import {ElMessage,ElMessageBox,ElLoading} from 'element-plus'
import store from '@/store'
import Vue from 'vue'
import router from '@/router'
const nodeEnv = process.env.NODE_ENV
import configB from '@/api/config'
import {userLogin} from '@/api';
let msgFlag = true
let loadFLag = true
// axios.defaults.baseURL = "http://m.shiyueji.net/apis";

class Http {
  constructor(baseUrl = null) {
	if(nodeEnv === 'production'){
		baseUrl = configB.baseUrl.pro;//pro:生产环境 dev:开发环境
	}else{
		baseUrl = '/apis';
	}
    let baseURL = baseUrl;
    this._http = axios.create({
      baseURL,
      timeout: 30000
    })

    this.uploadUrl = baseURL
    this.config = {
      // reqtime: +new Date
    }

  }

  upload(){
    return this.uploadUrl
  }

  create() {
    this._init()
    return this._http
  }

  _init() {
    this._http.interceptors.request.use(config => this._requestHandler(config, this.config), this._errorHandler) //发送请求
    this._http.interceptors.response.use(res => this._responseHandler(res), this._errorHandler) //处理请求
  }

  /**
   * [setRequestConfig 请求参数设置]
   * @param {Object} config [description]
   */
  setRequestConfig(config = {}) {
    this.config = Object.assign(this.config, config)
    return this
  }

  _requestHandler (config, _conf ) {
    if(loadFLag){
      loadFLag = false;
      ElLoading.service({ fullscreen: true, background:'rgba(0,0,0,0)'}).close()
    }
    if(config.method === 'post') {
      config.headers = {
        'Content-Type': 'application/json;charse=UTF-8',
        'Authorization':store.state.userInfo.token?('Bearer'+' '+ store.state.userInfo.token):''
      }
      config.transformRequest = [ d => JSON.stringify(d) ]

    }
    if(config.method === 'get') {
      config.headers = {
        'Content-Type': 'application/json;charse=UTF-8',
        'Authorization':store.state.userInfo.token?('Bearer'+' '+ store.state.userInfo.token):''
      }
      config.transformRequest = [ d => JSON.stringify(d) ]

    }
    config.params = Object.assign(config.params || {}, _conf)
    return config
  }

  _responseHandler(res) {
    loadFLag = true;
    if(res.data.success && msgFlag){
      msgFlag = false;
    }else if(res.data.code=="A0200"||res.data.code=="A0201"){// 登录过期，重新登录
		let userInfo = store.state.userInfo
		userInfo.token = ''
		userInfo.refreshToken = ''
		store.commit("updateUserInfo", userInfo);
		ElMessage({
		    message: res.data.msg,
		    type: 'warning',
		})
		router.push("/")
	}else if(res.data.code=="A0202"){
		let userInfo = store.state.userInfo
		userInfo.token = ''
		store.commit("updateUserInfo", userInfo);
		let postData = {
			"grantType": "PLATFORM_REFRESH_TOKEN",
			refreshTokenTokenRequest:{
				refreshToken: userInfo.refreshToken
			}
		}
		userLogin(postData).then(res1=>{
			userInfo.token = res1.data.accessToken;
			userInfo.refreshToken = res1.data.refreshToken;
			store.commit("updateUserInfo", userInfo);
		})
	}
	return res.data	
  }

  _errorHandler(error) {
    ElLoading.service({ fullscreen: true, background:'rgba(0,0,0,0)'}).close()
    ElMessage.error('网络连接失败，请稍后再试')
    // throw new Error(error)
    return Promise.reject(error)
  }
}

export default Http