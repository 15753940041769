<template>
	<div>
		<div class="mainWrap flexB">
			<span></span>
			<div class="flexA">
				<div class="flexA marginR10">
					<el-select v-model="pushData.enabled" placeholder="是否启用" @change="search()" clearable class="marginR10">
						<el-option v-for="item in statusList" :key="item.status"
							:label='item.name' :value="item.status"></el-option>
					</el-select>
					<el-input placeholder="搜索" v-model="pushData.goodsName" @keyup.enter="search()" class="w200">
						<template #suffix>
							<el-icon class="el-input__icon">
								<component :is="Search" class="enter-sj" @click="search()"></component>
							</el-icon>
						</template>
					</el-input>
				</div>
				<el-button type="primary" color='#112DF2' @click="add">新增活动</el-button>
			</div>
		</div>
		<el-table :data="tableData" :empty-text="emptyText" style="width: 100%;margin-top: 30px;">
		    <el-table-column prop="goodsName" label="商品名称" />
			<el-table-column prop="activePrice" label="体验价" />
			<el-table-column prop="goodsSalePrice" label="原价" />
			<!-- <el-table-column prop="statistics.participateShop" label="参与门店">
				<template #default="{row}">
					<el-button link type="primary" plain color="#112DF2" @click="dialogLayer(row.id,1)">{{row.statistics.participateShop}}</el-button>
				</template>
			</el-table-column> -->
			<el-table-column prop="goodsTypeStr" label="类别" />
		    <el-table-column prop="statistics.participateOrder" label="下单量">
				<template #default="{row}">
					<el-button link type="primary" plain color="#112DF2" @click="dialogLayer(row.id,3)">{{row.statistics.participateOrder}}</el-button>
				</template>
			</el-table-column>
			<el-table-column prop="goodsPurchasesLimit" label="可购买次数" />
			<el-table-column prop="createdTime" label="创建时间" />
			<el-table-column prop="enabled" label="启用">
				<template #default="{row}">
					<el-switch
						@change="changeEnabledById(row.enabled,row.id)"
						v-model="row.enabled"
						style="--el-switch-on-color:#A2A5B8; --el-switch-off-color:#112DF2"
						:active-value="0" :inactive-value="1"/>
				</template>
			</el-table-column>
			<el-table-column label="操作">
				<template #default="{row}">
					<el-button link type="primary" plain color="#112DF2" @click="editItem(row.id)">活动管理</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="paginations">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next" :total="total"></el-pagination>
		</div>
		<activity-shops :dialog-visible.sync="participateShopShow" ref="child1" :activityId='activityId' :visiFlag="visiFlag"></activity-shops>
		<activity-order :dialog-visible.sync="orderCountShow" ref="child3" :activityType="2" :activityId='activityId' :visiFlag="visiFlag"></activity-order>
	</div>
</template>

<script>
	import {experiencePricePageList,experienceUpdateEnabledById} from '@/api';
	import {formatDateD,typeStr} from '@/utils/data';
	import { Search } from "@element-plus/icons-vue";
	import activityShops from "@/components/PopTable/activityShops";
	import activityOrder from "@/components/PopTable/activityOrder";
	export default {
		components: {activityShops,activityOrder},
		data () {
			return {
				Search,
				pushData:{
					goodsName:'',
					enabled:null 
				},
				statusList:[
					{name:'启用',status:'true'},{name:'未启用',status:'false'}
				],
				tableData:[],
				pageSize: 10,	
				pageNum: 1,
				total:0,
				emptyText:'暂无数据',
				activityId:'',
				participateShopShow:false,
				orderCountShow:false
			}
		},
		created() {
			this.search()
		},	
		methods:{
			dialogLayer(id,type){
				this.activityId = id
				if(type == 1){
					this.$refs.child1.search()
				}
				if(type == 3){
					this.$refs.child3.search(id)
				}
				this.participateShopShow = type == 1
				this.orderCountShow = type == 3
			},
			changeEnabledById(val,id){
				let data = {
					id,
					enabled:val==1?true:false
				}
				experienceUpdateEnabledById({data}).then(res=>{
					if(res.success){
						this.$message.success(res.msg)
						this.search()
						return
					}
					// this.$message.error(res.msg)
				})
			},
			search(){
				let postData = {
					 "paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					}
				}
				postData = Object.assign(postData, this.pushData)
				postData.enabled = postData.enabled == '' ? null : postData.enabled
				experiencePricePageList(postData).then(res => {
					if(res&&res.success){
						let list = res.data.list
						list.forEach(item=>{
							item.createdTime = formatDateD(item.createdTime)
							item.goodsTypeStr = typeStr(item.goodsType)
							item.enabled = item.enabled ? 1 : 0
						})
						this.tableData = list
						this.total = Number(res.data.total)
					}
				})
			},
			visiFlag(val,type){
				if(type == 1){
					this.participateShopShow = val
					return
				}
				if(type == 3){
					this.orderCountShow = val
					return
				}
			},
			editItem(id){},
			add(){
				this.$router.push('/active/experienceList/experienceAdd/')
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.search()
			},
			handleCurrentChange(val) {
				this.pageNum = val;
				this.search()
			},
		}
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	
</style>