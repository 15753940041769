<template>
	<div class="mainWrap">
		<div class="flexB">
			<div class="flexA">
				<el-select v-model="type" placeholder="全部阶段" @change="typeChange" clearable class="marginR10">
					<el-option label='孕期' value="1"></el-option>
					<el-option label='产后' value="2"></el-option>
				</el-select>
				<el-date-picker class="marginR10" v-model="timeInterval" @change="search()" type="daterange" value-format="YYYY-MM-DD" start-placeholder="开始日期"
					end-placeholder="结束日期" />
				<div style="margin-right: 20px;">
					<el-input placeholder="搜索" v-model="fuzzySearch" @keyup.enter="search">
						<template #suffix>
							<!--   vue3图标使用方式  -->
							<el-icon class="el-input__icon">
								<component :is="Search" class="enter-sj" @click="search"></component>
							</el-icon>
						</template>
					</el-input>
				</div>	
				<el-button type="primary" color='#112DF2' @click="search">搜索会员</el-button>
			</div>
			<el-button type="warning" @click="importData">导出</el-button>
		</div>
		<el-table :data="tableData" :empty-text="emptyText" style="width: 100%;margin-top: 30px;">
			<el-table-column prop="id" label="会员编号" min-width="200"></el-table-column>
			<el-table-column prop="avatarUrl" label="会员信息" min-width="200">
				<template #default="{row}">
					<div class="flexA">
						<el-image class="img50 bgColor marginR10" :src="row.avatarUrl">
							<template #error>
							  <div class="image-slot">
								<el-icon style='height:50px;'><PictureFilled /></el-icon>
							  </div>
							</template>
						</el-image>
						<div class="textL">
							<p>{{row.nickName}}</p>
							<p>{{row.phoneNumber}}</p>
						</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="gender" label="性别" min-width="80">
				<template #default="{row}">
					<div>{{row.gender==2?'女性':row.gender==1?'男性':row.gender==0?'未知':''}}</div>
				</template>
			</el-table-column>
			<el-table-column prop="stage" label="阶段" min-width="100">
				<template #default="{row}">
					<div>{{row.stage=='HY'?'怀孕中':row.stage=='CH'?'生产后':row.stage=='BY'?'备孕':''}}</div>
				</template>
			</el-table-column>
			<el-table-column prop="dueDate" label="日期" min-width="180">
				<template #default="{row}">
					<div>{{row.stage=='HY'?(row.dueDate?('预产期:'+row.dueDate):''):row.stage=='CH'?(row.childbirthDate?('生产后:'+row.childbirthDate):''):''}}</div>
				</template>
			</el-table-column>
			<el-table-column prop="createdTime" label="注册时间" min-width="180"></el-table-column>
			<el-table-column prop="remark" label="备注" min-width="280">
				<template #default="{row}">
					<div v-if="!row.isEdit">
						<span class="remark">{{row.remark}}</span>
						<el-icon @click="editItem(row.id,row.remark,1)" style='height:10px;margin-left: 6px;'><EditPen /></el-icon>
					</div>
					<div v-if="row.isEdit">
						<el-input style="width: 60%;" v-model="row.remarkUp"></el-input>
						<el-button link type="primary" plain color="#112DF2" @click="editItem(row.id,row.remark,3)">取消</el-button>
						<el-button link type="primary" plain color="#112DF2" @click="editItem(row.id,row.remarkUp,2)">保存</el-button>
					</div>
				</template>
			</el-table-column>
			<!-- <el-table-column label="操作" min-width="240">
				<template #default="{row}">
					<el-button link type="primary" plain color="#112DF2">禁言会员</el-button>
					<el-button link type="primary" plain color="#112DF2">编辑会员</el-button>
					<el-button link type="primary" plain color="#112DF2">查看会员</el-button>
				</template>
			</el-table-column> -->
		</el-table>
		<div class="paginations">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next" :total="total"></el-pagination>
		</div>
	</div>
</template>

<script>
	import { Search } from "@element-plus/icons-vue";
	import {formatDateOrder} from '@/utils/data'
	import {userPageList,exportUserList,customerUpdateRemarkById} from '@/api'
	export default {
		data (){
			return{
				Search,
				fuzzySearch:'',
				pageSize: 10,
				pageNum: 1,
				type:'',
				timeInterval:[],
				total:0,
				tableData:[],
				emptyText:'暂无数据'
			}
		},
		created() {
			this.search()
		},	
		methods:{
			typeChange(){
				this.timeInterval = []
			},
			search(){
				let type = this.type
				let timeInterval = this.timeInterval
				let postData = {
					 "paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					},
					fuzzySearch:this.fuzzySearch,
					dueDateTimeInterval:null,
					childbirthDateTimeInterval:null
				}
				if(timeInterval.length>0){
					let arr = [1,1]
					arr[0] = timeInterval[0] +' ' + '00:00:00'
					arr[1] = timeInterval[1] +' ' + '23:59:59'
					if(type==1){
						postData.dueDateTimeInterval = arr
					}
					if(type==2){
						postData.childbirthDateTimeInterval = arr
					}
				}
				userPageList(postData).then(res=>{
					if(res.success){
						let list = res.data.list
						list.forEach(item=>{
							item.createdTime = formatDateOrder(item.createdTime)
						})
						this.tableData = list
						this.total = Number(res.data.total)
					}
				})
			},
			editItem(id,remark,type){
				this.tableData.forEach(item=>{
					if(item.id == id){
						item.isEdit = !item.isEdit
						if(type==1){
							item.remarkUp = remark
						}
					}
				})
				if(type==2){
					customerUpdateRemarkById({data:{id,remark}}).then(res=>{
						if(res&&res.success){
							this.tableData.forEach(item=>{
								if(item.id == id){
									item.remark = remark
								}
							})
						}
					})
				}
			},
			importData(){//导出
				let type = this.type
				let timeInterval = this.timeInterval
				let postData = {
					 "paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					},
					fuzzySearch:this.fuzzySearch,
					dueDateTimeInterval:null,
					childbirthDateTimeInterval:null
				}
				if(timeInterval.length>0){
					let arr = [1,1]
					arr[0] = timeInterval[0] +' ' + '00:00:00'
					arr[1] = timeInterval[1] +' ' + '23:59:59'
					if(type==1){
						postData.dueDateTimeInterval = arr
					}
					if(type==2){
						postData.childbirthDateTimeInterval = arr
					}
				}
				this.$http.post(exportUserList, postData, {
					headers:{
						'Authorization': this.$store.state.userInfo.token?('Bearer'+' '+ this.$store.state.userInfo.token):''
					},
					responseType: "blob"
				}).then(res => {
					const blob = new Blob([res.data], {type: 'application/vnd.ms-excel'});
					const fileName = '会员列表.xlsx';
					if ('download' in document.createElement('a')) {
						const elink = document.createElement('a');
						elink.download = fileName;
						elink.style.display = 'none';
						elink.href = URL.createObjectURL(blob);
						document.body.appendChild(elink);
						elink.click();
						URL.revokeObjectURL(elink.href);
						document.body.removeChild(elink);
					}else{
						navigator.msSaveBlob(blob, fileName);
					}
				});
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.search()
			},
			handleCurrentChange(val) {
				this.pageNum = val;
				this.search()
			}
		}
	}
</script>

<style lang="scss" scoped>
	.flexA{
		display: flex;
		align-items: center;
	}
	.flexB{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.mainWrap{
		width:94%;
		margin:20px auto;
	}
	.paginations{
		margin-top: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>