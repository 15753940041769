<template>
	<div class="el-dialog-body-spacing">
		<el-dialog title="选择手法项目" :model-value="dialogVisible" width="1100px" :before-close="hideDialog"
			:close-on-click-modal='false' append-to-body>
			<div style="height: 500px; position: relative">
				<product-items :itemType='3' :istabShow='"1"' :isShow='true' :selection="seleArr"
					:is-change='isChange' :is-radio='isRadio' :option='option'></product-items>
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="hideDialog">取 消</el-button>	
					<el-button color='#112DF2' type="primary" @click="confirm">确 定</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>
<script>
	import productItems from '@/views/goodsManagement/productItems'
	export default {
		components: {
			productItems
		},
		props: {
			close: {
				type: Function,
				default: function() {}
			},
			visiFlag: {
				type: Function,
				default: function() {}
			},
			type:{
				type: Number
			},
			selList: {
				type: Array
			},
			dialogVisible: {
				type: Boolean,
				default: false
			},
			option: {
				type: Object,
				default: function() {
					return {}
				}
			},
			closebackdata: {},
			isRadio: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				seleArr: [],
				isChange: false
			}
		},
		watch: {
			dialogVisible(v) {
				if (v) {
					this.seleArr = this.selList
					this.$nextTick(() => {
						this.isChange = !this.isChange
					})
				}
			}
		},
		computed: {
			selListLength() {
				return this.selList.length
			}
		},
		methods: {
			confirm() {
				// this.selList = this.seleArr
				this.hideDialog()
				this.close(this.seleArr, this.closebackdata)
			},
			hideDialog() {
				this.visiFlag(false, this.type)
				// this.$emit('update:dialogVisible', false)
			}
		}
	}
</script>
<style>
	.el-dialog__body{
		overflow: hidden;
		overflow-y:auto;
	}
</style>
