<template>
	<div>
		<div class="flexB">
			<h3>秒杀列表</h3>
			<div class="flexA">
				<div class="flexA marginR10">
					<el-select v-model="pushData.state" @change="search()" placeholder="全部状态" clearable class="marginR10">
						<el-option v-for="item in statusList" :key="item.status"
							:label='item.name' :value="item.status"></el-option>
					</el-select>
					<el-input placeholder="搜索" v-model="pushData.fuzzySearch" @keyup.enter="search()" class="w200">
						<template #suffix>
							<el-icon class="el-input__icon">
								<component :is="Search" class="enter-sj" @click="search()"></component>
							</el-icon>
						</template>
					</el-input>
				</div>
				<el-button type="primary" color='#112DF2' @click="add">新增活动</el-button>
			</div>
		</div>
		<el-table :data="tableData" :empty-text="emptyText" style="width: 100%;margin-top: 30px;">
		    <el-table-column prop="actNameStr" label="活动名称" />
			<el-table-column prop="serial" label="楼位" width="60" />
			<!-- <el-table-column prop="statistics.participateShop" label="参与门店" width="90">
				<template #default="{row}">
					<el-button link type="primary" plain color="#112DF2" @click="dialogLayer(row.id,1)">{{row.statistics.participateShop}}</el-button>
				</template>
			</el-table-column> -->
			<el-table-column prop="statistics.participateGoods" label="参与商品" width="90">
				<template #default="{row}">
					<el-button link type="primary" plain color="#112DF2" @click="dialogLayer(row.id,2)">{{row.statistics.participateGoods}}</el-button>
				</template>
			</el-table-column>
		    <el-table-column prop="statistics.participateOrder" label="下单量" width="90">
				<template #default="{row}">
					<el-button link type="primary" plain color="#112DF2" @click="dialogLayer(row.id,3)">{{row.statistics.participateOrder}}</el-button>
				</template>
			</el-table-column>
			<el-table-column prop="count" label="曝光量" width="90">
				<template #default="{row}">
					<div>0</div>
				</template>
			</el-table-column>
			<el-table-column prop="stateStr" label="活动状态" width="90" />
			<el-table-column prop="createdTime" label="创建时间" />
			<!-- <el-table-column prop="registrationTime" label="报名时间" /> -->
			<el-table-column prop="activityTime" label="活动时间" />
			<el-table-column label="操作">
				<template #default="{row}">
					<el-button link type="primary" plain color="#112DF2" @click="editItem(row.id,row.state)">活动管理</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="paginations">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next" :total="total"></el-pagination>
		</div>
		<activity-shops :dialog-visible.sync="participateShopShow" ref="child1" :activityId='activityId' :visiFlag="visiFlag"></activity-shops>
		<activity-goods :dialog-visible.sync="productShow" ref="child2" :activityType="1" :activityId='activityId' :visiFlag="visiFlag"></activity-goods>
		<activity-order :dialog-visible.sync="orderCountShow" ref="child3" :activityType="1" :activityId='activityId' :visiFlag="visiFlag"></activity-order>
	</div>
</template>

<script>
	import {activityPageList} from '@/api'
	import {formatDateD,stateStr,actNameStr} from '@/utils/data'
	import { Search } from "@element-plus/icons-vue";
	import activityShops from "@/components/PopTable/activityShops";
	import activityGoods from "@/components/PopTable/activityGoods";
	import activityOrder from "@/components/PopTable/activityOrder";
	export default {
		components: {activityShops,activityGoods,activityOrder},
		data () {
			return {
				Search,
				pushData:{
					fuzzySearch:'',
					type:1,
					state:null, //活动状态（'NOT_STARTED'：未开始，'IN_REGISTRATION'：报名中，'REGISTRATION_ENDED'：报名结束，'IN_PROGRESS'：进行中，'ENDED'：已结束）
				},
				statusList:[
					{name:'未开始',status:'NOT_STARTED'},
					// {name:'报名中',status:'IN_REGISTRATION'},
					// {name:'报名结束',status:'REGISTRATION_ENDED'},
					{name:'进行中',status:'IN_PROGRESS'},
					{name:'已结束',status:'ENDED'},
				],
				tableData:[],
				pageSize: 10,	
				pageNum: 1,
				total:0,
				emptyText:'暂无数据',
				activityId:'',
				participateShopShow:false,
				productShow:false,
				orderCountShow:false
			}
		},
		created() {
			this.search()
		},	
		methods:{
			dialogLayer(id,type){
				this.activityId = id
				if(type == 1){
					this.$refs.child1.search()
				}
				if(type == 2){
					this.$refs.child2.search(id)
				}
				if(type == 3){
					this.$refs.child3.search(id)
				}
				this.participateShopShow = type == 1
				this.productShow = type == 2
				this.orderCountShow = type == 3
			},
			search(){
				let postData = {
					 "paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					}
				}
				this.pushData.state = this.pushData.state?this.pushData.state:null
				postData = Object.assign(postData, this.pushData)
				activityPageList(postData).then(res => {
					if(res&&res.success){
						let list = res.data.list
						list.forEach(item=>{
							item.createdTime = formatDateD(item.createdTime)
							item.registrationTime = formatDateD(item.registrationTimeBegin) + '~' + formatDateD(item.registrationTimeEnd)
							item.activityTime = formatDateD(item.activityTimeBegin) + '~' + formatDateD(item.activityTimeEnd)
							item.stateStr = stateStr(item.state)
							item.actNameStr = actNameStr(item.name)
						})
						this.tableData = list
						this.total = Number(res.data.total)
					}
				})
			},
			visiFlag(val,type){
				if(type == 1){
					this.participateShopShow = val
					return
				}
				if(type == 2){
					this.productShow = val
					return
				}
				if(type == 3){
					this.orderCountShow = val
					return
				}
			},
			editItem(id,state){
				if(state==3){
					this.$message({
						showClose: true,
						message: "进行中的活动不可编辑",
						type: "warning",
					});
					return
				}
				this.$router.push('/active/flashSaleList/flashSaleAdd/'+id)
			},
			add(){
				this.$router.push('/active/flashSaleList/flashSaleAdd/')
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.search()
			},
			handleCurrentChange(val) {
				this.pageNum = val;
				this.search()
			},
		}
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	
</style>