<template>
	<div class="el-dialog-body-spacing">
		<el-dialog :title="activityType==3?'成团量':'成单量'" :model-value="dialogVisible" width="1100px" :before-close="hideDialog"
			:close-on-click-modal='false' append-to-body>
			<div style="position: relative">
				<div class="flexB">
					<span></span>
					<el-input placeholder="搜索" v-model="pushData.fuzzySearch" @keyup.enter="search()" style="width: 300px;">
						<template #suffix>
							<el-icon class="el-input__icon">
								<component :is="Search" class="enter-sj" @click="search()"></component>
							</el-icon>
						</template>
					</el-input>
				</div>
				<el-table :data="tableData" :empty-text="emptyText" style="width: 100%;margin-top: 15px;">
					<el-table-column prop="id" label="订单编号"></el-table-column>
					<el-table-column prop="goodsNameStr" label="商品信息"></el-table-column>
					<el-table-column prop="customerNickName" label="会员">
						<template #default="{row}">
							<div>{{row.customerNickName+'('+row.customerPhoneNumber+')'}}</div>
						</template>
					</el-table-column>
					<el-table-column v-if="activityType==1" prop="actualPaymentAmount" label="秒杀价"></el-table-column>
					<el-table-column v-if="activityType==2" prop="actualPaymentAmount" label="体验价"></el-table-column>
					<el-table-column v-if="activityType==3" prop="actualPaymentAmount" label="拼团价"></el-table-column>
					<el-table-column prop="stateStr" label="订单状态"></el-table-column>	
				</el-table>	
				<div class="paginations">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next" :total="totalNum"></el-pagination>
				</div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import { Search } from "@element-plus/icons-vue";
	import {formatDateOrder,orderStateStr,goodsNameStr} from '@/utils/data'
	import {orderPageList} from '@/api'
	export default {
		props: {
			visiFlag: {
				type: Function,
				default: function() {}
			},
			activityType:{
				type:Number,
				default: 1
			},
			activityId:{
				type:String,
				default: ''
			},
			dialogVisible: {
				type: Boolean,
				default: false
			},
		},
		data() {	
			return {
				Search,
				pushData:{
					fuzzySearch:'',
					state:null
				},
				activityId:'',
				emptyText:'暂无数据',
				tableData:[],
				pageSize: 5,	
				pageNum: 1,
				totalNum:0,
				seleArr: [],
				isChange: false
			}
		},
		methods: {
			search(id){
				this.activityId = id
				let postData = {
					 "paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					},
					activityId:id
				}
				postData = Object.assign(postData, this.pushData)
				if(this.activityType==3){
					postData.includeOrderStates = ['DISTRIBUTING','COMPLETION']
				}
				orderPageList(postData).then(res => {
					if(res&&res.success){
						let list = res.data.list
						list.forEach(item=>{
							item.stateStr = orderStateStr(item.state)
							item.shopName = orderStateStr(item.state)
							item.goodsNameStr = goodsNameStr(item.goodsNames[0])
						})
						this.tableData = list
						this.totalNum = Number(res.data.total)
					}
				})
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.search(this.activityId)
			},
			handleCurrentChange(val) {
				this.pageNum = val;
				this.search(this.activityId)
			},
			hideDialog() {
				this.visiFlag(false,3)
			}
		}
	}
</script>
<style rel="stylesheet/scss" lang='scss' scoped>
	.el-dialog__body{
		overflow: hidden;
		overflow-y:auto;
	}
</style>
