<template>
	<div class="mainWrap">
		<div class="mainWrap flexB marginB24">
			<h3>新建体验活动</h3>
			<div class="footerBtn">
				<el-button @click="() => this.$router.go(-1)">取 消</el-button>
				<el-button type="primary" color='#112DF2' @click="save" :loading="loading">保存</el-button>
			</div>
		</div>
		<div>
			<el-button type="primary" link @click="addGoods()">+ 添加活动商品</el-button>
			<el-table  :data="goods" border :empty-text="emptyText" style="width:1200px;margin-top: 15px">
				<el-table-column align="center" prop="name" label="服务项目/零售产品/卡项" />
				<el-table-column align="center" prop="typeStr" label="类型" />
				<el-table-column align="center" prop="salePrice" label="零售价" />
				<el-table-column align="center" prop="goodsPurchasesLimit" label="可参与次数">
					<template #default="{row}">
						<el-input v-model="row.goodsPurchasesLimit" @keyup="row.goodsPurchasesLimit=row.goodsPurchasesLimit.replace(/^(0+)|[^\d]+/g,'')" placeholder="请填写次数">
							<template #append>次</template>
						</el-input>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="activePrice" label="体验价">
					<template #default="scope">
						<el-input v-model="scope.row.activePrice" @input="groupPrice(scope.$index,scope.row.activePrice)" @blur="groupPriceBlur(scope.$index,scope.row.activePrice)" @keyup="scope.row.activePrice=scope.row.activePrice.replace(/[^\d\.]/g,'')" placeholder="请输入体验价">
							<template #append>元</template>
						</el-input>
					</template>
				</el-table-column>
				<el-table-column align="center" label="操作">
					<template #default="scope">
						<el-button link type="danger" @click="removeProduct(scope.$index)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<select-goods :dialog-visible.sync="isSelect" ref="child" :close="mergeList" :isShow='true'
			:sel-list.sync="selList" :visiFlag="visiFlag"></select-goods>
	</div>
</template>

<script>
	import Validate from '@/utils/rules'
	import selectGoods from "@/components/PopTable/selectGoods";
	import {experiencePriceCreate} from '@/api'
	export default{
		components: {selectGoods},
		data (){
			return{
				goods:[],
				loading: false,
				isSelect:false,
				emptyText:'暂无数据',
				selList:[],
				type:1
			}
		},
		created() {
			
		},	
		methods:{
			groupPrice(index,val){
				if(val>99999.99)val=99999.99;
				if(val<0)val=0;
				this.goods[index].activePrice = val
			},
			groupPriceBlur(index,val){
				val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')
				this.goods[index].activePrice = val
			},
			addGoods(){
				this.isSelect = true
				this.$refs.child.search(this.type)
			},
			save(){
				if (this.goods.length == 0) {
					this.$message({
						showClose: true,
						message: "请选择活动商品",
						type: "warning",
					});
					return;
				}
				for(let i of this.goods){
					let val = Number(i.activePrice)
					let val1 = Number(i.goodsPurchasesLimit)
					let val2 = Number(i.salePrice)
					if(!Number.isInteger(val1) || val1 == "" || val1 == null || val1 <= 0){
						this.$message({
							showClose: true,
							message: "请填写可购买次数",
							type: "warning",
						});
						return;
					}
					if(isNaN(val) || val == "" || val == null || val <= 0){
						this.$message({
							showClose: true,
							message: "请填写体验价",
							type: "warning",
						});
						return;
					}
					if(val2<val){
						this.$message({
							showClose: true,
							message: "体验价不能大于原价",
							type: "warning",
						});
						return;
					}
				}
				let arr = []
				this.goods.forEach(item=>{
					arr.push({
						goodsId:item.id,
						goodsType:item.activeGoodsType,
						activePrice:item.activePrice,
						goodsPurchasesLimit:item.goodsPurchasesLimit
					})
				})
				this.loading = true
				experiencePriceCreate({goods:arr}).then(res=>{
					if(res.success){
						this.$router.replace('/active/experienceList')
					}else{
						this.loading = false
						this.$message.error(res.msg)
					}
				})
			},
			removeProduct(index) {
				this.goods.splice(index, 1);
				// this.isSelect.splice(index, 1);
			},
			visiFlag(num,val){
				this.type = num
				this.isSelect = val
			},
			mergeList(data) {
				let goods = this.goods;
				let arr = [];
				for (let i = 0; i < data.length; i++) {
					let pushState = false;
					for (let j = 0; j < goods.length; j++) {
						if (data[i].id === goods[j].id) {
							arr.push(goods[j]);
							break;
						}
						if (j == goods.length - 1) {
							pushState = true;
						}
					}
					if (pushState || goods.length == 0) {
						arr.push({
							id: data[i].id,
							name: data[i].name,
							typeStr: data[i].typeStr,
							salePrice: data[i].salePrice,
							activeGoodsType:data[i].activeGoodsType,
							goodsPurchasesLimit:1,
							activePrice:''
						});
					}
				}
				this.goods = arr;
			},
		}
	}
</script>

<style rel="stylesheet/scss" lang='scss' scoped>
	::v-deep .el-form-item__content{
		display: block;
	}	
</style>