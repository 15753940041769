<template>
	<div class="el-dialog-body-spacing">
		<el-dialog title="分配门店" :model-value="dialogVisible" width="1200px" :before-close="hideDialog"
			:close-on-click-modal='false' append-to-body>
			<div style="position: relative">
				<el-table :data="tableData" :empty-text="emptyText" ref='tableBox' :row-key="getRowKeys" @row-click='clickRow'
					@selection-change="selectList" style="width: 100%;margin-top: 10px;">
					<el-table-column type="selection" width="60" :selectable="selectable" v-if="isShow"
					:reserve-selection="false"></el-table-column>
				    <el-table-column prop="id" label="门店编号" width="240" />
				    <el-table-column prop="name" label="门店信息" width="380">
						<template #default="{row}">
							<div class="flexA">
								<el-image class="img100 bgColor marginR10" :src="row.resources[0]">
									<template #error>
									  <div class="image-slot ">
										<el-icon style='height:100px;'><PictureFilled /></el-icon>
									  </div>
									</template>
								</el-image>
								<div class="textL">
									<p>{{row.name}}</p>
									<p>{{row.head}}</p>
									<p>{{row.headConcat}}</p>	
								</div>
							</div>
						</template>
					</el-table-column>
				    <el-table-column prop="goodsCategoryNames" label="门店类目" width="240">
				    	<template #default="{row}">
				    		<div class="color112 bge5e" v-for="(item,index) in row.goodsCategoryNames" :key="index">{{item}}</div>
				    	</template>
				    </el-table-column>
				    <el-table-column prop="enabled" label="门店状态" width="240">
				    	<template #default="{row}">	
				    		<div :class="[!row.enabled?'colorff0':'color112']">{{row.enabled?'正常状态':'关闭状态'}}</div>
				    	</template>
				    </el-table-column>
				</el-table>
				<div class="paginations">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next" :total="total"></el-pagination>
				</div>
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="hideDialog">取 消</el-button>	
					<el-button color='#112DF2' type="primary" @click="confirm">确 定</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>
<script>
	import { Search } from "@element-plus/icons-vue";
	import {formatDate} from '@/utils/data'
	import {shopPageList} from '@/api'
	export default {
		props: {
			close: {
				type: Function,
				default: function() {}
			},
			visiFlag: {
				type: Function,
				default: function() {}
			},
			isShow: {
				type: Boolean,
				default: false
			},
			dialogVisible: {
				type: Boolean,
				default: false
			},
			selList: {
				type: Array,
				default: []
			},
			isRadio: {
			  type: Boolean,
			  default: false
			}
		},
		data() {	
			return {
				Search,
				pushData:{
					enabled:true,
					saleable:true,
					fuzzySearch:'',
					type:1
				},
				emptyText:'暂无数据',
				tableData:[],
				pageSize: 5,	
				pageNum: 1,
				total:0,
				seleArr: [],
				isChange: false
			}
		},
		watch: {
			dialogVisible(v) {
				if (v) {
					this.seleArr = this.selList
					this.$nextTick(() => {
						this.isChange = !this.isChange
					})
				}
			},
			isChange() {
				this.isChangeFun()
			}
		},
		methods: {
			search(){
				let postData = {
					 "paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					},
				}
				postData = Object.assign(postData, this.pushData)
				shopPageList(postData).then(res => {
					if(res&&res.success){
						let list = res.data.list
						this.tableData = list
						this.total = Number(res.data.total)
					}
				})
			},
			// 选择
			selectList(val) {
				if (!this.clearSelector) {
					if (this.isRadio) {
						// 单选
						const rows = val[val.length - 1]
						if (rows) {
							this.selList.splice(0, 1, rows)
						} else {
							this.selList.splice(0, 1)
						}
						this.isChangeFun()
					} else {
						let s = this.selList
						s.splice(0, s.length)
						for (let i = 0; i < val.length; i++) {
							s.splice(i, 0, val[i])
						}
					}
				}
			},
			selectable(row) {
				if (!this.isShow) {
					if (row.state == 1) {
						if (this.option.smallstate === 1) {
							if (row.smallstate === 1) {
								return true
							} else {
								return false
							}
						} else {
							return true
						}
					} else {
						return false
					}
				} else {
					return true
				}
			},
			isChangeFun() {
				this.$nextTick(() => {
					let arr = []
					let backArr = []
					this.clearSelector = true
					this.$refs.tableBox.clearSelection()
					for (let i = 0; i < this.selList.length; i++) {
						for (let j = 0; j < this.tableData.length; j++) {
							if (this.selList[i].id == this.tableData[j].id) {
								arr.push(this.tableData[j])
								break
							}
							if (j == this.tableData.length - 1) {
								backArr.push(this.selList[i])
							}
						}
					}
					arr.forEach(row => {
						this.$refs.tableBox.toggleRowSelection(row)
					})
					backArr.forEach(row => {
						this.$refs.tableBox.toggleRowSelection(row)
					})
					this.clearSelector = false
				})
			},
			clickRow(row) {
				if (this.selectable(row)) {
					this.$refs.tableBox.toggleRowSelection(row)
				}
			},
			getRowKeys(row) {
				return row.id
			},
			clickRow(row) {
				if (this.selectable(row)) {
					this.$refs.tableBox.toggleRowSelection(row)
				}
			},
			getRowKeys(row) {
				return row.id
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.search()
			},
			handleCurrentChange(val) {
				this.pageNum = val;
				this.search()
			},
			confirm() {
				// this.selList = this.seleArr
				this.hideDialog()
				this.close(this.seleArr, this.closebackdata)
			},
			hideDialog() {
				this.visiFlag(false)
			}
		}
	}
</script>
<style rel="stylesheet/scss" lang='scss' scoped>
	.el-dialog__body{
		overflow: hidden;
		overflow-y:auto;
	}
</style>
