<template>
	<div class="mainWrap">
		<div class="mainWrap flexB marginB24">
			<h3>{{edit?'编辑':'新增'}}拼团活动</h3>
			<div class="footerBtn" v-if="step==1">
				<el-button @click="() => this.$router.go(-1)">取 消</el-button>
				<el-button type="primary" color='#112DF2' @click="submit" :loading="loading">下一步</el-button>
			</div>
			<div class="footerBtn" v-if="step==2">
				<el-button type="primary" color='#112DF2' @click="step=1">上一步</el-button>
				<el-button type="primary" color='#112DF2' @click="save" :loading="loading">保存</el-button>
			</div>
		</div>
		<el-form :model="pushData" ref="formBox" :rules="rules" label-width="150px" class="formBox">
			<el-row :gutter="16" v-if="step==1">
				<el-col :span="13">
					<el-form-item label="活动名称" prop="name">
						<el-input v-model="pushData.name" maxlength="20" placeholder="请输入活动名称"></el-input>
					</el-form-item>
					<!-- <el-form-item label="报名时间" prop="registrationTime">
						<el-date-picker v-model="registrationTime"
							type="daterange" value-format="YYYY-MM-DD" start-placeholder="选择报名开始日期"
							end-placeholder="选择报名结束日期" :disabled-date="disabledDate" />
					</el-form-item> -->
					<el-form-item label="活动时间" prop="activityTime">
						<el-date-picker v-model="activityTime"
							type="daterange" value-format="YYYY-MM-DD" start-placeholder="选择活动开始日期"
							end-placeholder="选择活动结束日期" :disabled-date="disabledDateA" />
					</el-form-item>
					<el-form-item label="活动楼位" prop="serial">
						<el-input v-model="pushData.serial" placeholder="输入活动楼位"></el-input>
					</el-form-item>
					<el-form-item label="团购有效期" prop="rule.activityParticipationNumber">
						<div class='flexA'>
							<el-select v-model="validity.day" clearable class="marginR10" style='width:80px'>
								<el-option v-for="item in days" :key="item"
									:label='item' :value="item"></el-option>
							</el-select>
							<div class="marginR10">天</div>
							<el-select v-model="validity.hou" clearable class="marginR10" style='width:80px'>
								<el-option v-for="item in hours" :key="item"
									:label='item' :value="item"></el-option>
							</el-select>
							<div class="marginR10">时</div>
							<el-select v-model="validity.min" clearable class="marginR10" style='width:80px'>
								<el-option v-for="item in mins" :key="item"
									:label='item' :value="item"></el-option>
							</el-select>分
						</div>
					</el-form-item>
					<el-form-item label="活动可参与次数" prop="rule.activityParticipationNumber">
						<el-select v-model="pushData.rule.activityParticipationNumber" clearable class="marginR10">
							<el-option v-for="item in list" :key="item.num"
								:label='item.name' :value="item.num"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="单个商品参团次数" prop="rule.goodsParticipationNumber">
						<el-select v-model="pushData.rule.goodsParticipationNumber" clearable class="marginR10">
							<el-option v-for="item in numList" :key="item"
								:label='item' :value="item"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="模拟成团">
						<el-radio-group v-model="isAuto">
							<el-radio label="1">自动成团</el-radio>
							<el-radio label="2">手动成团</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-col>
				<el-form-item label="活动图片" style="width:90%;" prop='fileListData'>
					<el-upload class="uploadLists" name='files' :data="uploadData" :headers="importHeaders" ref="upload" :action="activityUploadUrl" :file-list="fileListData" multiple accept="image/jpg,image/jpeg,image/png" list-type="picture-card" :limit="6" :on-preview="handlePictureCardPreview" :on-exceed="handleExceed" :beforeUpload="beforeAvatarUpload" :on-success="(response, file, fileList)=>{return uploadSuccess(response, file, fileList,1)}" :on-remove="(file,fileList)=>{return handleRemove(file,fileList,1)}">
						<el-icon><Plus /></el-icon>
					</el-upload>
					<div class="tips">
						<div>图片注意：</div>	
						<div>1、建议尺寸：350px*120px；</div>
						<div>2、建议格式：jpg、png、jpeg</div>
						<div>3、建议数量：1张；</div>
					</div>	
					<el-dialog v-model="dialogVisible">
						<img w-full :src="dialogImageUrl" alt="Preview Image" />
					</el-dialog>
				</el-form-item>
			</el-row>
			<el-row v-if="step==2">
				<el-form-item label="活动商品">
					<el-button type="primary" link @click="addGoods()">+ 添加活动商品</el-button>
					<div class='textC' style="margin-top: 15px;background:#F9FAFB;color:#8F9BB3;width:1200px;">
						<el-row>
							<div class='flexA fontB' style='background:#EFF1FE;color:#000;width:100%;'>
								<el-col :span='6'>服务项目/零售产品/卡项</el-col>
								<el-col :span='2'>类型</el-col>
								<el-col :span='2'>零售价</el-col>
								<el-col :span='3'>虚拟销量</el-col>
								<el-col :span='3'>成团数</el-col>
								<el-col :span='3'>参团价</el-col>
								<el-col :span='3'>团长价格/选填</el-col>
								<el-col :span='2'>操作</el-col>
							</div>
						</el-row>
						<div v-if='goods.length<=0' style='border-bottom:1px solid #EFF1FE;width:100%;'>{{emptyText}}</div>
						<el-row v-for="(item,index) in goods" style='border-bottom:1px solid #EFF1FE;'>
							<div class='flexA padB10' style='width:100%;'>
								<el-col :span='6'>{{item.name}}</el-col>
								<el-col :span='2'>{{item.typeStr}}</el-col>
								<el-col :span='2'>{{item.salePrice}}</el-col>
								<el-col :span='3'>
									<el-input style="width:80%;" v-model="item.virtualSalesVolume" @input="virtualSalesVolume(index,item.virtualSalesVolume)" @blur="virtualSalesVolumeBlur(index,item.virtualSalesVolume,item.virtualSalesVolumeOld)" @keyup="item.virtualSalesVolume=item.virtualSalesVolume.replace(/\D/g,'')" placeholder="虚拟销量">
									</el-input>
								</el-col>
								<el-col :span='9'>
									<el-row>
										<div class='flexA padT10' v-for="(items,itemIndex) in item.specifications">
											<el-col :span='8'>
												<div style='width:95%;'>
													<el-input v-model="items.groupNumber" @keyup="items.groupNumber=items.groupNumber.replace(/\D/g,'')" placeholder="成团人数">
														<template #append>人</template>
													</el-input>
												</div>
											</el-col>
											<el-col :span='8'>
												<div style='width:95%;'>
													<el-input v-model="items.groupPrice" @input="groupPrice(index,itemIndex,items.groupPrice,1)" @blur="groupPriceBlur(index,itemIndex,items.groupPrice,1)" @keyup="items.groupPrice=items.groupPrice.replace(/[^\d\.]/g,'')" placeholder="参团价">
														<template #append>元</template>
													</el-input>
												</div>
											</el-col>
											<el-col :span='8'>
												<div style='width:95%;'>
													<el-input v-model="items.groupLeaderPrice" @input="groupPrice(index,itemIndex,items.groupLeaderPrice,2)" @blur="groupPriceBlur(index,itemIndex,items.groupLeaderPrice,2)" @keyup="items.groupLeaderPrice=items.groupLeaderPrice.replace(/[^\d\.]/g,'')" placeholder="团长价">
														<template #append>元</template>
													</el-input>
												</div>
											</el-col>
										</div>
										<div class='addSpec' v-if='item.specifications.length<5' @click="addSpec(index)">+ 添加规格</div>
									</el-row>
								</el-col>
								<el-col :span='2'>
									<el-button link type="danger" @click="removeProduct(index)">删除</el-button>
								</el-col>
							</div>
						</el-row>
					</div>
				</el-form-item>
			</el-row>
		</el-form>
		<select-goods :dialog-visible.sync="isSelect" ref="child" :close="mergeList" :isShow='true'
			:sel-list.sync="selList" :visiFlag="visiFlag"></select-goods>
	</div>
</template>

<script>
	import Validate from '@/utils/rules'
	import {activityBeginForm,activityEndForm,formatDate,typeStr} from '@/utils/data'
	import selectGoods from "@/components/PopTable/selectGoods";
	import {activityUploadUrl,activityCreate,activityInfo,userLogin,activityUpdate} from '@/api'
	export default{
		components: {selectGoods},
		data (){
			return{
				activityUploadUrl,
				importHeaders:{
					'Authorization':''
				},
				uploadData:{folder:'activity/'},
				list:[{name:'不限',num:-1},{name:'1次',num:1	},{name:'2次',num:2},{name:'3次',num:3},{name:'4次',num:4},{name:'5次',num:5},
					{name:'6次',num:6},{name:'7次',num:7},{name:'8次',num:8},{name:'9次',num:9}],
				numList:[1,2,3,4,5,6,7,8,9],
				days:[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
				hours:['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'],
				mins:['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30',
					'31','32','33','34','35','36','37','38','39','40','41','42','43','44','45','46','47','48','49','50','51','52','53','54','55','56','57','58','59'],
				validity:{
					day:1,hou:'00',min:'00'
				},
				step:1,
				fileListData:[],
				imgUrl:[],
				dialogImageUrl:'',
				dialogVisible:false,
				registrationTime:[],
				activityTime:[],
				pushData:{
					name:'',
					type:2,
					resources:[],
					rule:{
						activityParticipationNumber:-1,
						goodsParticipationNumber:1
					}
				},
				isAuto:'1',
				rules: {
					name: [{required: true, message: '请输入活动名称', trigger: 'blur'}],
					serial: [{required: true,message: '请输入活动楼层',validator: Validate.inventoryNum, trigger: 'blur'}],
					fileListData:[{}],
					'rule.activityParticipationNumber':[{required: true, message: '请选择活动可参与次数', trigger: 'blur'}],
					'rule.goodsParticipationNumber':[{required: true, message: '请选择单个商品参与次数', trigger: 'blur'}]
				},
				loading: false,
				edit: false,
				goods:[],
				isSelect:false,
				emptyText:'暂无数据',
				selList:[],
				type:1
			}
		},
		computed: {
		    userInfo() {
				return this.$store.state.userInfo;
		    },
		},
		watch:{
			userInfo: {
				handler(nval, oval) {
					// console.log(nval, oval);//nval改变后的新数据，oval改变前的旧数据
				},
				deep: false, // 深度监听
				immediate: true,//立即执行
			}
		},
		created() {
			this.importHeaders.Authorization = this.userInfo.token?('Bearer'+' '+ this.userInfo.token):''
			if(this.$route.params.id){
				this.edit = true
				this.getDate(this.$route.params.id)
			}
		},	
		methods:{
			virtualSalesVolume(index,val){
				if(val == "" || val == null){
					this.goods[index].virtualSalesVolume = 0
					return
				}
				this.goods[index].virtualSalesVolume = val
			},
			virtualSalesVolumeBlur(index,val,old){
				if(Number(val)<Number(old)&&(Number(old)>0)&&(Number(val)!=Number(old))){
					this.$message({
						showClose: true,
						message: "新设置的活动商品虚拟销量不能小于上次设置的值",
						type: "warning",
					});
					this.goods[index].virtualSalesVolume = old
				}
			},
			groupPrice(index,index1,val,type){
				if(val>99999.99)val=99999.99;
				if(val<0)val=0;
				if(type==1){
					this.goods[index].specifications[index1].groupPrice = val
				}
				if(type==2){
					this.goods[index].specifications[index1].groupLeaderPrice = val
				}
			},
			groupPriceBlur(index,index1,val,type){
				val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')
				if(type==1){
					this.goods[index].specifications[index1].groupPrice = val
				}
				if(type==2){
					this.goods[index].specifications[index1].groupLeaderPrice = val
				}
			},
			getDate(id){
				activityInfo({data:{id}}).then(res=>{
					let data = res.data
					let str = data.rule.groupPurchaseValidityPeriod 
					let day,hou,min
					day = parseInt(str/1440)
					hou = parseInt((str%1440)/60)
					min = (str%1440)%60
					this.validity.day = day
					this.validity.hou = hou<10?('0'+hou):hou
					this.validity.min = min<10?('0'+min):min
					this.registrationTime = [formatDate(data.registrationTimeBegin),formatDate(data.registrationTimeEnd)]
					this.activityTime = [formatDate(data.activityTimeBegin),formatDate(data.activityTimeEnd)]
					this.isAuto = data.rule.automaticGrouping?'1':'2'
					if(data.resources&&data.resources.length>0){
						data.resources.forEach(ies =>{
							this.fileListData.push({url: ies.resource});
							this.imgUrl.push(ies.resource)
						});
					}
					delete data.resources
					delete data.activityTimeBegin
					delete data.activityTimeEnd
					delete data.registrationTimeBegin
					delete data.registrationTimeEnd
					data.serial = data.serial.toString()
					let goods = []
					let arr = []
					data.goods.forEach(item=>{
						goods.push({
							id:item.goodsId,
							name: item.goodsName,
							typeStr: typeStr(item.goodsType),
							salePrice: item.goodsSalePrice,
							activeGoodsType:item.goodsType,
							virtualSalesVolume:item.virtualSalesVolume,
							virtualSalesVolumeOld:item.virtualSalesVolume,
							specifications:item.specifications
						})
						arr.push({
							id:item.goodsId,
							name: item.goodsName,
							typeStr: typeStr(item.goodsType),
							salePrice: item.goodsSalePrice,
							activeGoodsType:item.goodsType
						})
					})
					this.selList = arr
					this.goods = goods
					this.pushData = data
					delete data.goods
				})
			},
			addGoods(){
				this.isSelect = true
				this.$refs.child.search(this.type)
			},
			disabledDate(time) {
				return time.getTime() < Date.now() - 8.64e7
			},
			disabledDateA(time) {
				return time.getTime() < Date.now() - 8.64e7
				// return time.getTime() < Date.now()
			},
			// 确认提交
			submit() {
				// if(!(this.registrationTime&&this.registrationTime.length>0)){
				// 	this.$message({
				// 		showClose: true,
				// 		message: "请选择报名时间",
				// 		type: "warning",
				// 	});
				// 	return
				// }
				if(!(this.activityTime&&this.activityTime.length>0)){
					this.$message({
						showClose: true,
						message: "请选择活动时间",
						type: "warning",
					});
					return
				}
				if(!(this.imgUrl&&this.imgUrl.length>0)){
					this.$message({
						showClose: true,
						message: "请上传图片",
						type: "warning",
					});
					return
				}
				// if(new Date(this.registrationTime[1]).getTime() > new Date(this.activityTime[0]).getTime()){
				// 	this.$message({
				// 		showClose: true,
				// 		message: "活动开始时间不能小于报名结束时间",
				// 		type: "warning",
				// 	});
				// 	return
				// }
				let min = (Number(this.validity.day)*24+Number(this.validity.hou))*60+Number(this.validity.min)
				if(min==0){
					this.$message({
						showClose: true,
						message: "请选择团购有效期",
						type: "warning",
					});
					return
				}
				if(min<60){
					this.$message({
						showClose: true,
						message: "团购有效期不得低于1小时",
						type: "warning",
					});
					return
				}
				this.pushData.rule.groupPurchaseValidityPeriod = min
				this.pushData.rule.automaticGrouping = this.isAuto=='1'
				this.$refs.formBox.validate((valid) => {
					if (valid) {
						this.step = 2
					}
				})
			},
			save(){
				this.pushData.resources = this.imgUrl
				if (this.goods.length == 0) {
					this.$message({
						showClose: true,
						message: "请选择活动商品",
						type: "warning",
					});
					return;
				}
				for(let i of this.goods){
					let val1 = Number(i.salePrice)
					for(let item of i.specifications){
						let val = Number(item.groupPrice)
						let val2 = Number(item.groupNumber)
						let val3 = Number(item.groupLeaderPrice)
						if(!Number.isInteger(val2) || val2 == "" || val2 == null || val2 <= 0){
							this.$message({
								showClose: true,
								message: "请填写成团人数",
								type: "warning",
							});
							return;
						}
						if(isNaN(val) || val == "" || val == null || val <= 0){
							this.$message({
								showClose: true,
								message: "请填写拼团价",
								type: "warning",
							});
							return;
						}
						if(val2<2||val2>20){
							this.$message({
								showClose: true,
								message: "成团人数需在2~20之间",
								type: "warning",
							});
							return;
						}
						if(val1<val){
							this.$message({
								showClose: true,
								message: "拼团价不能大于原价",
								type: "warning",
							});
							return;
						}
						if((val3&&(val3>val))||(val3==0)){
							this.$message({
								showClose: true,
								message: '团长价必须大于0且不能大于拼团价',
								type: "warning",
							});
							return;
						}
					}
				}
				let arr = []
				this.goods.forEach(item=>{
					arr.push({
						goodsId:item.id,
						goodsType:item.activeGoodsType,
						virtualSalesVolume:item.virtualSalesVolume,
						activePrice:0,
						specifications:item.specifications
					})
				})
				let postData = {
					goods:arr,
					"registrationTimeBegin": activityBeginForm(this.registrationTime[0]),
					"registrationTimeEnd": activityEndForm(this.registrationTime[1]),
					"activityTimeBegin": activityBeginForm(this.activityTime[0]),
					"activityTimeEnd": activityEndForm(this.activityTime[1])
				}
				postData = Object.assign(postData, this.pushData)
				let postUrl = activityCreate
				if(this.$route.params.id){
					postData.id = this.pushData.id
					postUrl = activityUpdate
				}
				this.loading = true
				postUrl(postData).then(res=>{
					if(res.success){
						this.$router.replace('/active/groupList')
					}else{
						this.loading = false
						this.$message.error(res.msg)
					}
				})
			},
			addSpec(index){
				if(this.goods[index].specifications.length>4){
					return
				}
				this.goods[index].specifications.push({
					groupPrice:'',
					groupNumber:'',
					groupLeaderPrice:''
				})
			},
			removeProduct(index) {
				this.goods.splice(index, 1);
				this.selList.splice(index, 1);
			},
			visiFlag(num,val){
				this.type = num
				this.isSelect = val
			},
			mergeList(data) {
				let goods = JSON.parse(JSON.stringify(this.goods));
				let arr = [];
				for (let i = 0; i < data.length; i++) {
					let pushState = false;
					for (let j = 0; j < goods.length; j++) {
						if (data[i].id === goods[j].id) {
							arr.push(goods[j]);
							break;
						}
						if (j == goods.length - 1) {
							pushState = true;
						}
					}
					if (pushState || goods.length == 0) {
						arr.push({
							id: data[i].id,
							name: data[i].name,
							typeStr: data[i].typeStr,
							salePrice: data[i].salePrice,
							activeGoodsType:data[i].activeGoodsType,
							virtualSalesVolume:0,
							virtualSalesVolumeOld:0,
							specifications:[{
								groupPrice:'',
								groupNumber:'',
								groupLeaderPrice:''
							}]
						});
					}
				}
				this.goods = arr;
			},
			handlePictureCardPreview(file, fileList,types){
				this.imgUrl.forEach((ies,index) =>{
					if(file.response){
						if(ies==file.response.data[0]){
							this.dialogImageUrl = ies
							this.dialogVisible = true
						}
						return
					}
					if(ies==file.url){
						this.dialogImageUrl = ies
						this.dialogVisible = true
					}
				});
			},
			handleRemove(file, fileList,types) {
				this.fileListData.forEach((ies,index) =>{
					if(ies.url){
						if(ies.url==file.url || ies.url==file.response.data[0]){
							this.fileListData.splice(index,1)
						}
					}else{
						if(ies==file.url || ies==file.response.data[0]){
							this.fileListData.splice(index,1)
						}
					}
				});
				this.imgUrl.forEach((ies,index) =>{
					if(ies==file.url || ies==file.response.data[0]){
						this.imgUrl.splice(index,1)
					}
				});
			},
			uploadSuccess(response, file, fileList,types) {
				let uid = file.uid;
				let idx = fileList.findIndex(item => item.uid === uid)
				if(!response.success){
					fileList.splice(idx, 1)
				}
				if(response.code=="A0200"||response.code=="A0201"){// 登录过期，重新登录
					this.$store.commit("updateUserInfo", '');
					this.$router.push("/")
					return
				}
				if(response.code=="A0202"){
					this.userLogin()
					return
				}
				this.fileListData.push(response.data[0])
				this.imgUrl.push(response.data[0])
			},
			userLogin(){
				let userInfo = this.userInfo
				let postData = {
					"grantType": "PLATFORM_REFRESH_TOKEN",
					refreshTokenTokenRequest:{
						refreshToken: this.userInfo.refreshToken
					}
				}
				userLogin(postData).then(res1=>{
					userInfo.token = res1.data.accessToken;
					userInfo.refreshToken = res1.data.refreshToken;
					this.$store.commit("updateUserInfo", userInfo);
					this.importHeaders.Authorization = userInfo.token?('Bearer'+' '+ userInfo.token):''
				})
			},
			handleExceed(files, fileList) {
				this.$message.warning("当前限制选择 6 个文件");
			},
			beforeAvatarUpload(file) {
				const extension = file.name.endsWith(".jpg")||file.name.endsWith(".JPG");
				const extension1 = file.name.endsWith(".png")||file.name.endsWith(".PNG");
				const extension2 = file.name.endsWith(".jpeg")||file.name.endsWith(".JPEG");
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!extension && !extension1 && !extension2) {
					this.$message({
						message: "上传模板只能是 .jpg,.png,.jpeg 格式!",
						type: "error"
					});
				}
				if (!isLt2M) {
					this.$message.error("上传图片大小不能超过 2M!");
				}
				return (extension || extension1 || extension2) && isLt2M;
			}
		}
	}
</script>

<style rel="stylesheet/scss" lang='scss' scoped>
	::v-deep .el-form-item__content{
		display: block;
	}
	.addSpec{
		width: 100%;
		text-align:center;
		margin-top:10px;
		border:1px dashed #8F9BB3;
		box-sizing: border-box;
	}
</style>