<template>
	<div>
		<el-form :model="pushData" :rules="rules" ref="formBox" label-width="130px" class="formBox">
			<div class="box">
				<div class="flexB">
					<div class="subTitle">{{edit?'编辑':'新建'}}{{pushData.type=='BANNER'?'广告位':pushData.type=='DIAMOND'?'金刚区':'轮播图'}}分类</div>
					<div class="footerBtn">
						<el-button @click="() => this.$router.go(-1)">取 消</el-button>
						<el-button type="primary" color='#112DF2' @click="submit" :loading="loading">{{edit?'保 存':'添 加'}}</el-button>
					</div>
				</div>
				<el-row :gutter="16">
					<el-col :span="8">
						<el-form-item label="类型" prop="type">
							<el-radio-group v-model="pushData.type" @change="getType" :disabled="edit">
								<el-radio label="BANNER">广告位</el-radio>
								<el-radio label="DIAMOND">金刚区</el-radio>
								<el-radio label="CAROUSEL">轮播图</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="名称" prop="name">
							<el-input v-model="pushData.name" :maxlength="pushData.type=='DIAMOND'?'4':'8'" placeholder="名称"></el-input>
						</el-form-item>
						<el-form-item label="排序" prop="serial">
							<el-input v-model="pushData.serial" placeholder="输入排序"></el-input>
						</el-form-item>
						<el-form-item label="是否可用" prop="enabled">
							<el-switch style="--el-switch-on-color:#A2A5B8; --el-switch-off-color:#409eff"
							 v-model="pushData.enabled" :active-value="0" :inactive-value="1"></el-switch>
						</el-form-item>
						<!-- <el-form-item label="是否跳转" prop="isJump">
							<el-switch style="--el-switch-on-color:#A2A5B8; --el-switch-off-color:#409eff"
							 v-model="pushData.isJump" :active-value="0" :inactive-value="1"></el-switch>
						</el-form-item> -->
						<el-form-item label="跳转类型">
							<el-radio-group v-model="pushData.jumpType">
								<el-radio label="NONE">无跳转</el-radio>
								<el-radio label="INTERNAl">内页跳转</el-radio>
								<el-radio label="WEGPAGE">网页</el-radio>
								<el-radio label="CATEGORY">类目</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="跳转路径" v-if="pushData.jumpType!='NONE'">
							<el-input v-if="pushData.jumpType=='INTERNAl'||pushData.jumpType=='WEGPAGE'" v-model="pushData.path" maxlength="200" placeholder="输入活动url"></el-input>
							<el-select v-if="pushData.jumpType=='CATEGORY'" v-model="pushData.path" placeholder="请选择类目" class="w300">
								<el-option v-for="item in list" :key="item.id"
									:label='item.name' :value="item.id"></el-option>
							</el-select>
							<!-- <el-select v-if="pushData.type==3" v-model="pushData.parentId" placeholder="请选择活动名称" class="w300" :disabled="edit">
								<el-option v-for="item in list" :key="item.parentId"
									:label='item.name' :value="item.parentId"></el-option>
							</el-select> -->
						</el-form-item>	
					</el-col>
				</el-row>
				<el-form-item label="有效期" prop="validity">
					<el-radio-group class="marginR20" v-model="pushData.validity">
						<el-radio :label="1">限期</el-radio>
						<el-radio :label="-1">长期有效</el-radio>
					</el-radio-group>
					<el-date-picker v-if="pushData.validity>0" v-model="indate"
						type="datetime" format="YYYY-MM-DD HH:mm" value-format="YYYY-MM-DD HH:mm" placeholder="截止日期" :disabled-date="disabledDate" />
				</el-form-item>
				<el-form-item label="分类图片">
					<el-upload class="uploadLists" name='files' :data="uploadData" :headers="importHeaders" ref="upload" :action="activityUploadUrl" :file-list="fileListData" accept="image/jpg,image/png" list-type="picture-card" :limit="1" :on-preview="handlePictureCardPreview" :on-exceed="handleExceed" :beforeUpload="beforeAvatarUpload" :on-success="(response, file, fileList)=>{return uploadSuccess(response, file, fileList,1)}" :on-remove="(file,fileList)=>{return handleRemove(file,fileList,1)}">
						<el-icon><Plus /></el-icon>
					</el-upload>
					<div class="tips">
						<div>注意：</div>
						<div>1、建议尺寸：{{pushData.type=='BANNER'?'352px*183px':pushData.type=='DIAMOND'?'80px*80px':'352px*125px'}}；</div>
						<div>2、建议格式：jpg、png</div>
						<div>3、建议数量：1张；</div>
					</div>
					<el-dialog v-model="dialogVisible">
					    <img w-full :src="dialogImageUrl" alt="Preview Image" />
					</el-dialog>
				</el-form-item>
			</div>
		</el-form>
	</div>
</template>

<script>
	import Validate from '@/utils/rules'
	import {formatDateIndate} from '@/utils/data'
	import {goodsCategoryList,homepageConfigCreate,homepageConfigUpdateById,homepageConfigInfo,activityUploadUrl,userLogin} from '@/api'
	export default {
		data() {
			return {
				activityUploadUrl,
				importHeaders:{
					'Authorization':''
				},
				uploadData:{folder:'activity/homepage-config/banner/'},
				fileListData:[],
				list:[],
				dialogImageUrl:'',
				dialogVisible:false,
				indate:'',
				pushData: {
					type:'BANNER',
					name: '',
					jumpType: 'NONE', //分类方式
					serial:'',
					enabled:'1',
					validity:-1,
					path:''
				},
				imgUrl:[],
				rules: {
					name: [{required: true, message: '请输入活动名称', trigger: 'blur'}],
					serial:[{required: true,message: '请输入0-999整数',validator: Validate.inventoryNum, trigger: 'blur'}],
					type: [{required: true}],
					validity: [{required: true}]
				},
				loading: false,
				edit: false
			}
		},
		computed: {
		    userInfo() {
				return this.$store.state.userInfo;
		    },
		},
		watch:{
			userInfo: {
				handler(nval, oval) {
					// console.log(nval, oval);//nval改变后的新数据，oval改变前的旧数据
				},
				deep: false, // 深度监听
				immediate: true,//立即执行
			}
		},
		created() {
			let type = this.$route.params.type
			this.pushData.type = type
			this.uploadData.folder = this.filter(type)
			this.getDate()
			this.importHeaders.Authorization = this.userInfo.token?('Bearer'+' '+ this.userInfo.token):''
			// 如果是编辑操作则渲染编辑数据
			if (this.$route.params.id) {
				this.edit = true
				this.loading = true	
				this.getInfo()
			}
		},
		methods: {
			filter(type){
				let path = 'activity/homepage-config/banner/'
				if(type == 'DIAMOND'){
					path = 'activity/homepage-config/diamond/'
				}
				if(type == 'CAROUSEL'){
					path = 'activity/homepage-config/carousel/'
				}
				return path
			},
			getType(){
				this.uploadData.folder = this.filter(this.pushData.type)
			},
			// 确认提交
			submit() {
				this.$refs.formBox.validate((valid) => {
					if (valid) {
						if(this.imgUrl.length==0){
							this.$message.error('至少上传一张图片')
							return
						}
						if(this.pushData.validity>0&&!this.indate){
							this.$message.error('请填写截止日期')
							return
						}
						if(this.pushData.validity>0){
							this.pushData.validity = new Date(this.indate).getTime()/1000
						}
						this.pushData.resources = this.imgUrl
						let url = this.edit ? homepageConfigUpdateById : homepageConfigCreate
						url(this.pushData).then(res=>{
							if(res.success){
								this.$router.replace('/active/diamondRegionList/')
							}else{
								this.loading = false
								this.$message.error(res.msg)
							}
						})
					}
				})
			},
			disabledDate(time) {
				return time.getTime() < Date.now()
			},
			getDate(){
				let postData = {parentId:0,level:1}
				goodsCategoryList({data:postData}).then(res => {
					if(res.success){
						this.list = res.data
					}
				})
			},
			getInfo(){
				let postData = {id:this.$route.params.id}
				homepageConfigInfo({data:postData}).then(res => {
					this.loading = false
					if(res.success){
						let data = res.data
						data.validity = data.validity*1
						if(data.validity!=-1){
							this.indate = formatDateIndate(data.validity)
							data.validity = 1
						}
						if(data.resources&&data.resources.length>0){
							data.resources.forEach(ies =>{
								this.fileListData.push({url: ies});
								this.imgUrl.push(ies)
							});
						}
						delete data.resources
						this.pushData = data
					}
				})
			},
			HTMLDecode(html) {
				return html.replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/&amp;/g, "&").replace(/&quot;/g, '"').replace(/&apos;/g, "'")
			},
			handlePictureCardPreview(file, fileList,types){
				this.imgUrl.forEach((ies,index) =>{
					if(file.response){
						if(ies==file.response.data[0]){
							this.dialogImageUrl = ies
							this.dialogVisible = true
						}
						return
					}
					if(ies==file.url){
						this.dialogImageUrl = ies
						this.dialogVisible = true
					}
				});
			},
			handleRemove(file, fileList,types) {
				this.fileListData.forEach((ies,index) =>{
					if(ies.url){
						if(ies.url==file.url || ies.url==file.response.data[0]){
							this.fileListData.splice(index,1)
						}
					}else{
						if(ies==file.url || ies==file.response.data[0]){
							this.fileListData.splice(index,1)
						}
					}
				});
				this.imgUrl.forEach((ies,index) =>{
					if(ies==file.url || ies==file.response.data[0]){
						this.imgUrl.splice(index,1)
					}
				});
			},
			uploadSuccess(response, file, fileList,types) {
				let uid = file.uid;
				let idx = fileList.findIndex(item => item.uid === uid)
				if(response.code.indexOf("A02")!=-1){
					fileList.splice(idx, 1)
				}
				if(response.code=="A0200"||response.code=="A0201"){// 登录过期，重新登录
					this.$store.commit("updateUserInfo", '');
					this.$router.push("/")
					return
				}
				if(response.code=="A0202"){
					this.userLogin()
					return
				}
				this.fileListData.push(response.data[0])
				this.imgUrl.push(response.data[0])
			},
			userLogin(){
				let userInfo = this.userInfo
				let postData = {
					"grantType": "PLATFORM_REFRESH_TOKEN",
					refreshTokenTokenRequest:{
						refreshToken: this.userInfo.refreshToken
					}
				}
				userLogin(postData).then(res1=>{
					userInfo.token = res1.data.accessToken;
					userInfo.refreshToken = res1.data.refreshToken;
					this.$store.commit("updateUserInfo", userInfo);
					this.importHeaders.Authorization = userInfo.token?('Bearer'+' '+ userInfo.token):''
				})
			},
			handleExceed(files, fileList) {
				this.$message.warning("当前限制选择 1 个文件");
			},
			beforeAvatarUpload(file) {
				const extension = file.name.endsWith(".jpg")||file.name.endsWith(".JPG");
				const extension1 = file.name.endsWith(".png")||file.name.endsWith(".PNG");
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!extension && !extension1 && !extension2 && !extension3) {
					this.$message({
						message: "上传模板只能是 .jpg,.png 格式!",
						type: "error"
					});
				}
				if (!isLt2M) {
					this.$message.error("上传图片大小不能超过 2M!");
				}
				return (extension || extension1 || extension2 || extension3) && isLt2M;
			},
		}
	}
</script>

<style rel="stylesheet/scss" lang='scss' scoped>
	.box {
		padding: 20px;
		margin-bottom: 20px;
		/* border: 1px solid #f0f0f0; */
		background: #fff;
		.subTitle {
			/* background: #ebedf0; */
			padding: 10px 15px;
			margin-bottom: 20px;
			font-weight: bold;
			font-size: 16px;
			color: #3f5b84;

			i {
				margin-right: 10px;
			}
		}
	}
	.avatar-uploader .avatar {
	  width: 100px;
	  height: 100px;
	  display: inline-block;
	  margin-right: 10px;
	}
	.avatar-uploader .el-upload {
	  border: 1px dashed var(--el-border-color);
	  border-radius: 6px;
	  cursor: pointer;
	  position: relative;
	  overflow: hidden;
	  transition: var(--el-transition-duration-fast);
	}
	
	.avatar-uploader .el-upload:hover {
	  border-color: var(--el-color-primary);
	}
	
	.el-icon.avatar-uploader-icon {
	  font-size: 28px;
	  color: #8c939d;
	  width: 100px;
	  height: 100px;
	  text-align: center;
	  border: 2px dashed #f2f2f2;
	}
	.tips{
		font-size: 13px;
		color: #999;
		display: inline-block;
		margin-left: 10px;
		line-height: 20px;
	}
	.ql-container {
		height: 300px;
		line-height: normal;
		width: 100%;
	}
 
	span.ql-size {
		max-width: 80px !important;
	}
 
	.ql-tooltip[data-mode="link"]::before {
		content: "请输入链接地址:";
	}
 
	.ql-tooltip.ql-editing a.ql-action::after {
		border-right: 0px;
		content: "保存";
		padding-right: 0px;
	}
 
	.ql-tooltip[data-mode="video"] {
		left: 0 !important;
	}
 
	.ql-tooltip[data-mode="video"]::before {
		content: "请输入视频地址:";
	}
 
	.ql-picker.ql-size .ql-picker-label::before,
	.ql-picker.ql-size .ql-picker-item::before {
		content: "14px";
	}
 
	.ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
	.ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
		content: "10px";
	}
 
	.ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
	.ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
		content: "18px";
	}
 
	.ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
	.ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
		content: "32px";
	}
 
	.ql-picker.ql-header .ql-picker-label::before,
	.ql-picker.ql-header .ql-picker-item::before {
		content: "文本";
	}
 
	.ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
	.ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
		content: "标题1";
	}
 
	.ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
	.ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
		content: "标题2";
	}
 
	.ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
	.ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
		content: "标题3";
	}
 
	.ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
	.ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
		content: "标题4";
	}
 
	.ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
	.ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
		content: "标题5";
	}
 
	.ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
	.ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
		content: "标题6";
	}
 
	.ql-picker.ql-font .ql-picker-label::before,
	.ql-picker.ql-font .ql-picker-item::before {
		content: "标准字体";
	}
 
	.ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
	.ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
		content: "衬线字体";
	}
 
	.ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
	.ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
		content: "等宽字体";
	}
</style>
<style lang='scss'>
	img{
		display: block;
		width: 100%;
	}
</style>
