<template>
	<div class="productListAdd-container">
		<div class="flexA" style="background:#fff;height:calc(100vh - 100px);">
			<div class="leftWrap">
				<el-form :model="pushData" :rules="rules" ref="formBox" label-width="130px" class="formBox">
					<div class="box">
						<div class="flexB">
							<div class="subTitle">{{edit?'编辑':'新增'}}项目分类</div>
							<div class="footerBtn">
								<el-button @click="() => this.$router.go(-1)">取 消</el-button>
								<el-button type="primary" color='#112DF2' @click="submit" :loading="loading">{{edit?'保 存':'添 加'}}</el-button>
							</div>
						</div>
						<el-form-item label="项目分类" prop="level">
							<el-radio-group v-model="pushData.level" :disabled="edit">
								<el-radio label="1">一级分类</el-radio>
								<el-radio label="2">二级分类</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="分类名称" prop="name">
							<el-input v-model="pushData.name" maxlength="10" placeholder="请输入项目名称"></el-input>
						</el-form-item>
						<el-form-item label="上级分类" v-if="pushData.level==2" prop="parentId">
							<el-select v-model="pushData.parentId" placeholder="请选择分类" class="w300" :disabled="edit">
								<el-option v-for="item in list" :key="item.parentId"
									:label='item.name' :value="item.parentId"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="适用人群" v-if="pushData.level==2" prop="forPeople">
							<el-input v-model="pushData.forPeople" placeholder="输入适用人群"></el-input>
						</el-form-item>
						<el-form-item label="见效周期" v-if="pushData.level==2" prop="effectivePeriod">
							<el-input v-model="pushData.effectivePeriod" placeholder="输入见效周期"></el-input>
						</el-form-item>	
						<el-form-item label="类目状态" prop="enabled" v-if='edit'>
							<el-radio-group v-model="pushData.enabled">
								<el-radio label="true">启用</el-radio>
								<el-radio label="false">禁用</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="分类头图/视频">
							<el-upload class="uploadLists" name='files' :data="uploadData" :headers="importHeaders" ref="upload" :action="uploadUrl" :file-list="fileListData" multiple accept="image/*,video/*" list-type="picture-card" :limit="6" :on-preview="handlePictureCardPreview" :on-exceed="handleExceed" :beforeUpload="beforeAvatarUpload" :on-success="(response, file, fileList)=>{return uploadSuccess(response, file, fileList,1)}" :on-remove="(file,fileList)=>{return handleRemove(file,fileList,1)}">
								<el-icon><Plus /></el-icon>
							</el-upload>
							<div class="tips">
								<div>头图注意：</div>
								<div>1、建议尺寸：375px*210px；</div>
								<div>2、建议格式：jpg、png、jpeg、MP4；</div>
								<div>3、建议数量：6张；</div>
							</div>
							<el-dialog v-model="dialogVisible1">
							    <img w-full :src="dialogImageUrl" alt="Preview Image" />
							</el-dialog>
						</el-form-item>
						<el-form-item label="分类介绍" style="width:90%;">
							<QuillEditor ref='quillRef' v-model:content="pushData.introduction" style="width:100%" :options="editorOption" contentType="html" />
							<!-- <quill-editor v-model:content="content" :options="editorOption" contentType="html" /> -->
							<!-- <quill-editor v-model="pushData.introduction" ref="myEditor" :options="editorOption"></quill-editor> -->
						</el-form-item>
					</div>
				</el-form>
			</div>
			<div class="rightWrap">
				<div style="font-size: 18px;font-weight: bold;">项目介绍预览</div>
				<div class="phoneBg">
					<div class="previewWrap">
						<img :src="imgUrl[0]" style="width: 100%;margin-bottom: 10px;" alt="">
						<div class="previewCon" v-if="pushData.name||pushData.forPeople||pushData.effectivePeriod">
							<div style="font-size: 16px;font-weight: bold;margin-bottom: 9px;">{{pushData.name}}</div>
							<div class="flexA">
								<div>
									<span v-if="pushData.forPeople">适用人群：{{pushData.forPeople}}</span>
								</div>
								<span v-if="pushData.effectivePeriod">见效周期：{{pushData.effectivePeriod}}</span>
							</div>
						</div>
						<div class="introduction" v-if="pushData.introduction">
							<div style="font-size: 14px;font-weight: bold;margin-left: 10px;">暖心科普</div>
							<div v-html="pushData.introduction"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Validate from '@/utils/rules'
	import {goodsCategoryList,goodsCategoryCreate,goodsCategoryUpdate,goodsCategoryInfo,uploadUrl,userLogin} from '@/api'
	// 工具栏配置
	const toolbarOptions = [
	    ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
	    ["blockquote", "code-block"], // 引用
	    [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
	    [{ script: "sub" }, { script: "super" }], // 上标/下标
	    [{ indent: '-1' }, { indent: '+1' }], // 缩进
	    [{ direction: 'rtl' }], // 文本方向
	    [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
	    [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
	    [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
	    [{ font: [] }], // 字体种类
	    [{ align: [] }], // 对齐方式
	    ['clean'], // 清除文本格式
	    ['link', 'image', 'video'] // 链接、图片、视频
	]
	import {QuillEditor, Quill } from '@vueup/vue-quill'
	import { container, ImageExtend, QuillWatch } from 'quill-image-extend-module'
	import quillTool from '@/utils/quillTool'
	Quill.register(quillTool, true)
	Quill.register('modules/ImageExtend', ImageExtend)
	import 'quill/dist/quill.core.css'
	import 'quill/dist/quill.snow.css'
	import 'quill/dist/quill.bubble.css'
	export default {
		name: 'categoryAdd',
		components: {QuillEditor},
		data() {
			return {
				uploadUrl,
				importHeaders:{
					'Authorization':''
				},
				uploadData:{folder:'goods-category/'},
				fileListData:[],
				imgUrl:[],
				list:[],
				dialogImageUrl:'',
				dialogVisible1:false,
				pushData: {
					name: '',
					level: '2', //项目分类
					parentId:'',
					forPeople:'',
					effectivePeriod:'',
					enabled:'true',
					resources:[],
					introduction: '',
				},
				rules: {
					name: [{required: true, message: '请输入分类名称', trigger: 'blur'}],
					forPeople: [{required: true, message: '请输入适用人群', trigger: 'blur'}],
					effectivePeriod: [{required: true, message: '请输入见效周期', trigger: 'blur'}],
					level: [{required: true}],
					// 'serviceItemProduct.stocknum': [{required: true,message: '请输入0-999整数',validator: Validate.inventoryNum, trigger: 'blur'}],
					parentId: [{required: true, message: '请选择上级分类', trigger: 'blur'}]
				},
				loading: false,
				edit: false,
				introduction: '',
				editorOption: {
					theme: 'snow',
					placeholder: '请输入内容',
					modules: {
						ImageExtend: {
							name: 'files', // 参数名
							action: uploadUrl, // 服务器地址，如果为空则采用base64插入图片
							headers: xhr => { // 设置请求头参数（选填）
								xhr.setRequestHeader("Authorization", (this.userInfo.token?('Bearer'+' '+ this.userInfo.token):''))
							},
							response: res => {
								if(res.code=="A0200"||res.code=="A0201"){// 登录过期，重新登录
									this.$store.commit("updateUserInfo", '');
									this.$router.push("/")
									return
								}
								if(res.code=="A0202"){
									this.userLogin()
									return
								}
								return res.data[0]
							},
							size: 8, // 图片不能超过8M
							sizeError: () => {
								this.$message.error('粘贴图片大小不能超过8MB!')
							}
						},
						toolbar: {
							container: toolbarOptions,
							handlers: {
								image: function(value) {
									QuillWatch.emit(this.quill.id)
								},
								link: function(value) {
									if (value) {
										var href = prompt('请输入链接地址：')
										this.quill.format('link', href)
									} else {
										this.quill.format('link', false)
									}
								},
								video: function(value) {
									if (value) {
										var href = prompt('请输入视频链接：')
										this.quill.format('video', href)
									} else {
										this.quill.format('video', false)
									}
								}
							}
						}
					}
				}
			}
		},
		computed: {
		    userInfo() {
				return this.$store.state.userInfo;
		    },
		},
		watch:{
			userInfo: {
				handler(nval, oval) {
					// console.log(nval, oval);//nval改变后的新数据，oval改变前的旧数据
				},
				deep: false, // 深度监听
				immediate: true,//立即执行
			}
		},
		created() {
			this.getDate()
			this.importHeaders.Authorization = this.userInfo.token?('Bearer'+' '+ this.userInfo.token):''
			// 如果是编辑操作则渲染编辑数据
			if (this.$route.params.id) {
				this.edit = true
				// this.loading = true	
				this.goodsCategoryInfo()
			}
		},
		methods: {
			goodsCategoryInfo(){
				let postData = {id:this.$route.params.id}
				goodsCategoryInfo({data:postData}).then(res => {
					if(res.success){
						let data = res.data
						this.$nextTick(() => {
							this.$refs.quillRef.setContents(data.introduction)
						})
						data.level = data.level.toString()
						data.enabled =  data.enabled.toString() 
						if(data.resources&&data.resources.length>0){
							data.resources.forEach(ies =>{
								this.fileListData.push({url: ies.resource});
								this.imgUrl.push(ies.resource)
							});
						}
						delete data.resources
						this.pushData = data
					}
				})
			},
			getDate(){
				let postData = {parentId:0,level:1}
				goodsCategoryList({data:postData}).then(res => {
					if(res.success){
						let arr = []
						let data = res.data
						if(data && data.length>0){
							data.forEach(item=>{
								item.parentId = item.id
								arr.push(item)
							})
						}
						this.list = arr
					}
				})
			},
			// 确认提交
			submit() {
				this.$refs.formBox.validate((valid) => {
					if (valid) {
						if(this.imgUrl.length==0){
							this.$message.error('至少上传一张图片')
							return
						}
						this.loading = true
						this.pushData.resources = this.imgUrl
						// let postData = Object.assign({}, this.pushData)
						if(this.pushData.level==1){
							this.pushData.parentId = 0
							this.pushData.forPeople = ''
							this.pushData.effectivePeriod = ''
						}
						if(this.edit){
							delete this.pushData.createdTime
						}
						let url = this.edit ? goodsCategoryUpdate : goodsCategoryCreate
						url(this.pushData).then(res=>{
							if(res.success){
								this.$router.replace('/categoryList')
							}else{
								this.loading = false
								this.$message.error(res.msg)
							}
						})
						
					}
				})
			},
			HTMLDecode(html) {
				return html.replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/&amp;/g, "&").replace(/&quot;/g, '"').replace(/&apos;/g, "'")
			},
			handlePictureCardPreview(file, fileList,types){
				this.imgUrl.forEach((ies,index) =>{
					if(file.response){
						if(ies==file.response.data[0]){
							this.dialogImageUrl = ies
							this.dialogVisible1 = true
						}
						return
					}
					if(ies==file.url){
						this.dialogImageUrl = ies
						this.dialogVisible1 = true
					}
				});
			},
			handleRemove(file, fileList,types) {
				this.fileListData.forEach((ies,index) =>{
					if(ies.url){
						if(ies.url==file.url || ies.url==file.response.data[0]){
							this.fileListData.splice(index,1)
						}
					}else{
						if(ies==file.url || ies==file.response.data[0]){
							this.fileListData.splice(index,1)
						}
					}
				});
				this.imgUrl.forEach((ies,index) =>{
					if(ies==file.url || ies==file.response.data[0]){
						this.imgUrl.splice(index,1)
					}
				});
			},
			uploadSuccess(response, file, fileList,types) {
				let uid = file.uid;
				let idx = fileList.findIndex(item => item.uid === uid)
				if(response.code.indexOf("A02")!=-1){
					fileList.splice(idx, 1)
				}
				if(response.code=="A0200"||response.code=="A0201"){// 登录过期，重新登录
					this.$store.commit("updateUserInfo", '');
					this.$router.push("/")
					return
				}
				if(response.code=="A0202"){
					this.userLogin()
					return
				}
				this.fileListData.push(response.data[0])
				this.imgUrl.push(response.data[0])
			},
			userLogin(){
				let userInfo = this.userInfo
				let postData = {
					"grantType": "PLATFORM_REFRESH_TOKEN",
					refreshTokenTokenRequest:{
						refreshToken: this.userInfo.refreshToken
					}
				}
				userLogin(postData).then(res1=>{
					userInfo.token = res1.data.accessToken;
					userInfo.refreshToken = res1.data.refreshToken;
					this.$store.commit("updateUserInfo", userInfo);
					this.importHeaders.Authorization = userInfo.token?('Bearer'+' '+ userInfo.token):''
				})
			},
			handleExceed(files, fileList) {
				this.$message.warning("当前限制选择 6 个文件");
			},
			beforeAvatarUpload(file) {
				const extension = file.name.endsWith(".jpg")||file.name.endsWith(".JPG");
				const extension1 = file.name.endsWith(".png")||file.name.endsWith(".PNG");
				const extension2 = file.name.endsWith(".jpeg")||file.name.endsWith(".JPEG");
				const extension3 = file.name.endsWith(".mp4")||file.name.endsWith(".MP4");
				const extension4 = file.name.endsWith(".avi")||file.name.endsWith(".AVI");
				const extension5 = file.name.endsWith(".rmvb")
				const extension6 = file.name.endsWith(".3gp")||file.name.endsWith(".3GP");
				const extension7 = file.name.endsWith(".mov")||file.name.endsWith(".MOV");
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!extension && !extension1 && !extension2 && !extension3 && !extension4 && !extension5 && !extension6 && !extension7) {
					this.$message({
						message: "上传模板只能是 .jpg,.png,.jpeg,.mp4,.avi,.rmvb,.3gp,.mov 格式!",
						type: "error"
					});
				}
				// if (!isLt2M) {
				// 	this.$message.error("上传图片大小不能超过 2M!");
				// }
				// return (extension || extension1 || extension2 || extension3) && isLt2M;
				return extension || extension1 || extension2 || extension3 || extension4 || extension5 || extension6 || extension7;
			},
		}
	}
</script>

<style rel="stylesheet/scss" lang='scss' scoped>
	.box {
		padding: 20px;
		margin-bottom: 20px;
		/* border: 1px solid #f0f0f0; */
		background: #fff;
		.subTitle {
			/* background: #ebedf0; */
			padding: 10px 15px;
			margin-bottom: 20px;
			font-weight: bold;
			font-size: 16px;
			color: #3f5b84;

			i {
				margin-right: 10px;
			}
		}
	}
	.flexB{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.productListAdd-container {
		.formBox {
			.el-input,
			.w300 {
				width: 300px;
				margin-right: 10px;
			}
			.mb10 {
				margin-bottom: 10px;
			}
			.t-center {
				text-align: center;
			}
		}
	}
	.flexA{
		display: flex;
		align-items:flex-start;
		justify-content: space-between;
	}
	.leftWrap{
		width: calc(100% - 400px);
	}
	.rightWrap{
		width:350px;
		margin-top: 80px;
		/* height: 100vh; */
		.phoneBg{
			width:350px;
			height: 700px;
			background: #f2f2f2;
			border-radius: 20px;
			padding:60px 10px;
			box-sizing: border-box;
			margin-top: 15px;
			.previewWrap{
				width: 100%;	
				height: 100%;
				background:#F5F5F8;
				border-radius: 20px;
				overflow-y: scroll;
				overflow-x: hidden;
			}
			.previewCon{
				padding: 10px;
				box-sizing: border-box;
				font-size: 12px;
				background: #fff;
				border-radius: 5px;
				margin-bottom: 10px;
			}
			.introduction{
				font-size: 12px;
				background: #fff;
				border-radius: 5px;
				padding-top: 10px;
			}
			/* background: url('../../assets/images/phoneBg.png') no-repeat;
			background-size: 100% 100%; */
		}
		/* img{
			width: 100%;
		} */
	}
	.avatar-uploader .avatar {
	  width: 100px;
	  height: 100px;
	  display: inline-block;
	  margin-right: 10px;
	}
	.avatar-uploader .el-upload {
	  border: 1px dashed var(--el-border-color);
	  border-radius: 6px;
	  cursor: pointer;
	  position: relative;
	  overflow: hidden;
	  transition: var(--el-transition-duration-fast);
	}
	
	.avatar-uploader .el-upload:hover {
	  border-color: var(--el-color-primary);
	}
	
	.el-icon.avatar-uploader-icon {
	  font-size: 28px;
	  color: #8c939d;
	  width: 100px;
	  height: 100px;
	  text-align: center;
	  border: 2px dashed #f2f2f2;
	}
	.tips{
		font-size: 13px;
		color: #999;
		display: inline-block;
		margin-left: 10px;
		line-height: 20px;
	}
	.ql-container {
		height: 300px;
		line-height: normal;
		width: 100%;
	}
 
	span.ql-size {
		max-width: 80px !important;
	}
 
	.ql-tooltip[data-mode="link"]::before {
		content: "请输入链接地址:";
	}
 
	.ql-tooltip.ql-editing a.ql-action::after {
		border-right: 0px;
		content: "保存";
		padding-right: 0px;
	}
 
	.ql-tooltip[data-mode="video"] {
		left: 0 !important;
	}
 
	.ql-tooltip[data-mode="video"]::before {
		content: "请输入视频地址:";
	}
 
	.ql-picker.ql-size .ql-picker-label::before,
	.ql-picker.ql-size .ql-picker-item::before {
		content: "14px";
	}
 
	.ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
	.ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
		content: "10px";
	}
 
	.ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
	.ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
		content: "18px";
	}
 
	.ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
	.ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
		content: "32px";
	}
 
	.ql-picker.ql-header .ql-picker-label::before,
	.ql-picker.ql-header .ql-picker-item::before {
		content: "文本";
	}
 
	.ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
	.ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
		content: "标题1";
	}
 
	.ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
	.ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
		content: "标题2";
	}
 
	.ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
	.ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
		content: "标题3";
	}
 
	.ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
	.ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
		content: "标题4";
	}
 
	.ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
	.ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
		content: "标题5";
	}
 
	.ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
	.ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
		content: "标题6";
	}
 
	.ql-picker.ql-font .ql-picker-label::before,
	.ql-picker.ql-font .ql-picker-item::before {
		content: "标准字体";
	}
 
	.ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
	.ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
		content: "衬线字体";
	}
 
	.ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
	.ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
		content: "等宽字体";
	}
</style>
<style lang='scss'>
	img{
		display: block;
		width: 100%;
	}
</style>
