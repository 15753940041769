<template>
	<div>
		<h3 class="marginB24">客资明细</h3>
		<el-button class="marginB24" type="primary" color='#112DF2' @click="add">+ 新增客资明细</el-button>
		<div class="flexB">
			<div class="flexA marginR10">
				<el-date-picker class="marginR10" v-model="timeInterval" @change="search()" type="daterange" value-format="YYYY-MM-DD" start-placeholder="开始日期"
					end-placeholder="结束日期" />
				<el-select v-model="pushData.belongMerchantId" style="width: 260px;" @change="search()" placeholder="商户" clearable class="marginR10">
					<el-option style="max-width: 260px;" v-for="item in merchantList" :key="item.id"
						:label='item.name' :value="item.id"></el-option>
				</el-select>
				<el-input placeholder="搜索会员名称手机号" v-model="pushData.fuzzySearch" @keyup.enter="search()" style="width: 200px;">
					<template #suffix>
						<el-icon class="el-input__icon">
							<component :is="Search" class="enter-sj" @click="search()"></component>
						</el-icon>
					</template>
				</el-input>
			</div>
			<div>
			<!-- <div style="padding: 6px 10px;border: 1px solid gainsboro;border-radius: 6px;"> -->
				<el-dropdown>
					<el-button type="primary" plain color="#112DF2">
					    更多操作<el-icon class="el-icon--right"><arrow-down /></el-icon>
					</el-button>
				    <!-- <span class="el-dropdown-link">
				      更多操作<el-icon class="el-icon--right"><arrow-down /></el-icon>
				    </span> -->
				    <template #dropdown>
				      <el-dropdown-menu>
				        <el-dropdown-item @click.native="importData">批量导出</el-dropdown-item>
				      </el-dropdown-menu>
				    </template>
				  </el-dropdown>
			</div>
		</div>
		<el-table :data="tableData" :empty-text="emptyText" style="width: 100%;margin-top: 30px;">
			<el-table-column prop="belongMerchantName" label="会员所属商户" />
		    <el-table-column prop="customerName" label="会员名称">
				<template #default="{row}">
					<div v-if="!row.isEditName" class="flexAR">{{row.customerName?row.customerName:0}}
						<el-icon @click="editItem(row.id,row.customerName,1,'name')" :size="18" style="margin-left: 6px;"><Edit /></el-icon>
					</div>
					<div v-if="row.isEditName">
						<el-input style="width: 60%;" v-model="row.customerNameUp"></el-input>
						<el-button link type="primary" plain color="#112DF2" @click="editItem(row.id,row.customerName,3,'name')">取消</el-button>
						<el-button link type="primary" plain color="#112DF2" @click="editItem(row.id,row.customerNameUp,2,'name')">保存</el-button>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="customerPhoneNumber" label="联系方式">
				<template #default="{row}">
					<div v-if="!row.isEditTel" class="flexAR">{{row.customerPhoneNumber?row.customerPhoneNumber:0}}
						<el-icon @click="editItem(row.id,row.customerPhoneNumber,1,'tel')" :size="18" style="margin-left: 6px;"><Edit /></el-icon>
					</div>
					<div v-if="row.isEditTel">
						<el-input style="width: 60%;" v-model="row.customerPhoneNumberUp"></el-input>
						<el-button link type="primary" plain color="#112DF2" @click="editItem(row.id,row.customerPhoneNumber,3,'tel')">取消</el-button>
						<el-button link type="primary" plain color="#112DF2" @click="editItem(row.id,row.customerPhoneNumberUp,2,'tel')">保存</el-button>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="undertakingMerchantGuestChargeAmount" label="客资费">
				<template #default="scope">
					<div v-if="!scope.row.isEditAmount" class="flexAR">{{scope.row.undertakingMerchantGuestChargeAmount?scope.row.undertakingMerchantGuestChargeAmount:0}}
						<el-icon @click="editItem(scope.row.id,scope.row.undertakingMerchantGuestChargeAmount,1,'amount')" :size="18" style="margin-left: 6px;"><Edit /></el-icon>
					</div>
					<div v-if="scope.row.isEditAmount">
						<el-input style="width: 60%;" v-model="scope.row.undertakingMerchantGuestChargeAmountUp" @keyup="scope.row.undertakingMerchantGuestChargeAmountUp=scope.row.undertakingMerchantGuestChargeAmountUp.replace(/[^\d\.]/g,'')"
						 @input="groupPrice(scope.$index,scope.row.undertakingMerchantGuestChargeAmountUp)" @blur="groupPriceBlur(scope.$index,scope.row.undertakingMerchantGuestChargeAmountUp)"></el-input>
						<el-button link type="primary" plain color="#112DF2" @click="editItem(scope.row.id,scope.row.undertakingMerchantGuestChargeAmount,3,'amount')">取消</el-button>
						<el-button link type="primary" plain color="#112DF2" @click="editItem(scope.row.id,scope.row.undertakingMerchantGuestChargeAmountUp,2,'amount')">保存</el-button>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="updatedTime" label="客资费确认日期">
				<template v-slot:header="scope">
					<span>客资费确认日期
						<el-tooltip effect="dark" content="根据客资费显示实时日期" placement="top">
							<el-icon><QuestionFilled /></el-icon>
						</el-tooltip>
					</span>
				</template>
			</el-table-column>
			<el-table-column prop="belongMerchantGuestChargeRatio" label="客资推广比例" />
			<el-table-column prop="belongMerchantGuestChargeAmount" label="客资收入" />
		</el-table>
		<div class="relativeB">
			<div class='absoluteB'>
			  <el-dropdown placement='top'>
			    <el-button type="warning" size='mini'>
			      统计数据<el-icon class="el-icon--right"><arrow-down /></el-icon>
			    </el-button>
				<template #dropdown>
				  <el-dropdown-menu>
				    <el-dropdown-item v-for="(item, index) in countData" :key="index">{{item.label}}: {{item.value || 0}}</el-dropdown-item>
				  </el-dropdown-menu>
				</template>
			  </el-dropdown>
			</div>
			<div class="paginations">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next" :total="total">
				</el-pagination>
			</div>
		</div>
		<el-dialog title="新增客资明细" width="800" :model-value="dialogVisible">
			<el-form :model="dataForm" ref="formBox" :rules="rules" label-width="180px" class="formBox">
				<el-form-item label="会员所属商户" prop="belongMerchantId">
					<el-select v-model="dataForm.belongMerchantId" style="width: 500px;" placeholder="会员所属商户" clearable class="marginR10">
						<el-option style="max-width: 500px;" v-for="item in merchantList" :key="item.id"
							:label='item.name' :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="会员名称" prop="customerName">
					<el-input v-model="dataForm.customerName" style="max-width: 500px;"></el-input>
				</el-form-item>
				<el-form-item label="联系方式" prop="customerPhoneNumber">
					<el-input v-model="dataForm.customerPhoneNumber" style="max-width: 500px;"></el-input>
				</el-form-item>
				<el-form-item label="客资费" prop="undertakingMerchantGuestChargeAmount">
					<el-input v-model="dataForm.undertakingMerchantGuestChargeAmount" style="max-width: 500px;"></el-input>
				</el-form-item>
			</el-form>
			<div class="flexAR">
				<el-button type="primary" color="#787878" @click="() => dialogVisible=false">取消</el-button>
				<el-button class="marginR10" type="primary" color="#112DF2" @click="save">保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Validate from '@/utils/rules'
	import {guestParticularPageList,guestParticularUpdateCustomerNameById,guestParticularUpdateCustomerPhoneNumberById,guestParticularUpdateUndertakingMerchantGuestRechargeAmountById,guestParticularExport,guestParticularCreat,merchantList} from '@/api'
	import {formatDateOrder,dateFormat} from '@/utils/data'
	import { Search } from "@element-plus/icons-vue";
	export default {
		data () {
			return {
				Search,
				dataForm:{
					belongMerchantId:'',
					customerName:'',
					customerPhoneNumber:'',
					undertakingMerchantGuestChargeAmount:''
				},
				dialogVisible:false,
				pushData:{
					fuzzySearch:'',
					beginTime:null,
					endTime:null,
					belongMerchantId:''
				},
				countData:[{
					label:'客资收入',
					value:0
				}],
				timeInterval:[],
				tableData:[],
				merchantList:[],
				pageSize: 10,	
				pageNum: 1,
				total:0,
				emptyText:'暂无数据',
				rules:{
					belongMerchantId: [{required: true,message: '请选择会员所属商户',trigger: 'blur'}],
					customerName: [{required: true,message: '请输入会员名称', trigger: 'blur'}],
					customerPhoneNumber: [{required: true,message: '请正确输入联系方式',validator: Validate.isTel, trigger: 'blur'}],
					undertakingMerchantGuestChargeAmount: [{required: true,meCssage: '请正确输入客资费',validator: Validate.isNumber, trigger: 'blur'}],
				}
			}
		},
		computed:{
			// 计算出本月第一天和最后一天
			countDate () {
			  let end = new Date()
			  let start = new Date()
			  start = new Date(start.getFullYear(), start.getMonth(), 1)
			  return [
			    dateFormat(start, 'YYYY-MM-DD'),
			    dateFormat(end, 'YYYY-MM-DD')
			  ]
			}
		},
		created() {
			this.timeInterval = this.countDate
			this.search()
			this.getMerchantList()
		},	
		methods:{
			save(){
				this.$refs.formBox.validate((valid) => {
					if (valid) {
						guestParticularCreat(this.dataForm).then(res=>{
							if(res&&res.success){
								this.$message({
									showClose: true,
									message: "成功",
									type: "success",
								});
								this.dialogVisible = false
								this.search()
							}else{
								this.$message.error(res.msg)
							}
						})
					}
				})
			},
			search(){
				if(this.timeInterval&&this.timeInterval.length>0){
					this.pushData.beginTime = new Date(this.timeInterval[0]+' '+'00:00:00').getTime()/1000
					this.pushData.endTime = new Date(this.timeInterval[1]+' '+'23:59:59').getTime()/1000
				}else{
					this.pushData.beginTime = null
					this.pushData.endTime = null
				}
				let postData = {
					 "paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					}
				}
				postData = Object.assign(postData, this.pushData)
				guestParticularPageList(postData).then(res => {
					if(res&&res.success){
						let list = res.data.pagination.list
						list.forEach(item=>{
							item.updatedTime = formatDateOrder(item.updatedTime)
							item.isEditName = false
							item.isEditTel = false
							item.isEditAmount = false
							item.customerNameUp = ''
							item.customerPhoneNumberUp = ''
							item.undertakingMerchantGuestChargeAmountUp = ''
							item.belongMerchantGuestChargeRatio = item.belongMerchantGuestChargeRatio*100 + '%'
						})
						this.tableData = list
						this.total = Number(res.data.pagination.total)
						this.countData[0].value = res.data.belongMerchantIncome
					}
				})
			},
			add(){
				this.dialogVisible = true
			},
			getMerchantList(){
				let postData = {enabled:true}
				merchantList(postData).then(res => {
					if(res&&res.success){
						this.merchantList = res.data
					}
				})
			},
			editItem(id,inputValue,num,type){
				this.tableData.forEach(item=>{
					if(type == 'tel'&&(num==2)){
						const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
						if (!reg.test(inputValue)) {
							this.$message.error('请输入正确的手机号码')
							return
						} 
					}
					if(item.id == id){
						if(type == 'amount'){
							item.isEditAmount = !item.isEditAmount
							item.undertakingMerchantGuestChargeAmountUp = num==1?inputValue:''
						}
						if(type == 'tel'){
							item.isEditTel = !item.isEditTel
							item.customerPhoneNumberUp = num==1?inputValue:''
						}
						if(type == 'name'){
							item.isEditName = !item.isEditName
							item.customerNameUp = num==1?inputValue:''
						}
					}
				})
				if(num==2){
					let postUrl = guestParticularUpdateCustomerNameById
					let body = {
						id,customerName:inputValue
					}
					if(type == 'tel'){
						postUrl = guestParticularUpdateCustomerPhoneNumberById
						body = {
							id,customerPhoneNumber:inputValue
						}
					}
					if(type == 'amount'){
						postUrl = guestParticularUpdateUndertakingMerchantGuestRechargeAmountById
						body = {
							id,undertakingMerchantGuestChargeAmount:inputValue
						}
					}
					postUrl({data:body}).then(res=>{
						if(res&&res.success){
							if(type == 'amount'){
								this.search()
								return
							}
							this.tableData.forEach(item=>{
								if(item.id == id){
									if(type == 'tel'){
										item.customerPhoneNumber = inputValue
									}
									if(type == 'name'){
										item.customerName = inputValue
									}
								}
							})
						}
					})
				}
			},
			groupPrice(index,val){
				if(val>99999.99)val=99999.99;
				if(val<0)val=0;
				this.tableData[index].undertakingMerchantGuestChargeAmount = val
			},
			groupPriceBlur(index,val){
				val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')
				this.tableData[index].undertakingMerchantGuestChargeAmount = val
			},
			importData(){//导出
				let postData = {}
				let posts = Object.assign(postData, this.pushData)
				this.$http.post(guestParticularExport, posts, {
					headers:{
						'Authorization': this.$store.state.userInfo.token?('Bearer'+' '+ this.$store.state.userInfo.token):''
					},
					responseType: "blob"
				}).then(res => {
					const blob = new Blob([res.data], {type: 'application/vnd.ms-excel'});
					const fileName = '客资明细列表.xlsx';
					if ('download' in document.createElement('a')) {
						const elink = document.createElement('a');
						elink.download = fileName;
						elink.style.display = 'none';
						elink.href = URL.createObjectURL(blob);
						document.body.appendChild(elink);
						elink.click();
						URL.revokeObjectURL(elink.href);
						document.body.removeChild(elink);
					}else{
						navigator.msSaveBlob(blob, fileName);
					}
				});
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.search()
			},
			handleCurrentChange(val) {
				this.pageNum = val;
				this.search()
			},
		}
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.cardCon{
		max-width: 90%;
		margin-bottom: 10px;
	}
	// :deep(.el-select .el-input__wrapper){
	// 	width: 200px;
	// }
	// :deep(.el-icon svg){
	// 	color: #112DF2;
	// 	width: 12px;
	// 	height: 12px;
	// }
	// .el-select-dropdown__item{
	//   padding: 0 20px;
	//   box-sizing: border-box;
	// }
</style>