<template>
	<div class="el-dialog-body-spacing">
		<el-dialog title="选择产品" :model-value="dialogVisible" width="1100px" :before-close="hideDialog"
			:close-on-click-modal='false' append-to-body>
			<div style="position: relative">
				<retail-goods :isShow='true' :selection="seleArr" :is-radio='isRadio' :is-change='isChange'
					:option='option'></retail-goods>
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="hideDialog">取 消</el-button>
					<el-button color='#112DF2' type="primary" @click="confirm">确 定</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>
<script>
	import retailGoods from '@/views/goodsManagement/retailGoods'
	export default {
		components: {
			retailGoods
		},
		props: {
			close: {
				type: Function,
				default: function() {}
			},
			visiFlag: {
				type: Function,
				default: function() {}
			},
			selList: {
				type: Array
			},
			dialogVisible: {
				type: Boolean,
				default: false
			},
			option: {
				type: Object,
				default: function() {
					return {}
				}
			},
			isRadio: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				seleArr: [],
				isChange: false
			}
		},
		watch: {
			dialogVisible(v) {
				if (v) {
					this.seleArr = this.selList
					this.$nextTick(() => {
						this.isChange = !this.isChange
					})
				}
			}
		},
		methods: {
			confirm() {
				// this.selList = this.seleArr
				this.hideDialog()
				this.close(this.seleArr)
			},
			hideDialog() {
				this.visiFlag(false,1)
				// this.$emit('update:dialogVisible', false)
			}
		}
	}
</script>
<style>
	.el-dialog__body{
		overflow: hidden;
		overflow-y:auto;
	}
</style>
