<template>
	<div class="mainWrap">
		<div class="flexB">
			<el-radio-group v-model="type" @change="search" label="size control" fill='#112DF2' size="default">
				<el-radio-button label="BANNER">广告位</el-radio-button>
			    <el-radio-button label="DIAMOND">金刚区</el-radio-button>
			    <el-radio-button label="CAROUSEL">轮播图</el-radio-button>
			</el-radio-group>
			<div class="flexA">
				<div class='marginR10 flexA'>
					<el-select v-model="enabled" @change="search" placeholder="是否可用" class="marginR10">
						<el-option v-for="item in statusList" :key="item.status"
							:label='item.name' :value="item.status"></el-option>
					</el-select>
					<el-select v-model="jumpType" @change="search" placeholder="跳转方式" class="marginR10">
						<el-option v-for="item in list" :key="item.id"
							:label='item.name' :value="item.id"></el-option>
					</el-select>
					<el-input placeholder="名称" v-model="name" @keyup.enter="search">
						<template #suffix>
							<!--   vue3图标使用方式  -->
							<el-icon class="el-input__icon">
								<component :is="Search" class="enter-sj" @click="search"></component>
							</el-icon>
						</template>
					</el-input>
				</div>	
				<el-button type="primary" color='#112DF2' @click="add">新增</el-button>
			</div>
		</div>
		<el-table :data="tableData" :empty-text="emptyText" style="width: 100%;margin-top: 30px;">
			<el-table-column prop="picUrl" label="图标">
				<template #default="{row}">
					<el-image class="picUrl" :src="row.resources?row.resources[0]:''" @click="imgPreview(row.resources[0])">
						<template #error>
						  <div class="image-slot">
							<el-icon style='height:80px;'><PictureFilled /></el-icon>
						  </div>
						</template>
					</el-image>
					<!-- <img v-if="row.picUrl" class="img80 picUrl" :src="row.picUrl" alt=""> -->
				</template>
			</el-table-column>
			<el-table-column prop="name" label="名称"></el-table-column>
			<el-table-column prop="jumpType" label="跳转类型">
				<template #default="{row}">
					<div :class="row.jumpType=='NONE'?'':'waysStyle'">{{row.jumpType=='NONE'?'无跳转':row.jumpType=='INTERNAl'?'内页跳转':row.jumpType=='WEGPAGE'?'网页':'类目'}}</div>
				</template>
			</el-table-column>
			<el-table-column prop="serial" label="排序"></el-table-column>
			<el-table-column prop="enabled" label="是否可用">
				<template #default="{row}">
					<el-switch
						@change="changeEnabledById(row.enabled,row.id)"
						v-model="row.enabled"
						style="--el-switch-on-color:#A2A5B8; --el-switch-off-color:#112DF2"
						:active-value="0" :inactive-value="1" />
				</template>
			</el-table-column>
			<el-table-column label="操作">
				<template #default="{row}">
					<el-button link type="primary" plain color="#112DF2" @click="editItem(row.id)">编辑</el-button>	
					<el-button link type="primary" plain color="#112DF2" @click="delItem(row.id)">删除</el-button>	
				</template>
			</el-table-column>
		</el-table>
		<el-dialog v-model="dialogVisible">
			<img w-full :src="dialogImageUrl" alt="Preview Image" />
		</el-dialog>
		<div class="paginations">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next" :total="total"></el-pagination>
		</div>
	</div>
</template>

<script>
	import { Search } from "@element-plus/icons-vue";
	import {homepageConfigPageList,homepageConfigDeleteById,homepageConfigUpdateEnabledById} from '@/api'
	export default {
		data (){
			return{
				Search,
				dialogImageUrl:'',
				dialogVisible:false,
				type:'BANNER',
				name:'',
				enabled:'all',
				jumpType:'all',
				statusList:[
					{name:'全部',status:'all'},
					{name:'可用',status:'true'},
					{name:'不可用',status:'false'},
				],
				list:[
					{name:'全部',id:'all'},
					{name:'无跳转',id:'NONE'},
					{name:'内页跳转',id:'INTERNAl'},
					{name:'网页',id:'WEBPAGE'},
					{name:'类目',id:'CATEGORY'},
				],
				pageSize: 10,
				pageNum: 1,
				total:0,
				tableData:[],
				emptyText:'暂无数据'
			}
		},
		created() {
			this.search()
		},	
		methods:{
			imgPreview(img){
				if(this.type!='CAROUSEL'){
					return
				}
				this.dialogVisible = true
				this.dialogImageUrl = img
			},
			search(){
				let postData = {
					 "paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					},
					name:this.name,
					type:this.type
				}
				if(this.jumpType!='all'){
					postData.jumpType = this.jumpType
				}
				if(this.enabled!='all'){
					postData.enabled = this.enabled
				}
				homepageConfigPageList(postData).then(res=>{
					if(res.success){
						let list = res.data.list
						list.forEach(item=>{
							item.enabled = item.enabled ? 1 : 0
						})
						this.tableData = list
						this.total = Number(res.data.total)
					}
				})
			},
			changeEnabledById(val,id){
				let data = {
					id,
					enabled:val==1?true:false
				}
				homepageConfigUpdateEnabledById({data}).then(res=>{
					if(res.success){
						this.$message.success(res.msg)
						this.search()
						return
					}
					this.$message.error(res.msg)
				})
			},
			delItem(id){
				this.$messageBox.confirm('此操作将永久删除该项数据，是否确定删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				}).then(()=>{
					homepageConfigDeleteById({data:{id}}).then(res=>{
						if(res.success){
							this.$message.success('删除成功！')
							this.search()
							return
						}
						this.$message.error(res.msg)
					})
				}).catch(()=>{})	
			},
			editItem(id){
				this.$router.push('/active/diamondRegionList/diamondRegionAdd/'+this.type+'/'+id)
			},
			add(){
				this.$router.push('/active/diamondRegionList/diamondRegionAdd/'+this.type)	
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.search()
			},
			handleCurrentChange(val) {
				this.pageNum = val;
				this.search()
			}
		}
	}
</script>

<style lang="scss" scoped>
	.flexA{
		display: flex;
		align-items: center;
	}
	.flexB{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.mainWrap{
		width:94%;
		margin:20px auto;
	}
	.paginations{
		margin-top: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.waysStyle{
		display: inline-block;
		color: #112DF2;
		padding: 2px 20px;
		box-sizing: border-box;
		border-radius: 32px;
		background-color: #E5E8FD;
	}
	.picUrl{
		margin: 0 auto;
	}
</style>