<template>
	<div class="el-dialog-body-spacing">
		<el-dialog title="商品列表" :model-value="dialogVisible" width="1200px" :before-close="hideDialog"
			:close-on-click-modal='false' append-to-body>
			<div style="position: relative">
				<div class="flexB">
					<el-radio-group v-model="pushData.type" @change="search(pushData.type)" label="size control" size="default">
						<el-radio-button label="1">零售产品</el-radio-button>
					    <el-radio-button label="2">产品服务</el-radio-button>
					    <el-radio-button label="3">手法服务</el-radio-button>
						<el-radio-button label="4">时卡</el-radio-button>
						<el-radio-button label="5">次卡</el-radio-button>
						<el-radio-button label="6">体验卡</el-radio-button>
					</el-radio-group>
					<el-input placeholder="搜索" v-model="pushData.fuzzySearch" @keyup.enter="search(pushData.type)" style="width: 300px;">
						<template #suffix>
							<el-icon class="el-input__icon">
								<component :is="Search" class="enter-sj" @click="search(pushData.type)"></component>
							</el-icon>
						</template>
					</el-input>
				</div>
				<el-table :data="tableData" :empty-text="emptyText" ref='tableBox' :row-key="getRowKeys" @row-click='clickRow'
					@selection-change="selectList" style="width: 100%;margin-top: 10px;">
					<el-table-column type="selection" width="50" :selectable="selectable" v-if="isShow"
					:reserve-selection="true"></el-table-column>
				    <el-table-column prop="name" label="商品信息" width="370">
						<template #default="{row}">
							<div>{{row.name}}</div>
						</template>
					</el-table-column>
					<el-table-column prop="typeStr" label="类别" width="370" />
					<el-table-column prop="salePrice" label="售价" width="370" />
				</el-table>
				<div class="paginations">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next" :total="total"></el-pagination>
				</div>
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="hideDialog">取 消</el-button>	
					<el-button color='#112DF2' type="primary" @click="confirm">确 定</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>
<script>
	import { Search } from "@element-plus/icons-vue";
	import {formatDate,typeStr} from '@/utils/data'
	import {goodsPageList,cardPageList} from '@/api'
	export default {
		props: {
			close: {
				type: Function,
				default: function() {}
			},
			visiFlag: {
				type: Function,
				default: function() {}
			},
			isShow: {
				type: Boolean,
				default: false
			},
			dialogVisible: {
				type: Boolean,
				default: false
			},
			selList: {
				type: Array
			},
		},
		data() {	
			return {
				Search,
				pushData:{
					enabled:true,
					saleable:true,
					fuzzySearch:'',
					type:1
				},
				emptyText:'暂无数据',
				tableData:[],
				pageSize: 5,	
				pageNum: 1,
				total:0,
				seleArr: [],
				isChange: false
			}
		},
		watch: {
			dialogVisible(v) {
				if (v) {
					this.seleArr = this.selList
					this.$nextTick(() => {
						this.isChange = !this.isChange
					})
				}
			},
			isChange() {
				this.isChangeFun()
			}
		},
		methods: {
			search(type){
				let postData = {
					 "paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					},
				}
				postData = Object.assign(postData, this.pushData)
				let url = type>3?cardPageList:goodsPageList
				if(type>3){
					postData.type = (type - 3)
				}
				url(postData).then(res => {
					if(res&&res.success){
						let list = res.data.list
						list.forEach(item=>{
							item.typeStr = typeStr(this.pushData.type)
							item.activeGoodsType = type
						})
						this.tableData = list
						this.total = Number(res.data.total)
						this.isChangeFun()
					}
				})
			},
			// 选择
			selectList(val) {
				if (!this.clearSelector) {
					if (this.isRadio) {
						// 单选
						const rows = val[val.length - 1]
						if (rows) {
							this.selList.splice(0, 1, rows)
						} else {
							this.selList.splice(0, 1)
						}
						this.isChangeFun()
					} else {
						let s = this.selList
						s.splice(0, s.length)
						for (let i = 0; i < val.length; i++) {
							s.splice(i, 0, val[i])
						}
					}
				}
			},
			selectable(row) {
				if (!this.isShow) {
					if (row.state == 1) {
						if (this.option.smallstate === 1) {
							if (row.smallstate === 1) {
								return true
							} else {
								return false
							}
						} else {
							return true
						}
					} else {
						return false
					}
				} else {
					return true
				}
			},
			isChangeFun() {
				this.$nextTick(() => {
					let arr = []
					let backArr = []
					this.clearSelector = true
					this.$refs.tableBox.clearSelection()
					for (let i = 0; i < this.selList.length; i++) {
						for (let j = 0; j < this.tableData.length; j++) {
							if (this.selList[i].id == this.tableData[j].id) {
								arr.push(this.tableData[j])
								break
							}
							if (j == this.tableData.length - 1) {
								backArr.push(this.selList[i])
							}
						}
					}
					arr.forEach(row => {
						this.$refs.tableBox.toggleRowSelection(row)
					})
					backArr.forEach(row => {
						this.$refs.tableBox.toggleRowSelection(row)
					})
					this.clearSelector = false
				})
			},
			clickRow(row) {
				if (this.selectable(row)) {
					this.$refs.tableBox.toggleRowSelection(row)
				}
			},
			getRowKeys(row) {
				return row.id
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.search(this.pushData.type)
			},
			handleCurrentChange(val) {
				this.pageNum = val;
				this.search(this.pushData.type)
			},
			confirm() {
				// this.selList = this.seleArr
				this.hideDialog()
				this.close(this.seleArr, this.closebackdata)
			},
			hideDialog() {
				this.visiFlag(this.pushData.type*1,false)
			}
		}
	}
</script>
<style rel="stylesheet/scss" lang='scss' scoped>
	::v-deep .el-radio-button__original-radio:checked+.el-radio-button__inner{
		background-color:#112DF2;
		border-color:#112DF2;
		box-shadow:0;
	}
	.el-dialog__body{
		overflow: hidden;
		overflow-y:auto;
	}
</style>
