<template>
	<div class="mainWrap">
		<el-form :model="pushData" :rules="rules" ref="formBox" label-width="150px" class="formWrap marginB80">
			<h2 class="marginB24">{{edit?'编辑':'新增'}}商家</h2>
			<el-form-item label="商家名称" prop="name">
				<el-input v-model="pushData.name" maxlength="20" placeholder="输入商家名称"></el-input>
			</el-form-item>
			<el-form-item label="负责人" prop="head">
				<el-input v-model="pushData.head" maxlength="5" placeholder="输入负责人"></el-input>
			</el-form-item>
			<el-form-item label="负责人手机号" prop="headConcat">
				<el-input v-model="pushData.headConcat" maxlength="11" placeholder="输入手机号"></el-input>
			</el-form-item>
			<!-- <el-form-item label="商家角色">
				<el-input v-model="pushData.role" maxlength="20" placeholder="请输入商家角色"></el-input>
			</el-form-item> -->
			<el-form-item label="商家类目" v-if="!edit">
				<el-cascader ref="cascaderAddr" :options="options" :props="props" clearable v-model='goodsCategoryIds' @change="handleChange" />
				<!-- <el-select v-if="pushData.type==2" v-model="pushData.typeValue" placeholder="请选择类目" class="w300">
					<el-option v-for="item in list" :key="item.parentId"
						:label='item.name' :value="item.parentId"></el-option>
				</el-select> -->
				<!-- <el-input v-model="pushData.role" maxlength="20" placeholder="请输入商家角色"></el-input> -->
			</el-form-item>
			<el-form-item label="商家类目" v-if="edit">
				<div>{{goodsCategoryNames}}</div>
			</el-form-item>
			<el-form-item label="商家状态" prop="enabled">
				<el-radio-group v-model="pushData.enabled">
					<el-radio label="true">启用</el-radio>
					<el-radio label="false">不启用</el-radio>
				</el-radio-group>
			</el-form-item>
		</el-form>
		<div class="formWrap">
			<div class="footerBtn">
				<el-button type="primary" color='#112DF2' @click="submit" :loading="loading">保存{{edit?'':'新增'}}</el-button>
				<el-button link @click="() => this.$router.go(-1)">暂不保存</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import Validate from '@/utils/rules'
	import {merchantCreate,goodsCategoryTreeList,merchantInfo,merchantUpdate} from "@/api"
	export default{	
		data (){
			return{
				loading:false,
				edit: false,
				goodsCategoryNames:'',
				pushData:{
					enabled:'true',
					goodsCategoryIds:'',
					headConcat:'',
					head:''	
				},
				props:{checkStrictly: true ,multiple: true},
				options:[],
				rules: {
					name: [{required: true, message: '请输入商家名称', trigger: 'blur'}],
					head: [{required: true, message: '请输入负责人', trigger: 'blur'}],
					goodsCategoryIds: [{required: true}],
					headConcat: [{required: true,message: '请输入负责人	手机号',validator: Validate.isTel, trigger: 'blur'}],
					parentId: [{required: true, message: '商家类目', trigger: 'blur'}]
				},
			}
		},
		created() {
			this.getData()
			if(this.$route.params.id){
				this.getDetails(this.$route.params.id)
				this.edit = true
			}
		},	
		methods:{
			getData(){
				goodsCategoryTreeList({}).then(res=>{
					console.log(res.data)
					if(res.success){
						let data = res.data
						let arr = []
						if(data.length>0){
							data.forEach(item=>{
								item.label = item.name
								item.value = item.id
								if(item.children&&item.children.length>0){
									for (let i of item.children) {
										i.label = i.name
										i.value = i.id
									}
								}
								arr.push(item)
							})
						}
						this.options = arr
					}
				})
			},
			getDetails(){
				let postData = {id:this.$route.params.id}
				merchantInfo({data:postData}).then(res => {
					if(res.success){
						let data = res.data
						data.enabled =  data.enabled.toString() 
						data.goodsCategoryIds = data.goodsCategoryIds.split(',')
						this.goodsCategoryNames = data.goodsCategoryNames.join('、')
						console.log(data)
						this.pushData = data
					}
				})
			},
			handleChange(e) {
				var  thsAreaCode = this.$refs['cascaderAddr'].getCheckedNodes()    //注意2： 获取label值
				console.log(thsAreaCode); // 注意3： 最终结果是个一维数组对象label，value
				console.log(e)
				console.log(e.join(','))
				if(e.length>5){
					this.$message.error('最多可选择5个')
					// this.$refs['cascaderAddr'].checkedValue
					this.value = this.goodsCategoryIds
					return
				}
				this.goodsCategoryIds = e.join(',')
			},
			submit(){
				this.$refs.formBox.validate((valid) => {
					if (valid) {
						this.loading = true
						let pushData = {
							name:this.pushData.name,
							head:this.pushData.head,
							headConcat:this.pushData.headConcat,
							// goodsCategoryIds:this.pushData.goodsCategoryIds,
							enabled:this.pushData.enabled
						}
						if(this.edit){
							pushData.id = this.pushData.id
						}else{
							pushData.goodsCategoryIds = this.goodsCategoryIds
						}
						let url = this.edit ? merchantUpdate : merchantCreate
						url(pushData).then(res=>{
							if(res.success){
								this.$router.replace('/merchantManagement')
							}else{
								this.loading = false
								this.$message.error(res.msg)
							}
						})
					}
				})
			}
		}
	}
</script>

<style rel="stylesheet/scss" lang='scss' scoped>
	
</style>