<template>
	<div class="wrap">
		<div class="card-header">
			<el-card class="box-card">
			    <template #header>
			      <div class="card-header">
			        <h3>卡项分类</h3>
			      </div>
			    </template> 
				<div v-for="item in list" :key="item.type" @click="getCategory(item.type)" :class="[pushData.type==item.type ? 'current' : '','card-header item']">
					<div>{{ item.name }}</div>
					<span><span class="span">{{item.subCategoryCount}}</span></span>
				</div>
			</el-card>
			<el-card class="mainCon">
				<div class="card-header">
					<div></div>
					<div class="flexA">
						<div class="flexA marginR10">
							<el-select v-model="pushData.enabled" placeholder="是否启用" clearable class="marginR10">
								<el-option v-for="item in statusList" :key="item.status"
									:label='item.name' :value="item.status"></el-option>
							</el-select>
							<el-select v-model="pushData.saleable" placeholder="是否可销售" clearable class="marginR10">
								<el-option v-for="item in saleStatusList" :key="item.status"
									:label='item.name' :value="item.status"></el-option>
							</el-select>
							<el-input placeholder="搜索" v-model="pushData.fuzzySearch" @keyup.enter="search(pushData.type)" class="w200">
								<template #suffix>
									<el-icon class="el-input__icon">
										<component :is="Search" class="enter-sj" @click="search(pushData.type)"></component>
									</el-icon>
								</template>
							</el-input>
						</div>
						<el-button type="primary" color='#112DF2' @click="add">新增卡项</el-button>
					</div>
				</div>
				<el-table :data="tableData" :empty-text="emptyText" style="width: 100%;margin-top: 30px;">
				    <el-table-column prop="code" label="卡项信息" min-width="300">
						<template #default="{row}">
							<div class="flexA">
								<el-image class="img80 bgColor marginR10" :src="row.imgsUrl[0]">
									<template #error>
									  <div class="image-slot ">
										<el-icon style='height:80px;'><PictureFilled /></el-icon>
									  </div>
									</template>
								</el-image>
								<div class="textL">
									<p>{{row.name}}</p>
									<p>{{row.id}}</p>
									<p class="colorff0">{{row.salePrice?('￥'+row.salePrice):''}}</p>	
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="serviceItemCount" label="项目数量" min-width='100' />
					<el-table-column prop="totalServiceableTimes" label="最大可服务次数" min-width="130" />
					<el-table-column prop="validity" label="有效时间" min-width='100'>
						<template #default='{row}'>
							<div v-if="row.type==1">
								{{row.validity==-1?(row.giveValidity?row.giveValidity+'月':'--'):(row.giveValidity?(row.giveValidity+row.validity+'月'):row.validity+'月')}}
							</div>
							<div v-else>{{row.validity==-1?'':row.validity+'月'}}</div>
						</template>
					</el-table-column>
					<el-table-column prop="statistics.salesVolume" label="销量" min-width='60' />
					<el-table-column prop="virtualSalesVolume" label="虚拟销量" min-width='100'>
						<template #default="{row}">
							<el-button link type="primary" @click="dialogSalesVolume(row.id,row.virtualSalesVolume)">{{row.virtualSalesVolume}}</el-button>
						</template>
					</el-table-column>
					<el-table-column prop="createdTime" label="创建时间" min-width="100" />
					<el-table-column prop="enabled" label="启用" min-width='70'>
						<template #default="{row}">
							<el-switch
								@change="changeEnabledById(row.enabled,row.id)"
								v-model="row.enabled"
								style="--el-switch-on-color:#A2A5B8; --el-switch-off-color:#112DF2"
								:active-value="0" :inactive-value="1"/>
						</template>
					</el-table-column>
					<el-table-column prop="saleable" label="销售" min-width='70'>
						<template #default="{row}">
							<el-switch
								@change="changeSaleableById(row.saleable,row.id)"
								v-model="row.saleable"
								style="--el-switch-on-color:#A2A5B8; --el-switch-off-color:#112DF2"
								:active-value="0" :inactive-value="1"/>	
						</template>
					</el-table-column>
					<el-table-column label="操作" min-width="120">
						<template #default="{row}">
							<el-button link type="primary" plain color="#112DF2" @click="editItem(row.id)">修改</el-button>
							<el-button link type="primary" plain color="#112DF2" @click="delItem(row.id)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="paginations">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next" :total="total"></el-pagination>
				</div>
			</el-card>
		</div>
		<el-dialog v-model="dialogVisible" v-if="dialogVisible" title="虚拟销量编辑" width="600px">
			<el-form :model="form">
			  <el-form-item label="虚拟销量" label-width="80px">
				<el-input v-model="form.virtualSalesVolume" style='width:60%' autocomplete="off" />
			  </el-form-item>
			</el-form>
			<template #footer>
			  <span class="dialog-footer">
				<el-button @click="dialogVisible = false">取消</el-button>
				<el-button type="primary" @click="confirm">确定</el-button>
			  </span>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {statisticsCardCountByCardType,cardPageList,cardDel,cardUpdateEnabledById,cardUpdateSaleableById,cardUpdateVirtualSalesVolumeById} from '@/api'
	import {formatDate} from '@/utils/data'
	import { Search } from "@element-plus/icons-vue";
	export default {
		data () {
			return {
				Search,
				pushData:{
					type:'1',
					enabled:null,
					saleable:null,
					fuzzySearch:''
				},
				form:{
					virtualSalesVolume:''
				},
				dialogVisible:false,
				cardsId:'',
				statusList:[{name:'启用',status:'true'},{name:'未启用',status:'false'},],
				saleStatusList:[{name:'销售',status:'true'},{name:'不可销售',status:'false'}],
				list:[
					{name:'时卡',type:'1',subCategoryCount:'0'},
					{name:'次卡',type:'2',subCategoryCount:'0'},
					{name:'体验卡',type:'3',subCategoryCount:'0'}
				],
				tableData:[],
				pageSize: 5,	
				pageNum: 1,
				total:0,
				emptyText:'暂无数据'
			}
		},
		created() {
			this.getData()
		},	
		methods:{
			dialogSalesVolume(id,num){
				this.dialogVisible = true
				this.cardsId = id
				this.form.virtualSalesVolume = num
			},
			confirm(){
				let data = {
					id:this.cardsId,
					virtualSalesVolume:this.form.virtualSalesVolume
				}
				cardUpdateVirtualSalesVolumeById({data}).then(res=>{
					if(res.success){
						this.dialogVisible = false
						this.$message.success(res.msg)
						this.search(this.current)
						return
					}
					this.$message.error(res.msg)
				})
			},
			getData(){
				statisticsCardCountByCardType().then(res=>{
					this.search('1')
					if(res.success){
						let data = res.data
						this.list[0].subCategoryCount = data.timesCardCount
						this.list[1].subCategoryCount = data.numberCardCount
						this.list[2].subCategoryCount = data.generalCardCount
					}
				})
			},
			changeEnabledById(val,id){
				let data = {
					id,
					enabled:val==1?true:false
				}
				cardUpdateEnabledById({data}).then(res=>{
					if(res.success){
						this.$message.success(res.msg)
						this.search(this.current)
						return
					}
					// this.$message.error(res.msg)
				})
			},
			changeSaleableById(val,id){
				let data = {
					id,
					saleable:val==1?true:false
				}
				cardUpdateSaleableById({data}).then(res=>{
					if(res.success){
						this.$message.success(res.msg)
						this.search(this.current)
						return
					}
					// this.$message.error(res.msg)
				})
			},
			search(type){
				let postData = {
					 "paging": {
						"pageNum": this.pageNum,
						"pageSize": this.pageSize
					}
				}
				postData = Object.assign(postData, this.pushData)
				postData.enabled = postData.enabled == '' ? null : postData.enabled
				postData.saleable = postData.saleable == '' ? null : postData.saleable
				cardPageList(postData).then(res=>{
					if(res&&res.success){
						let list = res.data.list
						list.forEach(item=>{
							item.createdTime = formatDate(item.createdTime)
							if(!item.statistics){
								item.statistics = {
									salesVolume:0
								}
							}
							item.enabled = item.enabled ? 1 : 0
							item.saleable = item.saleable ? 1 : 0
							item.imgsUrl = []
							if(item.resources&&item.resources.length>0){
								for(let i of item.resources){
									let reg = /\w.(png|jpg|jpeg)$/i
									if(reg.test(i)){
										item.imgsUrl.push(i)
									}
								}
							}
						})
						this.tableData = list
						this.total = Number(res.data.total)
						let num = Number(type)
						this.list[num-1].subCategoryCount = Number(res.data.total)
					}
				})
			},
			getCategory(type){
				this.pushData.type = type
				this.search(type)
			},
			delItem(id){
				this.$messageBox.confirm('此操作将永久删除该商品，是否确定删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				}).then(()=>{
					cardDel({data:{id}}).then(res=>{
						if(res.success){
							this.$message.success('删除成功！')
							this.search(this.pushData.type)
							return
						}
						// this.$message.error(res.msg)
					})
				}).catch(()=>{})
			},
			editItem(id){
				this.$router.push('/goods/cardList/cardAdd/'+id)
			},
			add(){
				this.$router.push('/goods/cardList/cardAdd/')
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.getCategory(this.pushData.type)
			},
			handleCurrentChange(val) {
				this.pageNum = val;
				this.getCategory(this.pushData.type)
			},
		}
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.wrap{
		
	}
	.box-card{
		width: 276px;
		height: calc(100vh - 60px);
	}
	.card-header {
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
	}
	.item {
	  margin-bottom: 8px;
	  padding: 12px;
	  box-sizing: border-box;
	  font-size: 16px;
	  span{
		  color: #00B56B;
	  }
	}
	.current{
		color: #fff;
		background: #112DF2;
		border-radius: 10px;
		span{
			color: #fff;
		}
	}
	.mainCon{
		width: calc(100% - 316px);
		height: calc(100vh - 60px);
	}
	::v-deep .el-radio-button__original-radio:checked+.el-radio-button__inner{
		background-color:#112DF2;
		border-color:#112DF2;
		box-shadow:0;
	}
</style>