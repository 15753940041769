// 全局验证规则

export default {
	// 登录输入框验证
	loginRule(rule, value, callback){
		if (!rule.required) {
			if (value == '' || value == null) {
				callback()
				return
			}
		}
		// const reg = /^[\u4e00-\u9fa5_a-zA-Z0-9`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]+$/im//只能输入中文、大小写字母、数字、标点符号
		const reg = /^[\a-zA-Z0-9`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]+$/im
		if (!reg.test(value)) {
			callback(new Error('只能输入大小写字母、数字、标点符号'))
		} else {
			callback()
		}
	},
	
	// 是否是数字
	isNumber(rule, value, callback) {
		if (!rule.required) {
			if (value == '' || value == null) {
				callback()
				return
			}
		}
		if (isNaN(value) || value == '' || value == null  || value < 0 ) {
			callback(new Error('请输入正确的数字'))
		} else {
			callback()
		}
	},
	isRealNum(rule, value, callback) {
		// 0可以通过
		if (!rule.required) {
			if (value === '' || value == null) {
				return false
			}
		}
		if (!isNaN(value)) {
			callback()
		} else {
			callback(new Error('请输入正确的数字'))
		}
	},

	// 是否是整数
	int(rule, value, callback) {
		if (!rule.required) {
			if (value == '' || value == null) {
				callback()
				return
			}
		}
		const v = Number(value)
		if (!Number.isInteger(v) || value === '' || value === null) {
			callback(new Error('请输入正确的整数'))
		} else {
			callback()
		}
	},

	// 是否是整数 不能小于等于0
	posInt(rule, value, callback) {
		if (!rule.required) {
			if (value == '' || value == null) {
				callback()
				return
			}
		}
		const v = Number(value)
		if (!Number.isInteger(v) || value == '' || value == null || value <= 0) {
			callback(new Error('请输入正确的整数'))
		} else {
			callback()
		}
	},
	// 是否是整数 不能小于等于0     1-100
	posIntRange(rule, value, callback) {
		if (!rule.required) {
			if (value == '' || value == null) {
				callback()
				return
			}
		}
		const v = Number(value)
		if (!Number.isInteger(v) || value == '' || value == null || value <= 0 || value > 100) {
			callback(new Error('请输入正确的整数'))
		} else {
			callback()
		}
	},
	// 是否是整数 整数0-999
	inventoryNum(rule, value, callback) {
		if (!rule.required) {
			if (value == '' || value == null) {
				callback()
				return
			}
		}
		const v = Number(value)
		if (!Number.isInteger(v) || value == '' || value == null || value < 0 || value > 999) {
			callback(new Error('请输入正确的整数'))
		} else {
			callback()
		}
	},
	//整数、大小写字母，限制50字符
	barcodeValidate(rule, value, callback) {
		if (!rule.required) {
			if (value == '' || value == null) {
				callback()
				return
			}
		}
		const reg = /^[a-zA-Z0-9]*$/
		if (!reg.test(value) || value.length > 50) {
			callback(new Error('请输入不大于50个字符的字母或数字'))
		} else {
			callback()
		}
	},

	// 是否是浮点型不能小于能等于0
	posInt_positive(rule, value, callback) {
		if (!rule.required) {
			if (value == '' || value == null) {
				callback()
				return
			}
		}
		if (isNaN(value) || !(value >= 0) || value == '0' || value == '' || value == null) {
			callback(new Error('请输入正确的数字'))
		} else {
			callback()
		}
	},

	// 是否为手机号码
	isTel(rule, value, callback) {
		if (!rule.required) {
			if (value == '' || value == null) {
				callback()
				return
			}
		}
		const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
		if (!reg.test(value)) {
			callback(new Error('请输入正确的手机号码'))
		} else {
			callback()
		}
	}
}
